import React from 'react'
import RealEstate from './RealEstate'
import Mission from './Mission'
import Offerings from './Offerings'
import PropertyInvestmentSection from './PropertyInvestmentSection'
import RealState2 from './RealState2'
import NewsCard from './NewsCard';
import './Home.css';
import NewsletterBanner from './NewsletterBanner';
import NewsletterBanner2 from '../component/NewsletterBanner2'
const Home = () => {
  return (
    <div style={{overflowX:'hidden'}} >
    <div style={{paddingLeft:'unset', paddingRight:'unset'}} className='home2'>
    <Offerings/>
    <PropertyInvestmentSection/>
    </div>
    <RealEstate/>
    <div className="ads-container">
      <button className="ads-button">Ads</button>
    </div>
    {/* <NewsletterBanner/> */}
    <NewsletterBanner2 />
    </div>
  )
}

export default Home