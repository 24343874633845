// src/StockCard.js
import React from 'react';

const StockCard = ({ symbol, value, change }) => {
  const changeClass = change > 0 ? 'positive' : 'negative';

  return (
    <div className="stock-card">
      <div className="stock-symbol">{symbol}</div>
      <div className="stock-value">{value}</div>
      <div className={`stock-change ${changeClass}`}>{change}%</div>
    </div>
  );
};

export default StockCard;
