import React, { Component, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomSlider.scss";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

function SliderComp() {
  const [ticker, setTicker] = useState();

  useEffect(() => {
    getTicker();

    const intervalId = setInterval(() => {
      getTicker();
    }, 600000);

    return () => {
      clearInterval(intervalId);
      console.log("Interval cleared on component unmount");
    };
  }, []);

  async function getTicker() {
    try{
      const response = await fetch("https://backend.inferredtheorem.com/live_ticker/");
      const result = await response.json();
      setTicker(result);
    }catch(error){
      console.log("error in fetching ticker slider", error);
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  var slidesToShow = 7;
  var cardSpacing = 18;
  // const cards = [
  //   { symbol: "AAz", value: "4,092.69", change: "2.91%", changeColor: "green" },
  //   { symbol: "CNA", value: "6,851.12", change: "2.86%", changeColor: "green" },
  //   { symbol: "SSE", value: "20,043.25", change: "1.64%", changeColor: "green" },
  //   { symbol: "BP", value: "74,998.87", change: "2.21%", changeColor: "red" },
  //   { symbol: "XYZ", value: "1,234.56", change: "3.45%", changeColor: "green" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  //   { symbol: "MNO", value: "9,876.54", change: "1.23%", changeColor: "red" },
  // ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);

  useEffect(() => {
    setTotalSlides(Math.ceil(ticker?.length / slidesToShow));
  }, [ticker?.length, slidesToShow]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === totalSlides - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? totalSlides - 1 : prevIndex - 1
    );
  };

  const TruncateString = (str, length) => {
    if (!str) return str;
    str = Math.abs(str) + "";
    if (str?.length > length) {
      let temp = str.substring(0, length);
      return temp;
    }
    return str;
  };

  return (
    <div className="sliderMain">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-sm-11 p-0">
            <div className="slider-container">
              <div
                className="slider-wrapper"
                style={{
                  transform: `translateX(-${
                    currentIndex * (100 / slidesToShow)
                  }%)`,
                  width: `${(ticker?.length / slidesToShow) * 100}%`,
                }}
              >
                {ticker &&
                  ticker.map((card, index) => (
                    <div
                      key={index}
                      className="stock-card"
                      style={{
                        width: `calc(${
                          100 / slidesToShow
                        }% - ${cardSpacing}px)`,
                        margin: `0 ${cardSpacing / 2}px`,
                      }}
                    >
                      <div className="row">
                        <span className="stock-symbol">{card?.symbol}</span>
                      </div>
                      <div className="row">
                        <div className=" col-sm-6 ">
                          <span className="stock-value">{card?.price}</span>
                        </div>
                        <div className="col-sm-6">
                          <span
                            className={`stock-change`}
                            style={{
                              color: `${card?.percent_change > 0 ? "" : "red"}`,
                            }}
                          >
                            {card?.percent_change > 0 ? "+" : "-"}
                            {TruncateString(card?.percent_change, 5)}%
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-sm-1 d-flex align-items-center d-flex justify-content-start ps-0">
            <div className="rightIcon">
              <button className="prev-arrow" onClick={prevSlide}>
                &#10094;
              </button>
              <button className="next-arrow" onClick={nextSlide}>
                &#10095;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SliderComp;
