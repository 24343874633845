import React, { useEffect, useState, useRef } from "react";
import { createChart } from "lightweight-charts";
import "./CryptoChart.css";
import { PiChartLine } from "react-icons/pi";
import { LiaMountainSolid } from "react-icons/lia";
import { MdOutlineCandlestickChart } from "react-icons/md";

const CryptoChart = React.memo(
  ({
    baseData,
    currency,
    ChartColor,
    days,
    lineAreaData,
    indicatorData,
    setIndicatorData,
    candlestickData,
    currentChartType,
    isChartListVisible,
    setCurrentChartType,
    setCurrentIndicatorChartType,
    currentIndicatorChartType,
    isIndicatorChartVisibale,
    setIsIndicatorChartVisible,
    setIsChartListVisible
  }) => {
    const priceChartRef = useRef();
    const indicatorChartRef = useRef();
    const [priceChart, setPriceChart] = useState(null); // State for the main price chart
    const [priceSeries, setPriceSeries] = useState(null); // State for the price series
    const [volumeSeries, setVolumeSeries] = useState(null);

    // console.log("todayDay", days);
    console.log("baseData", baseData);

    useEffect(() => {
      // Create the main price chart
      const chart = createChart(priceChartRef.current, {
        width: 1300,
        height: 300,
        layout: {
          backgroundColor: "#ffffff",
          textColor: "#000000",
        },
        grid: {
          vertLines: false,
          horzLines: { color: "#e0e0e0" },
        },
        rightPriceScale: {
          visible: true,
        },
        timeScale: {
          // visible: false,
          timeVisible: true,
          secondsVisible: false,
        },
      });

      setPriceChart(chart); // Set the chart reference

      // Initialize with a line series as the default chart
      const initialPriceSeries = chart.addLineSeries({
        color: "#0000FF",
        lineWidth: 2,
      });
      lineAreaData && initialPriceSeries.setData(lineAreaData);
      setPriceSeries(initialPriceSeries); // Set the price series reference

      // Create the indicator chart (below the price chart)
      const indicatorChart = createChart(indicatorChartRef.current, {
        width: 1330,
        height: 200,
        layout: {
          backgroundColor: "#ffffff",
          textColor: "#000000",
        },
        grid: {
          vertLines: false,
          horzLines: { color: "#e0e0e0" },
        },
        rightPriceScale: {
          visible: true,
        },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
        },
      });

      const indicatorSeries = indicatorChart.addLineSeries({
        color: "#ff0000",
        lineWidth: 2,
      });

      indicatorData && indicatorSeries.setData(indicatorData);

      // Add volume histogram series (below the line chart)
    const volumeSeriesInstance = indicatorChart.addHistogramSeries({
      color: "#6bada7",
      priceFormat: { type: "volume" },
      scaleMargins: { top: 0.8, bottom: 0 },
    });
    candlestickData &&
      volumeSeriesInstance.setData(
        candlestickData.map((data) => ({
          time: data.time,
          value: data.volume,
          color: data.close > data.open ? "#65a29c" : "#e1908e", // Green/red based on price movement
        }))
      );
    setVolumeSeries(volumeSeriesInstance);

      // Sync the time scale of both charts
      const priceTimeScale = chart.timeScale();
      const indicatorTimeScale = indicatorChart.timeScale();

      priceTimeScale.subscribeVisibleLogicalRangeChange(() => {
        const logicalRange = priceTimeScale.getVisibleLogicalRange();
        indicatorTimeScale.setVisibleLogicalRange(logicalRange);
      });

      indicatorTimeScale.subscribeVisibleLogicalRangeChange(() => {
        const logicalRange = indicatorTimeScale.getVisibleLogicalRange();
        priceTimeScale.setVisibleLogicalRange(logicalRange);
      });

      // Cleanup on unmount
      return () => {
        chart.remove();
        indicatorChart.remove();
      };
    }, [baseData, indicatorData]);

    const switchChartType = (type) => {
      if (!priceChart) {
        console.error("Chart is not initialized yet.");
        return;
      }

      // Ensure priceSeries exists before removing it
      if (priceSeries) {
        try {
          priceChart.removeSeries(priceSeries); // Remove the current series before adding a new one
        } catch (error) {
          console.error("Failed to remove the series:", error);
        }
      }

      
      let newSeries;

    if (type === "candlestick") {
      newSeries = priceChart.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
      });
      newSeries.setData(candlestickData);

      const toolTip = document.getElementById("tooltip");
      priceChart.subscribeCrosshairMove((param) => {
        if (!param || !param.time) {
          toolTip.style.display = 'none';
          return;
        }
        const data = param.seriesData.get(newSeries);
        if (data) {
          const { open, high, low, close } = data;
          const priceInfo = `
            Open: ${open.toFixed(2)}<br />
            High: ${high.toFixed(2)}<br />
            Low: ${low.toFixed(2)}<br />
            Close: ${close.toFixed(2)}
          `;
          toolTip.innerHTML = priceInfo;
          toolTip.style.display = 'block';
          toolTip.style.left = `${param.point.x + 15}px`;
          toolTip.style.top = `${param.point.y}px`;
        }
      });

    } else if (type === "line") {
      newSeries = priceChart.addLineSeries({
        color: "#0000FF",
        lineWidth: 2,
      });
      newSeries.setData(lineAreaData);
    } else if (type === "area") {
      newSeries = priceChart.addAreaSeries({
        topColor: "rgba(0, 150, 136, 0.4)",
        bottomColor: "rgba(0, 150, 136, 0.0)",
        lineColor: "rgba(0, 150, 136, 1)",
        lineWidth: 2,
      });
      newSeries.setData(lineAreaData);
    }

    setPriceSeries(newSeries); // Update the price series state

    };

    const handleChartType = (type) => {
      switchChartType(type);
      setIsChartListVisible(false)
      setCurrentChartType(type);
    };

    // const handleToggleChart = (value)=>{
    //   setIsChartListVisible((prev) => !prev)
    // }

    // const handleToggleIndicator = (value)=>{
    //   setIsIndicatorChartVisible((prev) => !prev)
    // }

    const handleIndicatorChartType = (type)=>{
      setCurrentIndicatorChartType(type);
      setIsIndicatorChartVisible(false)
      const temp = baseData?.map((item) => ({
        time: item.time,
        value: item[type] || 0,      
      }));
      setIndicatorData(temp);
    }

    return (
      <>
        <div className="cryptochartt">
          <div ref={priceChartRef} />
            <div className="market-stock-price-text" ><p>Stock Price</p></div>
          <div ref={indicatorChartRef} style={{ marginTop: "20px" }} />
          <div className="market-stock-price-text" ><p> Indicator Metric ({currentIndicatorChartType})</p></div>

          <div
        id="tooltip"
        // className="ttt"
        style={{
          position: "absolute",
          border: "1px solid #CCC",
          padding: "10px",
          display: "none",
          pointerEvents: "none",
        }}
      ></div>

</div>

          <div className="market-chart-dropdown-botton">
            <div>
              {isChartListVisible && (
                <div className="dropDown-list">
                  <p
                    className={`${
                      currentChartType === "line" && "active-chart"
                    }`}
                    onClick={() => handleChartType("line")}
                  >
                    <PiChartLine size={25} /> Line
                  </p>
                  <p
                    className={`${
                      currentChartType === "candlestick" && "active-chart"
                    }`}
                    onClick={() => handleChartType("candlestick")}
                  >
                    <MdOutlineCandlestickChart size={25} />
                    Candle
                  </p>
                  <p
                    className={`${
                      currentChartType === "area" && "active-chart"
                    }`}
                    onClick={() => handleChartType("area")}
                  >
                    <LiaMountainSolid size={25} />
                    Mountain
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="market-indicator-dropdown-botton">
            <div>
              {isIndicatorChartVisibale && (
                <div className="dropDown-list">               
                  <p className={`${
                      currentIndicatorChartType !== "24-hour Volume" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('24-hour Volume')} >24-hour Volume</p>
                  <p 
                  className={`${
                      currentIndicatorChartType !== "Accumulation/Distribution" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Accumulation/Distribution')} 
                  >Accumulation/ Distribution</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Advance Decline Line" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Advance Decline Line')} > Advance Decline Line</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Advance Decline Ratio" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Advance Decline Ratio')} >Advance Decline Ratio</p>
                  <p  className={`${
                      currentIndicatorChartType !== "ALMA" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('ALMA')} >ALMA</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Aroon Up" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Aroon Up')} >Aroon Up</p>  
                    <p  className={`${
                      currentIndicatorChartType !== "Aroon Down" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Aroon Down')} >Aroon Down</p>              
                  <p  className={`${
                      currentIndicatorChartType !== "Average Day Range" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Average Day Range')} >Average Day Range</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Fib 23.6%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Fib 23.6%')} >Fib 23.6%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Fib 38.2%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Fib 38.2%')} >Fib 38.2%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Fib 50%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Fib 50%')} >Fib 50%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Fib 61.8%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Fib 61.8%')} >Fib 61.8%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Fib 100%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Fib 100%')} >Fib 100%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Ext 161.8%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Ext 161.8%')} >Ext 161.8%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Ext 261.8%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Ext 261.8%')} >Ext 261.8%</p>
                  <p  className={`${
                      currentIndicatorChartType !== "Ext 423.6%" ? "dropDown-list-text" : 'dropDown-list-text active-text'
                    }`} onClick={()=> handleIndicatorChartType('Ext 423.6%')} >Ext 423.6%</p>
                </div>
              )}
            </div>
          </div>
         
        
         
       
      </>
    );
  }
);

export default CryptoChart;

/*  

<div className="">
      <div ref={priceChartRef} /> 
      <h1>hello world</h1>
        <div ref={indicatorChartRef} style={{ marginTop: "20px" }} />
    

      <div style={{ marginTop: "20px" }}>
        <button onClick={() => switchChartType("candlestick")}>
          Candlestick
        </button>{" "}
        &nbsp; &nbsp; &nbsp;
        <button onClick={() => switchChartType("line")}>Line</button> &nbsp;
        &nbsp; &nbsp;
        <button onClick={() => switchChartType("area")}>Area</button>
      </div>
    
    </div> 

*/
