import React, { useEffect, useState } from "react";

// const colors = [
//   {
//     dataKey: "Cement",
//     stroke: "#2CA02C",
//   },
//   {
//     dataKey: "Kitchen_furniture",
//     stroke: "#74F2FF",
//   },
//   { dataKey: "Imported_plywood", stroke: "#C5B0D5" },
//   { dataKey: "Paint_non_aqueous", stroke: "#17BECF" },
//   { dataKey: "Paint_aqueous", stroke: "#DBDB8D" },
//   { dataKey: "Insulating_materials_thermal_or_acoustic", stroke: "#BCBD22" },
//   { dataKey: "Sanitaryware", stroke: "#C7C7C7" },
//   { dataKey: "Pipes_and_fittings_flexible", stroke: "#7F7F7F" },
//   { dataKey: "Pipes_and_fittings_rigid", stroke: "#F7B6D2" },
//   { dataKey: "Taps_and_valves_for_sanitaryware", stroke: "#E377C2" },
//   {
//     dataKey: "Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy",
//     stroke: "#124A74",
//   },
//   { dataKey: "Central_heating_boilers", stroke: "#C49C94" },
//   { dataKey: "Fabricated_structural_steel", stroke: "#8C564B" },
//   { dataKey: "Imported_sawn_or_planed_wood", stroke: "#9467BD" },
//   { dataKey: "Concrete_reinforcing_bars_steel", stroke: "#FF9896" },
//   { dataKey: "Pre_cast_concrete_products", stroke: "#D62728" },
//   { dataKey: "Ready_mixed_concrete", stroke: "#98DF8A" },
//   {
//     dataKey: "Bituminous_Mixtures_based_on_Natural_Artificial_Stone",
//     stroke: "#FFBB78",
//   },
//   { dataKey: "Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy", stroke: "#00ffbf" },
// ];

const colors = {
  Cement: "#2CA02C",
  Kitchen_furniture: "#74F2FF",
  Imported_plywood: "#C5B0D5",
  Paint_non_aqueous: "#17BECF",
  Paint_aqueous: "#DBDB8D",
  Insulating_materials_thermal_or_acoustic: "#BCBD22",
  Sanitaryware: "#C7C7C7",
  Pipes_and_fittings_flexible: "#7F7F7F",
  Pipes_and_fittings_rigid: "#F7B6D2",
  Taps_and_valves_for_sanitaryware: "#E377C2",
  Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy: "#124A74",
  Central_heating_boilers: "#C49C94",
  Fabricated_structural_steel: "#8C564B",
  Imported_sawn_or_planed_wood: "#9467BD",
  Concrete_reinforcing_bars_steel: "#FF9896",
  Pre_cast_concrete_products: "#D62728",
  Ready_mixed_concrete: "#98DF8A",
  Bituminous_Mixtures_based_on_Natural_Artificial_Stone: "#FFBB78",
  Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy: "#00ffbf",
};



const CRMCheckBox = React.memo(
  ({
    selectedItems,
    setSelectedItems,
    setTemp,
    temp,
    setImportedData,
    importedData,
    year,
    setYear
  }) => {

    const tableData = [
      "Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy",
      "Bituminous_Mixtures_based_on_Natural_Artificial_Stone",
      "Cement",
      "Ready_mixed_concrete",
      "Pre_cast_concrete_products",
      "Concrete_reinforcing_bars_steel",
      "Imported_sawn_or_planed_wood",
      "Imported_plywood",
      "Fabricated_structural_steel",
      "Central_heating_boilers",
      "Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy",
      "Taps_and_valves_for_sanitaryware",
      "Pipes_and_fittings_rigid",
      "Pipes_and_fittings_flexible",
      "Sanitaryware",
      "Insulating_materials_thermal_or_acoustic",
      "Paint_aqueous",
      "Paint_non_aqueous",
      "Kitchen_furniture",
    ];

    const rm = [2500, 4300, 4500, 3100, 5200, 4200, 7900, 3400, 7500, 5500];

    const [chartData, setChartData] = useState();
    const [month, setMonth] = useState();
    // const [importedData, setImportedData] = useState();
    const [Cement, setCement] = useState();
    const [Kitchen_furniture, setKitchen_furniture] = useState();
    const [Imported_plywood, setImported_plywood] = useState();
    const [Paint_non_aqueous, setPaint_non_aqueous] = useState();
    const [Paint_aqueous, setPaint_aqueous] = useState();
    const [
      Insulating_materials_thermal_or_acoustic,
      setInsulating_materials_thermal_or_acoustic,
    ] = useState();
    const [Sanitaryware, setSanitaryware] = useState();
    const [Pipes_and_fittings_flexible, setPipes_and_fittings_flexible] =
      useState();
    const [Pipes_and_fittings_rigid, setPipes_and_fittings_rigid] = useState();
    const [
      Taps_and_valves_for_sanitaryware,
      setTaps_and_valves_for_sanitaryware,
    ] = useState();
    const [
      Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy,
      setGravel_Sand_Clays_Kaolin_incl_Aggregate_Levy,
    ] = useState();
    const [Central_heating_boilers, setCentral_heating_boilers] = useState();
    const [Fabricated_structural_steel, setFabricated_structural_steel] =
      useState();
    const [Imported_sawn_or_planed_wood, setImported_sawn_or_planed_wood] =
      useState();
    const [
      Concrete_reinforcing_bars_steel,
      setConcrete_reinforcing_bars_steel,
    ] = useState();
    const [Pre_cast_concrete_products, setPre_cast_concrete_products] =
      useState();
    const [Ready_mixed_concrete, setReady_mixed_concrete] = useState();
    const [
      Bituminous_Mixtures_based_on_Natural_Artificial_Stone,
      setBituminous_Mixtures_based_on_Natural_Artificial_Stone,
    ] = useState();
    const [
      Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy,
      setGravel_Sand_Clays_Kaolin_exc_Aggregate_Levy,
    ] = useState();

    const dataSource = {
      Cement,
      Kitchen_furniture,
      Imported_plywood,
      Paint_non_aqueous,
      Paint_aqueous,
      Insulating_materials_thermal_or_acoustic,
      Sanitaryware,
      Pipes_and_fittings_flexible,
      Pipes_and_fittings_rigid,
      Taps_and_valves_for_sanitaryware,
      Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy,
      Central_heating_boilers,
      Fabricated_structural_steel,
      Imported_sawn_or_planed_wood,
      Concrete_reinforcing_bars_steel,
      Pre_cast_concrete_products,
      Ready_mixed_concrete,
      Bituminous_Mixtures_based_on_Natural_Artificial_Stone,
      Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy,
    };

    useEffect(() => {
      getChartData(year);
    }, [year]);

    async function getChartData(year) {
      const result = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_year_month/?year=${year}`
      );
      const resp = await result.json();
      setChartData(resp.data);
      setImportedData(
        resp.data?.map((item) => ({
          name: item["Month_YY"]?.split(" ")[0],
          Imported_plywood: item["Imported_plywood"],
        }))
      );
      setCement(resp.data?.map((item) => item["cement"]));
      setMonth(resp.data?.map((item) => item["Month_YY"]?.split(" ")[0]));
      setImported_plywood(resp.data?.map((item) => item["Imported_plywood"]));
      setKitchen_furniture(resp.data?.map((item) => item["Kitchen_furniture"]));
      setPaint_non_aqueous(resp.data?.map((item) => item["Paint_non_aqueous"]));
      setPaint_aqueous(resp.data?.map((item) => item["Paint_aqueous"]));
      setInsulating_materials_thermal_or_acoustic(
        resp.data?.map(
          (item) => item["Insulating_materials_thermal_or_acoustic"]
        )
      );
      setSanitaryware(resp.data?.map((item) => item["Sanitaryware"]));
      setPipes_and_fittings_flexible(
        resp.data?.map((item) => item["Pipes_and_fittings_flexible"])
      );

      setPipes_and_fittings_rigid(
        resp.data?.map((item) => item["Pipes_and_fittings_rigid"])
      );
      setTaps_and_valves_for_sanitaryware(
        resp.data?.map((item) => item["Taps_and_valves_for_sanitaryware"])
      );
      setGravel_Sand_Clays_Kaolin_incl_Aggregate_Levy(
        resp.data?.map(
          (item) => item["Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy"]
        )
      );
      setCentral_heating_boilers(
        resp.data?.map((item) => item["Central_heating_boilers"])
      );
      setFabricated_structural_steel(
        resp.data?.map((item) => item["Fabricated_structural_steel"])
      );
      setImported_sawn_or_planed_wood(
        resp.data?.map((item) => item["Imported_sawn_or_planed_wood"])
      );

      setConcrete_reinforcing_bars_steel(
        resp.data?.map((item) => item["Concrete_reinforcing_bars_steel"])
      );
      setPre_cast_concrete_products(
        resp.data?.map((item) => item["Pre_cast_concrete_products"])
      );
      setReady_mixed_concrete(
        resp.data?.map((item) => item["Ready_mixed_concrete"])
      );
      setBituminous_Mixtures_based_on_Natural_Artificial_Stone(
        resp.data?.map(
          (item) =>
            item["Bituminous_Mixtures_based_on_Natural_Artificial_Stone"]
        )
      );
      setGravel_Sand_Clays_Kaolin_exc_Aggregate_Levy(
        resp.data?.map(
          (item) => item["Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy"]
        )
      );
    }

    const handleCheckboxChange = (item1) => {
      // console.log("this is selected", dataSource[item1]);

      // console.log(`Current key: "${item1}"`);
      //   console.log(`Available keys in dataSource: ${Object.keys(dataSource)}`);

      if (selectedItems.includes(item1)) {
        //remove selected item
        setSelectedItems(selectedItems.filter((i) => i !== item1));

        let hello;
        let updatedData = importedData.map((item, index) => {
          const { [item1]: hello, ...rest } = item;
          return rest;
        });

        setImportedData(updatedData);
      } else {
        // add select item
        setSelectedItems([...selectedItems, item1]);
        let updatedData = importedData.map((item, index) => {
          const key = item1;
          return { ...item, [key]: dataSource[item1][index] };
        });
        console.log("updated data added", updatedData);
        setImportedData(updatedData);
      }
    };

    return (
      <div className="top-crm-radio-section">
        <div className="radio-left-section">
        <div className="radio-left-section-div">
              <label
                htmlFor='item'
                className="custom-checkbox radio-section-text"
              >
                <input
                  type="checkbox"
                  id='item'
                  name='item'
                  checked={true}
                 
                  // onChange={() => handleCheckboxChange(item)}
                  value='item'
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: 'white',
                    borderColor: '#124A74',
                  }}
                ></span>
                 
                <p className="radio-section-subtext  no-select-checkbox">
                  Select Materials
                </p>
              </label>
            </div>
          {tableData.slice(0, 9).map((item) => (
            <div className="radio-left-section-div" key={item}>
              <label
                htmlFor={item}
                className="custom-checkbox radio-section-text"
              >
                <input
                  type="checkbox"
                  id={item}
                  name={item}
                  checked={selectedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                  value={item}
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: colors[item],
                    borderColor: colors[item],
                  }}
                ></span>
                 
                <p className="radio-section-subtext">
                  {item.split("_").join(" ")}
                </p>
              </label>
            </div>
          ))}
        </div>
        <div className="radio-right-section">
          {tableData.slice(9, 20).map((item) => (
            <div className="radio-right-section-div" key={item}>
              <label
                htmlFor={item}
                className="custom-checkbox radio-section-text"
              >
                <input
                  type="checkbox"
                  id={item}
                  name={item}
                  checked={selectedItems.includes(item)}
                  onChange={() => handleCheckboxChange(item)}
                  value={item}
                />
                <span
                  className="checkmark"
                  style={{
                    backgroundColor: colors[item],
                    borderColor: colors[item],
                  }}
                ></span>
                 
                <p className="radio-section-subtext">
                  {item.split("_").join(" ")}
                </p>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default CRMCheckBox;
