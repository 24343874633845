import React from 'react'
import './realState2.scss'


const RealState2 = () => {
  return (
   <>
   



  <div className='RealState2'></div>


   </>
    
    

  )
}

export default RealState2