import React, { useEffect, useState } from "react";
import "./IPO.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import CryptoChart from "../component/CryptoChart";
import CryptoSmallChart from "../component/CryptoSmallChart";
import SingleIPO from "./SingleIPO";
import axios from "axios";
import { DateRangePicker } from "rsuite";
import { RiCloseLargeFill } from "react-icons/ri";

const IPO = () => {
  const [topNews, setTopNews] = useState("");
  const [sectionNews, setSectionNews] = useState("");
  const [trendingNews, setTrendingNews] = useState("");
  const [trendingTicker, setTrendingTicker] = useState("");
  const [allNews, setAllNews] = useState("");
  const [publisherList, setPublisherList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [IsCardvisible, setIsCardVisible] = useState(false);

  const [topIPOChart, setTopIPOChart] = useState("");

  const [topsTicker, setTickers] = useState([]);
  const [top1Ticker, setTop1Ticker] = useState([]);
  const [top2Ticker, setTop2Ticker] = useState([]);
  const [top3Ticker, setTop3Ticker] = useState([]);
  const [top4Ticker, setTop4Ticker] = useState([]);
  const [top5Ticker, setTop5Ticker] = useState([]);

  const [topGainLosers, setTopGainLosers] = useState([]);
  const [top1GainLoser, setTop1GainLoser] = useState([]);
  const [top2GainLoser, setTop2GainLoser] = useState([]);
  const [top3GainLoser, setTop3GainLoser] = useState([]);
  const [top4GainLoser, setTop4GainLoser] = useState([]);
  const [top5GainLoser, setTop5GainLoser] = useState([]);
  const [top6GainLoser, setTop6GainLoser] = useState([]);
  const [top7GainLoser, setTop7GainLoser] = useState([]);
  const [top8GainLoser, setTop8GainLoser] = useState([]);
  const [top9GainLoser, setTop9GainLoser] = useState([]);
  const [top10GainLoser, setTop10GainLoser] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [gainerLoserText, setGainerLoserText] = useState('Top Gainers')
  const [tickerId, setTickerId] = useState();

  const staticGNData = [top1GainLoser, top2GainLoser, top3GainLoser, top4GainLoser, top5GainLoser, top6GainLoser, top7GainLoser, top8GainLoser, top9GainLoser, top10GainLoser];    

  useEffect(() => {
    getTopNews();
    getSectionNews();
    getTrendingTicker();
    getTrendingNews();
    getPublisherList();
    getTopGainLosers();
  }, []);

  async function getTopNews() {
    try {
      const resp = await fetch("https://backend.inferredtheorem.com/get_top4_ipo/");
      const result = await resp.json();
      setTopNews(result);
      const ticker = result?.map((item) => item.ticker);

      setTickers(ticker);
    } catch (error) {
      console.log("error in fetching top news", error);
    }
  }
      
  async function getSectionNews() {    
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/section_2_ipo/");
      const resp2 = await resp1.json();
      setAllNews(resp2);
      const page = Math.ceil(resp2.count / 10);
      setTotalPage(page);
      setSectionNews(resp2.data.slice(0, 10));
    } catch (error) {
      console.log("error in fetching section news in news page", error);
    }
  }

  async function getTrendingNews() {
    try {
      const resp1 = await fetch(
        "https://backend.inferredtheorem.com/get_news_by_ticker_name/"
      );
      const resp2 = await resp1.json();
      setTrendingNews(resp2.data);
    } catch (error) {
      console.log("error in fetching trending news in news page", error);
    }
  }

  async function getTrendingTicker() {
    try {
      const resp1 = await fetch(
        "https://backend.inferredtheorem.com/get_all_tickers/top_gainers/"
      );
      const resp2 = await resp1.json();
      setTrendingTicker(resp2);
    } catch (error) {
      console.log("error in fetching trending news in news page", error);
    }
  }

  async function getTopGainLosers() {
    try {
      const resp1 = await fetch(
        "https://backend.inferredtheorem.com/get_all_tickers/top_gainers/"
      );
      const resp2 = await resp1.json();
      setTopGainLosers(resp2);
    } catch (error) {
      console.log("error in fetching trending news in news page", error);
    }
  }

  async function getPublisherList() {
    try {
      const resp = await fetch(
        "https://backend.inferredtheorem.com/ticker_name_dropdown/"
      );
      const result = await resp.json();
      setPublisherList(result["ticker Name"]);
    } catch (error) {
      console.log("error in getting dropdown in IPO", error);
    }
  }

  useEffect(() => {
    if (endDate) {
      filterLatestNewsBYdate();
    }
  }, [startDate, endDate]);


  useEffect(()=>{
    if(topNews[0]){
      getChartData1();
    }
  },[topNews[0]])

  useEffect(()=>{
    if(topNews[1]){
      getChartData2();
    }
  },[topNews[1]])

  useEffect(()=>{
    if(topNews[2]){
    getChartData3();
    }
  },[topNews[2]])

  useEffect(()=>{
    if(topNews[3]){
    getChartData4();
    }
  },[topNews[3]])

  useEffect(()=>{
    if(topNews[4]){
    getChartData5();
    }
  },[topNews[4]])

  


  async function getChartData1() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topNews?.[0]?.ticker}/`
      );
      const result = await response.json();
      setTop1Ticker(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getChartData2() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topNews?.[1]?.ticker}/`
      );
      const result = await response.json();
      setTop2Ticker(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getChartData3() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topNews?.[2]?.ticker}/`
      );
      const result = await response.json();
      setTop3Ticker(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getChartData4() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topNews?.[3]?.ticker}/`
      );
      const result = await response.json();
      setTop4Ticker(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getChartData5() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topNews?.[4]?.ticker}/`
      );
      const result = await response.json();
      setTop5Ticker(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  const filterTopGainerLoser = async(key)=>{
    // console.log("key", key)
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/get_all_tickers/${key}/`
      );
      const result = await response.json();
      setTopGainLosers(result);
      if(key === 'top_gainers'){
        setGainerLoserText("Top Gainers");
      }else{
        setGainerLoserText("Top Losers");
      }
      console.log("key", key);
    } catch (error) {
      console.log("error in fetching filterTopGainerLoser chart", error);
    }
  }


  useEffect(()=>{
    if(topGainLosers[0]){
      getGL1Data();
    }

    if(topGainLosers[1]){
      getGL2Data();
    }

    if(topGainLosers[2]){
      getGL3Data();
    }

    if(topGainLosers[3]){
      getGL4Data();
    }

    if(topGainLosers[4]){
      getGL5Data();
    }
    if(topGainLosers[5]){
      getGL6Data();
    }
    if(topGainLosers[6]){
      getGL7Data();
    }
    if(topGainLosers[7]){
      getGL8Data();
    }
    if(topGainLosers[8]){
      getGL9Data();
    }
    if(topGainLosers[9]){
      getGL10Data();
    }
    
  }, [topGainLosers])  

  async function getGL1Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[0]?.ticker}/`
      );
      const result = await response.json();
      setTop1GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL2Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[1]?.ticker}/`
      );
      const result = await response.json();
      setTop2GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL3Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[2]?.ticker}/`
      );
      const result = await response.json();
      setTop3GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL4Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[3]?.ticker}/`
      );
      const result = await response.json();
      setTop4GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL5Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[4]?.ticker}/`
      );
      const result = await response.json();
      setTop5GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL6Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[5]?.ticker}/`
      );
      const result = await response.json();
      setTop6GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL7Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[6]?.ticker}/`
      );
      const result = await response.json();
      setTop7GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL8Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[7]?.ticker}/`
      );
      const result = await response.json();
      setTop8GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL9Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[8]?.ticker}/`
      );
      const result = await response.json();
      setTop9GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }

  async function getGL10Data() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/chart_ipo/${topGainLosers?.[9]?.ticker}/`
      );
      const result = await response.json();
      setTop10GainLoser(result);
    } catch (error) {
      console.log("error in fetching chart", error);
    }
  }


  const filtertrendingNewsByPublishing = async (publishing) => {
    try {
      const resp = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_ticker_name/?long_name=${publishing}`
      );
      const result = await resp.json();
      setTrendingNews(result.data);
    } catch (error) {
      console.log("error in filter trending News", error);
    }
  };

  const formatDate2 = (date) => {
    return date instanceof Date && !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "Not selected";
  };

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getSectionNews();
  };

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const filterLatestNewsBYdate = () => {
    const start_date = formatDate2(startDate);
    const end_date = formatDate2(endDate);



    axios
      .get(
        `https://backend.inferredtheorem.com/from_to_date_ipo/?from=${start_date}&to=${end_date}`
      )
      .then((response) => {
        console.log(
          "response filter by date",
          response.data.data.length,
          response.data.data
        );
        if (response.data.data.length) {
          setAllNews(response.data);
          setSectionNews(response.data.data.slice(0, 9));
        } else {
          handleShowPopup();
          setSectionNews(null);
        }
      })
      .catch((error) => {
        console.log("error in filter news by date", error);
        // getSectionNews();
      });
  };

  function handleFetchNextPage() {
    const newpage = Number(currentPage) + 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function FetchPage(pageNumber) {
    let data;
    if (pageNumber > 1) {
      data = allNews.data.slice((pageNumber - 1) * 10, pageNumber * 10);
    } else {
      data = allNews.data.slice(0, 10);
    }
    setSectionNews(data);
    console.log(pageNumber);
  }

  function handleFetchPrevPage() {
    const newpage = currentPage - 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function handleInputChange(e) {
    const value = e.target.value;
    if (value < 0) {
      return;
    }
    setInputPage(value);
    setCurrentPage(value);
    const data = allNews.data.slice((value - 1) * 10, value * 10);
    setSectionNews(data);
  }

  const FormatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const TruncateString = (str, length) => {
    if (str.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  const handleIPOClicked = (data, tickerId) => {
    setIsCardVisible(true);
    setTopIPOChart(data);
    setTickerId(tickerId);

  };

  return (
    <section
      className="PlanningApplications"
      style={{
        color: "black",
        marginTop: "20px",
      }}
    >
      <div className="planning">
        <div className="IPO-Container">
          {!IsCardvisible ? (
            <div className="IPO_page">
            <div className="top_IPO_container">
              <div className="top_2_IPO">
                {topNews[0] && (
                  <div
                    className="top-1st-IPO"
                    onClick={() => handleIPOClicked(topNews[0], topNews[0]?.ticker)}
                  >
                    <div className="top-1st-IPO-left-container">
                      <h3 className="top_2_IPO_heading">
                        {TruncateString(topNews[0]?.long_name, 25)}
                      </h3>
                      <div className="top_IPO_big_price">
                        <span>Price:</span>
                        <span>{topNews[0]?.current_price}</span>
                      </div>
                    </div>
                    <div className="top-1st-IPO-right-container">
                      <CryptoSmallChart baseData={top1Ticker} TopNews={topNews[0]} />
                    </div>
                    <div className="top_IPO_big_dates">
                      <span>{FormatDate(topNews[0]?.date)}</span>
                    </div>
                  </div>
                )}
                {topNews[1] && (
                  <div
                    className="top-2nd-IPO"
                    onClick={() => handleIPOClicked(topNews[1], topNews[1]?.ticker)}
                  >
                    <div className="top-2st-IPO-left-container">
                      <h3 className="top_2_IPO_heading">
                        {TruncateString(topNews[1]?.long_name, 25)}
                      </h3>
                      <div className="top_IPO_big_price">
                        <span>Price:</span>
                        <span>{topNews[1]?.current_price}</span>
                      </div>
                    </div>
                    <div className="top-2st-IPO-right-container">
                      <CryptoSmallChart baseData={top2Ticker} TopNews={topNews[1]} />
                    </div>
                    <div className="top_IPO_big_dates">
                      <span>{FormatDate(topNews[1]?.date)}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="top_3_IPO">
                {topNews[2] && (
                  <div
                    className="top_IPO_card top_3rd_IPO "
                    onClick={() => handleIPOClicked(topNews[2], topNews[2]?.ticker)}
                  >
                    <div className="top_3rd_IPO-upperBody">
                      <div className="top_3rd_IPO_left-container">
                        <h3 className="top_3_IPO_heading">
                          {TruncateString(topNews[2]?.long_name, 18)}
                        </h3>
                        <div className="top_small_IPO_price">
                          <span>Price:</span>
                          <span>{topNews[2]?.current_price}</span>
                        </div>
                      </div>
                      <div className="top_3rd_IPO_right-container">
                        <CryptoSmallChart baseData={top3Ticker} TopNews={topNews[2]} />
                      </div>
                    </div>
                    <div className="top_small_IPO_dates">
                      <span>{FormatDate(topNews[2]?.date)}</span>
                    </div>
                  </div>
                )}
                {topNews[3] && (
                  <div
                    className="top_IPO_card top_4th_IPO "
                    onClick={() => handleIPOClicked(topNews[3], topNews[3]?.ticker)}
                  >
                    <div className="top_4th_IPO-upperBody">
                      <div className="top_4rd_IPO_left-container">
                        <h3 className="top_3_IPO_heading">
                          {TruncateString(topNews[3]?.long_name, 18)}
                        </h3>
                        <div className="top_small_IPO_price">
                          <span>Price:</span>
                          <span>{topNews[3]?.current_price}</span>
                        </div>
                      </div>
                      <div className="top_4rd_IPO_right-container">
                        <CryptoSmallChart baseData={top4Ticker} TopNews={topNews[3]} />
                      </div>
                    </div>
                    <div className="top_small_IPO_dates">
                      <span>{FormatDate(topNews[3]?.date)}</span>
                    </div>
                  </div>
                )}
                {topNews[4] && (
                  <div
                    className="top_IPO_card top_5th_IPO "
                    onClick={() => handleIPOClicked(topNews[4], topNews[4]?.ticker)}
                  >
                    <div className="top_5th_IPO-upperBody">
                      <div className="top_5rd_IPO_left-container">
                        <h3 className="top_3_IPO_heading">
                          {TruncateString(topNews[4]?.long_name, 18)}
                        </h3>
                        <div className="top_small_IPO_price">
                          <span>Price:</span>
                          <span>{topNews[4]?.current_price}</span>
                        </div>
                      </div>
                      <div className="top_5rd_IPO_right-container">
                        <CryptoSmallChart baseData={top5Ticker} TopNews={topNews[4]} />
                      </div>
                    </div>
                    <div className="top_small_IPO_dates">
                      <span>{FormatDate(topNews[4]?.date)}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="bottom_IPO_container">
              <div className="latest_IPO_container">
                <div className="latest_IPO_heading">
                  <h3>Latest</h3>
                  <div className="bottom-border"></div>
                  
                </div>
                {isPopupVisible && (
                  <div className="popup-overlay">
                    <div className="popup-content">
                      <RiCloseLargeFill className="noData" />
                      <h2>No Data Found</h2>
                      <p>Sorry, there is no data available to display.</p>
                      <button onClick={handleClosePopup}>Close</button>
                    </div>
                  </div>
                )}
                {sectionNews && (
                  <div className="latest_IPO_cards">
                    {sectionNews.map((ipo) => (
                      <div key={ipo?.id} className="latest_IPO_card"
                      onClick={() => handleIPOClicked(ipo)}
                      >
                        <h3 className="latest_IPO_card_heading">
                          {ipo?.long_name}
                        </h3>
                        <div className="latest_IPO_price">
                          <span>Price:</span>
                          <span>{ipo?.current_price}</span>
                        </div>
                        <div className="latest_IPO_dates">
                          <span>{FormatDate(ipo?.date)}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="Trending_IPO_container">
                <div className="trending_IPO_heading">
                  <p> {gainerLoserText}  </p>
                  {/* <button>Filter</button> */}
                  <div class="dropdown">
                    <button
                      class="btn btn-secondary btn-dark dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                     Filter
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={(e) =>
                            filterTopGainerLoser("top_losers")
                          }
                        >
                          Top Losers
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          onClick={(e) =>
                            filterTopGainerLoser("top_gainers")
                          }
                        >
                          Top Gainer
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="trending_IPO-cards">
                  {topGainLosers &&
                    topGainLosers.map((item, index) => (
                      <div className="trending_IPO-card">
                        <div key={index} 
                         onClick={() => handleIPOClicked(item)} 
                        className="trending_IPO-card-info">
                          <p>{TruncateString(item?.long_name, 30)}</p>
                          <p>{item?.current_price}</p>

                          {item?.previous_close > item?.current_price ? (
                            <p style={{ color: "red" }}>
                              -{item?.percent_change} &nbsp; (-
                              {item?.absolute_change}%){" "}
                            </p>
                          ) : (
                            <p>
                              +{item?.percent_change} &nbsp; (+
                              {item?.absolute_change}%){" "}
                            </p>
                          )}
                        </div>

                        <div className="trending_IPO-card-chart">
                          <CryptoSmallChart baseData={staticGNData[index]} gainerLoserText={gainerLoserText} />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="pagination-controls">
              <div className="page-button">
                <button
                  className={`${
                    currentPage === 1 ? "next-button-display" : "next-button"
                  }`}
                  onClick={handleFetchPrevPage}
                >
                  <FaArrowLeftLong size={15} /> Prev Page
                </button>

                <button
                  className={`${
                    currentPage === totalPage
                      ? "next-button-display"
                      : "next-button"
                  }`}
                  onClick={handleFetchNextPage}
                >
                  Next Page <FaArrowRightLong size={15} />
                </button>
              </div>

              <div className="pagination-count">
                <span>Page</span>
                <input
                  type="number"
                  name=""
                  id=""
                  value={inputPage}
                  onChange={handleInputChange}
                  // onKeyDown={handlePageKeyPress}
                />
                <span>of {totalPage}</span>
              </div>
            </div>
          </div>
          ) : (
            <div className="SingleIPOBlur">
              <SingleIPO
               gainerLoserText={gainerLoserText}
                setIsCardVisible={setIsCardVisible}
                tickerId={tickerId}
                topIPOChart={topIPOChart}
                topGainLosers={topGainLosers}
                top1GainLoser={top1GainLoser}
                top2GainLoser={top2GainLoser}
                top3GainLoser={top3GainLoser}
                top4GainLoser={top4GainLoser}
                top5GainLoser={top5GainLoser}
                top6GainLoser={top6GainLoser}
                top7GainLoser={top7GainLoser}
                top8GainLoser={top8GainLoser}
                top9GainLoser={top9GainLoser}
                top10GainLoser={top10GainLoser}
              />
            </div>
          )}

          {/* {IsCardvisible && (
            <div className="SingleIPOBlur">
              <SingleIPO
                setIsCardVisible={setIsCardVisible}
                topIPOChart={topIPOChart}
              />
            </div>
          )} */}
        </div>
      </div>
    </section>
  );
};

export default IPO;



