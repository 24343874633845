import React, { useState } from "react";
import './SignUp.css';
import { FcGoogle } from "react-icons/fc";
import { IoLogoApple } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const SignUp = ({ authenticateUser }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Determine if the form is valid
  const isFormValid =
    formData.firstName.trim() !== '' &&
    formData.lastName.trim() !== '' &&
    formData.email.trim() !== '' &&
    formData.password.trim() !== '' &&
    formData.confirmPassword.trim() !== '' && // Ensure confirmPassword is not empty
    formData.password === formData.confirmPassword;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle confirm password validation
    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    if (!formData.confirmPassword) {
      alert("Please confirm your password.");
      return;
    }

    try {
      const response = await fetch('https://www.inferredtheorem.com:5000/api/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          password: formData.password,
          confirmPassword: formData.confirmPassword
        }),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Signed up successfully', data);
        authenticateUser(true); // Update the authentication state
        navigate('/login');
      } else {
        console.error('Error signing up:', data.message);
        alert(data.message);
      }
    } catch (error) {
      console.error('Error signing up:', error);
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <div className="SignUp-container">
      <div className="SignUp-form">
        <h2 className="SignUp-form-heading">Sign up now</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="input-container">
            <input
              type="email"
              name="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-container">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-container">
            <input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-container">
            <label>
              <input type="checkbox" required />
              <p>By creating an account, I agree to the Terms of use and Privacy Policy</p>
            </label>
          </div>

          <button
            type="submit"
            className={`signup-btn ${isFormValid ? 'enabled' : 'disabled'}`}
            disabled={!isFormValid}
          >
            Sign up
          </button>

          <p>
            Already have an account? <NavLink to={"/login"}>Log in</NavLink>
          </p>

          <div className="or-divider">
            <span>OR</span>
          </div>

          <button className="social-btn google-btn"> <FcGoogle size={20} /> Continue with Google</button>
          <button className="social-btn apple-btn"> <IoLogoApple size={20} /> Continue with Apple</button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
