import React from "react";
import "./banner2.scss";
const Banner2 = () => {
  return (
    <>
      <section id="Banner2">
        <div className="container-fluid">
          <div className="row banner-content">
            <div className="col-sm-4">
              <h1>News</h1>
            </div>
            <div className="col-sm-8">
              <div className="search-bar-container">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search query"
                  />
                  <button className="btn btn-dark" type="button">
                    SEARCH
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <img src={img} className='Img2'/> */}
      </section>
    </>
  );
};

export default Banner2;
