import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slidercss.css";
import CryptoSmallChart from "./CryptoSmallChart";

const CardSlider = ({
  gainerLoserText,
  topGainLosers,
  top1GainLoser,
  top2GainLoser,
  top3GainLoser,
  top4GainLoser,
  top5GainLoser,
  top6GainLoser,
  top7GainLoser,
  top8GainLoser,
  top9GainLoser,
  top10GainLoser,
}) => {
  // console.log("all gainloser data", topGainLosers);

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Maximum of 5 cards
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // const gainLoserIndexing = [
  //   top1GainLoser,
  //   top2GainLoser,
  //   top3GainLoser,
  //   top4GainLoser,
  //   top5GainLoser,
  //   top6GainLoser,
  //   top7GainLoser,
  //   top8GainLoser,
  //   top9GainLoser,
  //   top10GainLoser,
  // ];

  // const numberMap = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const TruncateString = (str, length) => {
    if (str?.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  return (
    <div className="slider-containers">
      <Slider {...settings} className="slider">
        {/* {numberMap.map((item, index) => {
        <div className="slider-card-wrapper">
        <div className="card">
          <p>{TruncateString(topGainLosers[1]?.long_name, 16)}</p>
          <p>{topGainLosers[1]?.current_price}</p>
          <div className="slider-chart">
            <p style={{ color: "#00AE31" }}>
              +{topGainLosers[1]?.absolute_change} (+
              {topGainLosers[1]?.percent_change}%)
            </p>

            <CryptoSmallChart
              baseData={top2GainLoser}
              gainerLoserText={gainerLoserText}
            />
          </div>
        </div>
      </div>
        })} */}

         <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[0]?.long_name, 16)}</p>
            <p>{topGainLosers[0]?.current_price}</p>
            <div className="slider-chart">

              {             
                topGainLosers[0]?.previous_close >
                topGainLosers[0]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[0]?.absolute_change} (-
                    {topGainLosers[0]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[0]?.absolute_change} (+
                    {topGainLosers[0]?.percent_change}%)
                  </p>
                )
                }                        

              <CryptoSmallChart
                baseData={top1GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[1]?.long_name, 16)}</p>
            <p>{topGainLosers[1]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[1]?.previous_close >
                topGainLosers[1]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[1]?.absolute_change} (-
                    {topGainLosers[1]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[1]?.absolute_change} (+
                    {topGainLosers[1]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top2GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[2]?.long_name, 16)}</p>
            <p>{topGainLosers[2]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[2]?.previous_close >
                topGainLosers[2]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[2]?.absolute_change} (-
                    {topGainLosers[2]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[2]?.absolute_change} (+
                    {topGainLosers[2]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top3GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[3]?.long_name, 16)}</p>
            <p>{topGainLosers[3]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[3]?.previous_close >
                topGainLosers[3]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[3]?.absolute_change} (-
                    {topGainLosers[3]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[3]?.absolute_change} (+
                    {topGainLosers[3]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top4GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[4]?.long_name, 16)}</p>
            <p>{topGainLosers[4]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[4]?.previous_close >
                topGainLosers[4]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[4]?.absolute_change} (-
                    {topGainLosers[4]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[4]?.absolute_change} (+
                    {topGainLosers[4]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top5GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[5]?.long_name, 16)}</p>
            <p>{topGainLosers[5]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[5]?.previous_close >
                topGainLosers[5]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[5]?.absolute_change} (-
                    {topGainLosers[5]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[5]?.absolute_change} (+
                    {topGainLosers[5]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top6GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[6]?.long_name, 16)}</p>
            <p>{topGainLosers[6]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[6]?.previous_close >
                topGainLosers[6]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[6]?.absolute_change} (-
                    {topGainLosers[6]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[6]?.absolute_change} (+
                    {topGainLosers[6]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top7GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[7]?.long_name, 16)}</p>
            <p>{topGainLosers[7]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[7]?.previous_close >
                topGainLosers[7]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[7]?.absolute_change} (-
                    {topGainLosers[7]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[7]?.absolute_change} (+
                    {topGainLosers[7]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top8GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[8]?.long_name, 16)}</p>
            <p>{topGainLosers[8]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[8]?.previous_close >
                topGainLosers[8]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[8]?.absolute_change} (-
                    {topGainLosers[8]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[8]?.absolute_change} (+
                    {topGainLosers[8]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top9GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div>
        <div className="slider-card-wrapper">
          <div className="card">
            <p>{TruncateString(topGainLosers[9]?.long_name, 16)}</p>
            <p>{topGainLosers[9]?.current_price}</p>
            <div className="slider-chart">
            {             
                topGainLosers[9]?.previous_close >
                topGainLosers[9]?.current_price ? (
                  <p style={{ color: "#d60a22" }}>
                    {" "}
                    -{topGainLosers[9]?.absolute_change} (-
                    {topGainLosers[9]?.percent_change}%)
                  </p>
                ) : (
                  <p style={{ color: "#00AE31" }}>
                    +{topGainLosers[9]?.absolute_change} (+
                    {topGainLosers[9]?.percent_change}%)
                  </p>
                )
                }   

              <CryptoSmallChart
                baseData={top10GainLoser}
                gainerLoserText={gainerLoserText}
              />
            </div>
          </div>
        </div> 
      </Slider>
    </div>
  );
};

{
  /* {cards.map((card, index) => (
          <div className="slider-card-wrapper" key={index}>
            <div className="card">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
            </div>
          </div>
        ))} */
}

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-prev-arrow" onClick={onClick}>
      <svg
        className="arrow-icon"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        ></path>
      </svg>
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-next-arrow" onClick={onClick}>
      <svg
        className="arrow-icon"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        ></path>
      </svg>
    </div>
  );
};

export default CardSlider;
