// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Rectangle 126.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Banner2 {
  position: relative;
  width: 100%;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
#Banner2 .banner-content {
  position: relative;
  text-align: center;
  padding: 20px;
  /* Semi-transparent background for better text readability */
  border-radius: 10px;
}
#Banner2 .banner-content h1 {
  font-family: Libre Franklin;
  font-size: 48px;
  font-weight: 500;
  line-height: 58.18px;
  text-align: center;
  margin-bottom: 20px;
}
#Banner2 .search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#Banner2 .input-group {
  width: 50%;
}
#Banner2 .form-control {
  border-radius: 0;
  border-right: 0;
  height: 40px;
}
#Banner2 .btn {
  border-radius: 0;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/banner2.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EAEA,iFAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAAJ;AAEI;EACE,kBAAA;EACA,kBAAA;EACA,aAAA;EACF,4DAAA;EACE,mBAAA;AAAN;AAEM;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AAAR;AAGI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADR;AAIM;EACE,UAAA;AAFR;AAKM;EACE,gBAAA;EACA,eAAA;EACA,YAAA;AAHR;AAMM;EACE,gBAAA;EACA,YAAA;AAJR","sourcesContent":["#Banner2{\n    position: relative;\n    width: 100%;\n    // height: 526px; /* Full viewport height */\n    background: url('../images/Rectangle\\ 126.png') no-repeat center center/cover;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n  \n    .banner-content {\n      position: relative;\n      text-align: center;\n      padding: 20px;\n    /* Semi-transparent background for better text readability */\n      border-radius: 10px;\n  \n      h1 {\n        font-family: Libre Franklin;\n        font-size: 48px;\n        font-weight: 500;\n        line-height: 58.18px;\n        text-align: center;\n        margin-bottom: 20px;\n      }\n    }\n    .search-bar-container {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding: 20px;\n      }\n      \n      .input-group {\n        width: 50%;\n      }\n      \n      .form-control {\n        border-radius: 0;\n        border-right: 0;\n        height: 40px;\n      }\n      \n      .btn {\n        border-radius: 0;\n        height: 40px;\n      }\n      \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
