import React from 'react';
import './PropertyInvestmentSection.scss';

const PropertyInvestmentSection = () => {
  return (
    <div className="investment-section">
      <div className="content">
      <p>
      We are committed to sharing our insights with the world through our innovative Newsroom which utilises a bespoke and unique Large Language Model, and will serve as a hub for Industry News, Industry Research Papers, Planning Applications, Live Projects, Government Legislation, A Construction Raw Material Price Index and Live market data on publicly traded Property Investment, Development and Construction companies.

</p>
      </div>
      
    </div>
  );
};

export default PropertyInvestmentSection;
