// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 100%;
    padding-right: unset;
    padding-left: unset;
    margin-right: auto;
    margin-left: auto;
}
/* Styles for the container */
.ads-container {
    display: flex;
    justify-content: center; /* Horizontally center the button */
    align-items: center; /* Vertically center the button */
    height: 200px; /* Adjust this as per your container's height */
    background-color: #ffffff; /* White background */
    border: 1px solid #f0f0f0; /* Light border */
    position: relative;
  }
  
  /* Styles for the button */
  .ads-button {
    padding: 8px 16px;
    background-color: #f0f0f0; /* Light grey background */
    border: 1px solid #ccc; /* Border similar to the one in the image */
    border-radius: 4px;
    color: #333; /* Text color */
    cursor: pointer;
    font-size: 16px;
  }
  
  .ads-button:hover {
    background-color: #e0e0e0; /* Slightly darker grey on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oBAAoB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;AACA,6BAA6B;AAC7B;IACI,aAAa;IACb,uBAAuB,EAAE,mCAAmC;IAC5D,mBAAmB,EAAE,iCAAiC;IACtD,aAAa,EAAE,+CAA+C;IAC9D,yBAAyB,EAAE,qBAAqB;IAChD,yBAAyB,EAAE,iBAAiB;IAC5C,kBAAkB;EACpB;;EAEA,0BAA0B;EAC1B;IACE,iBAAiB;IACjB,yBAAyB,EAAE,0BAA0B;IACrD,sBAAsB,EAAE,2CAA2C;IACnE,kBAAkB;IAClB,WAAW,EAAE,eAAe;IAC5B,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,yBAAyB,EAAE,kCAAkC;EAC/D","sourcesContent":[".container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {\n    width: 100%;\n    padding-right: unset;\n    padding-left: unset;\n    margin-right: auto;\n    margin-left: auto;\n}\n/* Styles for the container */\n.ads-container {\n    display: flex;\n    justify-content: center; /* Horizontally center the button */\n    align-items: center; /* Vertically center the button */\n    height: 200px; /* Adjust this as per your container's height */\n    background-color: #ffffff; /* White background */\n    border: 1px solid #f0f0f0; /* Light border */\n    position: relative;\n  }\n  \n  /* Styles for the button */\n  .ads-button {\n    padding: 8px 16px;\n    background-color: #f0f0f0; /* Light grey background */\n    border: 1px solid #ccc; /* Border similar to the one in the image */\n    border-radius: 4px;\n    color: #333; /* Text color */\n    cursor: pointer;\n    font-size: 16px;\n  }\n  \n  .ads-button:hover {\n    background-color: #e0e0e0; /* Slightly darker grey on hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
