import React, { useEffect, useState, useRef } from "react";
import "./news.scss";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { DateRangePicker } from "rsuite";
import { RiCloseLargeFill } from "react-icons/ri";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";

const TruncateString = (str, length) => {
  if (str?.length > length) {
    let temp = str.substring(0, length) + "...";
    return temp;
  }
  return str;
};

const FromatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

const ShortPara = ({
  sectionNews,
  title,
  description,
  url,
  date,
  publisher,
}) => {
  return (
    sectionNews && (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className="latest-news-card">
          <div className="latest-news-desc">
            <h3>{TruncateString(title, 35)}</h3>
            <div className="latest-news-agent">
              <span>{TruncateString(publisher, 26)}</span>
              <span>{date}</span>
            </div>
            <p>{TruncateString(description, 100)}</p>
          </div>
        </div>
      </a>
    )
  );
};

const LongPara = ({
  sectionNews,
  imageUrl,
  title,
  description,
  url,
  date,
  publisher,
}) => {
  return (
    sectionNews && (
      <div className="latest-news-card">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div className="latest-news-img">
            <img src={imageUrl} alt="" />
          </div>
        </a>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="latest-news-desc">
            <h3>{TruncateString(title, 60)}</h3>
            <div className="latest-news-agent">
              <span>{TruncateString(publisher, 26)}</span>
              <span>{date}</span>
            </div>
            <p>{TruncateString(description, 153)}</p>
          </div>
        </a>
      </div>
    )
  );
};

const News = () => {
  const [topNews, setTopNews] = useState("");
  const [sectionNews, setSectionNews] = useState("");
  const [trendingNews, setTrendingNews] = useState("");
  const [allNews, setAllNews] = useState("");
  const [publisherList, setPublisherList] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [isDrpoDownVisible, setIsDropDownVisible] = useState(false);


  useEffect(() => {
    getTopNews();
    getSectionNews();
    getTrendingNews();
    getPublisherList();

  }, []);

  useEffect(() => {
    if(endDate){
      filterLatestNewsBYdate();  
    }else{
      setPopupVisible(false);
      getSectionNews();
    }
  }, [endDate]);

  async function getTopNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/get_top4_news/");
      const resp2 = await resp1.json();
      setTopNews(resp2);
    } catch (error) {
      console.log("error in fetching top news", error);
    }
  }

  async function getSectionNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/section_2_news/");
      const resp2 = await resp1.json();
      setAllNews(resp2);
      const page = Math.ceil(resp2.count / 7);
      setTotalPage(page);
      setSectionNews(resp2.data.slice(0, 7));
    } catch (error) {
      console.log("error in fetching section news in news page", error);
    }
  }

  async function getTrendingNews() {
    try {
      const resp1 = await fetch(
        "https://backend.inferredtheorem.com/get_news_by_publisher_news/"
      );
      const resp2 = await resp1.json();
      setTrendingNews(resp2.data);
    } catch (error) {
      console.log("error in fetching trending news in news page", error);
    }
  }

  async function getPublisherList() {
    try {
      const resp = await fetch(
        "https://backend.inferredtheorem.com/publisher_dropdown_news/"
      );
      const result = await resp.json();
      setPublisherList(result.publishers);
    } catch (error) {
      console.log("error in Drop down in news page");
    }
  }

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getSectionNews();
  };

  const formatDate2 = (date) => {
    return date instanceof Date && !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "Not selected";
  };

  const formatDate3 = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const filterLatestNewsBYdate = async () => {

    

    if (!startDate || !endDate) return;

    const start_date = formatDate3(startDate);
    const end_date = formatDate3(endDate);

    // console.log("start date",start_date);
    // console.log("end date",end_date);

    try {
      const resp = await fetch(
        `https://backend.inferredtheorem.com/from_to_date_news/?from=${start_date}&to=${end_date}`
      );
      
      const result = await resp.json();
      // console.log("result", result);

      if (result.data) {
        setAllNews(result);
        setSectionNews(result.data.slice(0, 7));
        const page = Math.ceil(result.count / 7);
        setTotalPage(page);
        setPopupVisible(false);
          setCurrentPage(1);
          setInputPage(1);      
      } else {
        setSectionNews(null);
        setPopupVisible(true);
        setTotalPage(1);
        setCurrentPage(1);
        setInputPage(1);
      }
    } catch (error) {
      console.log("error in fiter data by date", error);
      getSectionNews();
    }
  };

  function handleFetchNextPage() {
    const newpage = Number(currentPage) + 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function FetchPage(pageNumber) {
    let data;
    if (pageNumber > 1) {
      data = allNews.data.slice((pageNumber - 1) * 7, pageNumber * 7);
    } else {
      data = allNews.data.slice(0, 7);
    }
    setSectionNews(data);
  }

  function handleFetchPrevPage() {
    const newpage = currentPage - 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  async function filtertrendingNewsByPublisher(publisher) {
    const resp = await fetch(
      `https://backend.inferredtheorem.com/get_news_by_publisher_news/?publisher=${publisher}`
    );
    const result = await resp.json();
    setTrendingNews(result.data);
    setIsDropDownVisible(false);
  }

  function handleInputChange(e) {
    const value = e.target.value;
    if (value < 0) return;
    setInputPage(value);
    setCurrentPage(value);
    const data = allNews.data.slice((value - 1) * 7, value * 7);
    setSectionNews(data);
  }

  return (
    <section
      className="PlanningApplications"
      style={{
        color: "black",
        marginTop: "20px",
      }}
    >
      <div className="planning">
        <div className="news-page">
          {/* top 4 Newas Start */}
          <div className="top-news-container">
            {topNews[0] && (
              <div className="box top-1-news">
                <a
                  href={topNews[0]?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <img src={`${topNews[0]?.image_url}`} alt="news-banner" />
                  <div className="top-news-description">
                    <h3>{TruncateString(topNews[0]?.title, 50)}</h3>
                    <div className="top-news-sub-desc">
                      <p>{TruncateString(topNews[0]?.publisher, 20)}</p>
                      <p>{FromatDate(topNews[0]?.date)}</p>
                    </div>
                  </div>
                </a>
              </div>
            )}

            {topNews[1] && (
              <div className="box top-2-news">
                <a href={topNews[1]?.url} target="_blank">
                  <div className="top-2-news-left-container">
                    <p>{TruncateString(topNews[1]?.description, 245)}</p>
                  </div>
                </a>
                <a
                  href={topNews[1]?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <div className="top-2-news-right-container">
                    <img src={`${topNews[1]?.image_url}`} alt="" />

                    <div className="top-2-news-description">
                      <h3>{TruncateString(topNews[1]?.title, 40)}</h3>
                      <div className="top-news-sub-desc">
                        <span>{TruncateString(topNews[1]?.publisher, 20)}</span>
                        <span>{FromatDate(topNews[1]?.date)}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )}

            {topNews[2] && (
              <div className="box top-3-news ">
                <div className="top-3-news-image">
                  <a href={topNews[2]?.url} target="_blank">
                    <img src={`${topNews[2]?.image_url}`} alt="" />
                  </a>
                </div>
                <div className="top-3-news-right-container">
                  <a
                    href={topNews[2]?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <h3>{TruncateString(topNews[2]?.title, 50)}</h3>
                    <div className="top-3-news-sub-desc">
                      {/* <p>{FromatDate(topNews[2]?.date)}</p> */}
                      <span>{TruncateString(topNews[2]?.publisher, 20)}</span>
                      <span>{FromatDate(topNews[2]?.date)}</span>
                    </div>
                    <p>{TruncateString(topNews[2]?.description, 185)}</p>
                  </a>
                </div>
              </div>
            )}

            {topNews[3] && (
              <div className="box top-4-news ">
                <div className="top-4-news-left-container">
                  <a
                    href={topNews[3]?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <p className="top-4-news-heading">
                      {TruncateString(topNews[3]?.title, 50)}
                    </p>
                    <div className="top-4-news-sub-desc">
                      {/* <p>{FromatDate(topNews[3]?.date)}</p> */}
                      <span>{TruncateString(topNews[3]?.publisher, 20)}</span>
                      <span>{FromatDate(topNews[3]?.date)}</span>
                    </div>
                    <p className="top-4-news-description">
                      {TruncateString(topNews[3]?.description, 225)}
                    </p>
                  </a>
                </div>
                <div className="top-4-news-img">
                  <a href={topNews[3]?.url} target="_blank">
                    <img src={`${topNews[3]?.image_url}`} alt="" />
                  </a>
                </div>
              </div>
            )}
          </div>
          {/* top 4 News End */}

          {/* latest News start */}
          <div className="bottom-news-container">
            <div className="latest-news-container">
              <div className="latest-news-heading">
                <h3>Latest</h3>
                <div className="bottom-border"></div>
                <DateRangePicker
                  format="MM/dd/yyyy"
                  character=" – "
                  placeholder="Select a Date range"
                  onChange={handleDateChange}
                />
                {/* <div
                  className="latest-news-date-button"
                  onClick={() => setIsDateVisible(prev=> !prev)}
                >
                  <button>Date</button>
                  <IoIosArrowDown />
                {isDateVisible && (
                  <div className="latest-news-date-visible" >
                    <DatePicker
                      placeholder="Start Date"
                      style={{ width: 130, marginRight: "1rem" }}
                      onChange={handleDateChange}
                    />
                    <DatePicker
                      placeholder="End Date"
                      style={{ width: 130 }}
                      onChange={handleDateChange}
                    />
                  </div>
                )}
                </div> */}
              </div>
              {isPopupVisible && (
                <div className="popup-overlay">
                  <div className="popup-content">
                    <RiCloseLargeFill className="noData" />
                    <h2>No Data Found</h2>
                    <p>Sorry, there is no data available to display.</p>
                    <button onClick={handleClosePopup}>Close</button>
                  </div>
                </div>
              )}
              {sectionNews && (
                <div className="latest-news-cards">
                  {sectionNews[0] && (
                    <LongPara
                      sectionNews={sectionNews}
                      imageUrl={sectionNews[0]?.image_url}
                      title={sectionNews[0]?.title}
                      description={sectionNews[0]?.description}
                      url={sectionNews[0]?.url}
                      date={sectionNews[0]?.date}
                      publisher={sectionNews[0]?.publisher}
                    />
                  )}

                  {sectionNews[1] && (
                    <LongPara
                      sectionNews={sectionNews}
                      imageUrl={sectionNews[1]?.image_url}
                      title={sectionNews[1]?.title}
                      description={sectionNews[1]?.description}
                      url={sectionNews[1]?.url}
                      date={sectionNews[1]?.date}
                      publisher={sectionNews[1]?.publisher}
                    />
                  )}

                  {sectionNews[2] && (
                    <LongPara
                      sectionNews={sectionNews}
                      imageUrl={sectionNews[2]?.image_url}
                      title={sectionNews[2]?.title}
                      description={sectionNews[2]?.description}
                      url={sectionNews[2]?.url}
                      date={sectionNews[2]?.date}
                      publisher={sectionNews[2]?.publisher}
                    />
                  )}

                  {sectionNews[3] && (
                    <div className="latest-news-card">
                      {sectionNews[3] && (
                        <a
                          href={sectionNews[3]?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div className="latest-news-desc">
                            <h3>{TruncateString(sectionNews[3]?.title, 35)}</h3>
                            <div className="latest-news-agent">
                              <span>{sectionNews[3]?.publisher}</span>
                              <span>{sectionNews[3]?.date}</span>
                            </div>
                            <p>
                              {TruncateString(sectionNews[3]?.description, 100)}
                            </p>
                          </div>
                        </a>
                      )}
                    </div>
                  )}

                  {sectionNews[4] && (
                    <LongPara
                      sectionNews={sectionNews}
                      imageUrl={sectionNews[4]?.image_url}
                      title={sectionNews[4]?.title}
                      description={sectionNews[4]?.description}
                      url={sectionNews[4]?.url}
                      date={sectionNews[4]?.date}
                      publisher={sectionNews[4]?.publisher}
                    />
                  )}

                  {sectionNews[5] && (
                    <ShortPara
                      sectionNews={sectionNews}
                      title={sectionNews[5]?.title}
                      description={sectionNews[5]?.description}
                      url={sectionNews[5]?.url}
                      date={sectionNews[5]?.date}
                      publisher={sectionNews[5]?.publisher}
                    />
                  )}

                  {sectionNews[6] && (
                    <LongPara
                      sectionNews={sectionNews}
                      imageUrl={sectionNews[6]?.image_url}
                      title={sectionNews[6]?.title}
                      description={sectionNews[6]?.description}
                      url={sectionNews[6]?.url}
                      date={sectionNews[6]?.date}
                      publisher={sectionNews[6]?.publisher}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="trending-news-container">
              <div className="trending-headline-text">
                <h3>Trending Headlines</h3>
                {/* <div class="dropdown">
                  <button
                    class="btn btn-dark dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Publisher
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    {publisherList &&
                      publisherList.slice(0, 5).map((item, index) => (
                        <li key={index} style={{ cursor: "pointer" }}>
                          <a
                            class="dropdown-item"
                            onClick={(e) => filtertrendingNewsByPublisher(item)}
                          >
                            {TruncateString(item, 11)}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div> */}
                <div
                  className="trending-news-dropdown"
                  onClick={() => setIsDropDownVisible((prev) => !prev)}
                >
                  <button>Publisher</button>
                  <IoIosArrowDown color="white" />
                </div>
                {isDrpoDownVisible && (
                  <div className="dropDown-list">
                    {publisherList &&
                      publisherList.map((item, index) => (
                        <p
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => filtertrendingNewsByPublisher(item)}
                        >
                          {TruncateString(item, 12)}
                          {/* Hello */}
                        </p>
                      ))}
                  </div>
                )}
              </div>

              <div className="trending-headline-card">
                {trendingNews &&
                  trendingNews.slice(0, 8).map((item, index) => (
                    <a
                      target="_blank"
                      href={item?.url}
                      key={item?.id}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className={`trending-headline-box ${
                          index !== 7 && "add-border"
                        }`}
                      >
                        <div className="trending-headline-box-image">
                          <img src={item.image_url} alt="" />
                        </div>
                        <div className="trending-headline-rignt-container">
                          <h3>{TruncateString(item.title, 50)}</h3>

                          <p>{TruncateString(item.description, 110)}</p>
                          <div className="trending-headline-rignt-container-date">
                            <span>{TruncateString(item.publisher, 20)}</span>
                            <span>{FromatDate(item.date)}</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
              </div>
            </div>
          </div>

          {/* latest News end */}

          {/* pagination start */}
          <div className="pagination-controls">
            <div className="page-button">
              <button
                className={`${
                  currentPage === 1 ? "next-button-display" : "next-button"
                }`}
                onClick={handleFetchPrevPage}
              >
                <FaArrowLeftLong size={15} /> Prev Page
              </button>

              <button
                className={`${
                  currentPage === totalPage
                    ? "next-button-display"
                    : "next-button"
                }`}
                onClick={handleFetchNextPage}
              >
                Next Page <FaArrowRightLong size={15} />
              </button>
            </div>

            <div className="pagination-count">
              <span>Page</span>
              <input
                type="number"
                name=""
                id=""
                value={inputPage}
                onChange={handleInputChange}
                // onKeyDown={handlePageKeyPress}
              />
              <span>of {totalPage}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
