import React, { useEffect, useState } from "react";
import "./ConstructionRawMaterial.scss";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { DateRangePicker, Footer } from "rsuite";
import axios from "axios";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { RiCloseLargeFill } from "react-icons/ri";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import RawMaterialTable from "../component/RawMaterialTable";
import MaterialPopup from "../component/MaterialPopup";
import CRMChart from "../component/CRMChart";
import CRMCheckBox from "../component/CRMCheckBox";

const TruncateString = (str, length) => {
  if (str?.length > length) {
    let temp = str.substring(0, length) + "...";
    return temp;
  }
  return str;
};

const FromatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};


const LongPara = ({ imageUrl, title, description, url, date }) => {
  return ( 
      <div className="latest-news-card">
        <a href={url} rel="noopener noreferrer" target="_blank">
          <div className="latest-news-img">
            <img src={imageUrl} alt="" />
          </div>
        </a>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="latest-news-desc">
            <h3>{TruncateString(title, 60)}</h3>
            <div className="latest-news-agent">
              <span>{FromatDate(date)}</span>
            </div>
            <p>{TruncateString(description, 300)}</p>
          </div>
        </a>
      </div>
  );
};

const ConstructionRawMaterial = () => {
  const [page, setPage] = React.useState(1);
  const [latestNews, setlatestNews] = React.useState("");
  const [sectionNews, setSectionNews] = useState();
  const [allNews, setAllNews] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isMaterailCardHidden, setIsMaterailCardHidden] = useState(true);


  // const [monthlyData, setMonthlyData] = useState("");
  const [monthlyData, setMonthlyData] = useState("");
  const [year, setYear] = useState(2024);
  const [showYear, setShowYear] = useState("");


  useEffect(() => {
    getSectionNews();
    // getDataByYear();
  }, []);

   useEffect(()=>{
    getDataByYear();
   },[year])

  async function getSectionNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/section_2_cm/");
      const resp2 = await resp1.json();
      setAllNews(resp2);
      const page = Math.ceil(resp2.count / 9);
      setTotalPage(page);
      setSectionNews(resp2.data.slice(0, 9));
    } catch (error) {
      console.log("error in fetching section news", error);
    }
  }  

  const TruncateString = (str, length) => {
    if (str?.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  const FormatDate2 = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  useEffect(() => {
    // console.log("endDate", endDate);
    if (endDate) {
      filterLatestNewsBYdate();
    } else {
      getSectionNews();
      setPopupVisible(false);
      setTotalPage(1);
        setCurrentPage(1);
        setInputPage(1);
    }
  }, [endDate]);

  const formatDate3 = (dateStr) => {
    const date = new Date(dateStr);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const filterLatestNewsBYdate = () => {
    const start_date = formatDate3(startDate);
    const end_date = formatDate3(endDate);

    //  console.log("this is start date",start_date);
    //  console.log("this is end date",end_date);

    axios
      .get(
        `https://backend.inferredtheorem.com/from_to_date_cm/?from=${start_date}&to=${end_date}`
      )
      .then((response) => {
        if (response.data.count == 0) {
          setSectionNews(null);
        setPopupVisible(true);
        setTotalPage(1);
        setCurrentPage(1);
        setInputPage(1);
        } else {       
          setAllNews(response.data);
      const page = Math.ceil(response.data.count / 9) || 1 ;
      setTotalPage(page);
      setCurrentPage(1);
        setInputPage(1);
      setSectionNews(response.data?.data?.slice(0, 9)); 
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function filtertrendingNewsBYarea(publisher) {
    try {
      const resp = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_category/?category=${publisher}`
      );
      const result = await resp.json();
      // console.log(result.data);
    } catch (error) {
      console.log("error in filter news by date", error);
    }
  }

  const reduxSearch = useSelector((state) => state.searchreducer.searchNews);

  useEffect(() => {
    handleSearch();
  }, [reduxSearch]);

  const handleSearch = () => {
    axios
      .get(`https://backend.inferredtheorem.com/search_cm/?search=${reduxSearch}`)
      .then((response) => {
        if (response.data.count == 0) {
        } else {
          setlatestNews(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 
  function handleFetchNextPage() {
    const newpage = Number(currentPage) + 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function FetchPage(pageNumber) {
    let data1;
    if (pageNumber > 1) {
      data1 = allNews?.data?.slice((pageNumber - 1) * 9, pageNumber * 9);
    } else {
      data1 = allNews?.data?.slice(0, 9);
    }
    setSectionNews(data1);
    // console.log("data1", data1);
  }

  function handleFetchPrevPage() {
    const newpage = currentPage - 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function handleInputChange(e) {
    const value = e.target.value;
    if (value < 0) {
      return;
    }
    setInputPage(value);
    setCurrentPage(value);
    const data = allNews.data.slice((value - 1) * 9, value * 9);
    setSectionNews(data);
  }

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getSectionNews();
  };

  async function getDataByYear() {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_year_month/?year=${year}`
      );
      const result = await response.json();
      setMonthlyData(convertTo2DArray(result.data));
    } catch (error) {
      console.log("error in fetching current year data", error);
    }
  }


  function convertTo2DArray(data) {
    return data?.map((item) => Object.values(item));
  }

  const tableData = [
    "Raw Material",
    "Gravel, Sand, Clays & Kaolin - excluding Aggregate Levy",
    "Gravel, Sand, Clays & Kaolin - including  Aggregate Levy",
    "Bituminous Mixtures based on Natural & Artificial Stone",
    "Cement",
    "Ready-mixed concrete",
    "Pre-cast concrete products",
    "Concrete reinforcing bars (steel)",
    "Imported sawn or planed wood",
    "Imported plywood",
    "Fabricated structural steel",
    "Central heating boilers",
    "Taps and valves for sanitaryware",
    "Pipes and fittings (rigid)",
    "Pipes and fittings (flexible)",
    "Sanitaryware",
    "Insulating materials (thermal or acoustic)",
    "Paint (aqueous)",
    "Paint (non-aqueous)",
    "Kitchen furniture",
  ];

  console.log("selected year", year);

  return (
    <>
      <section
        className="ConstructionRawMaterial"
        style={{
          color: "black",
          marginTop: "20px",
        }}
      >
        <div
          className= "planning"
        >
          <div className="top-crm-container" >
            <h1 className="top-crm-container-heading" >Construction Raw Material Data</h1>
            
            {<div className="top-crm-chart-seaction">
            <CRMChart setIsMaterailCardHidden={setIsMaterailCardHidden} year={year} setYear={setYear}  />
            </div> }
          </div>

          <div className="bottom-news-container">


           { isMaterailCardHidden ? (<div className="latest-news-container">
              <div className="latest-news-heading">
                <h3>Latest</h3>
                <div className="bottom-border"></div>
                <DateRangePicker
                  format="MM/dd/yyyy"
                  character=" – "
                  placeholder="Select a Date range"
                  onChange={handleDateChange}
                />
              </div>
              {isPopupVisible && (
                <div className="popup-overlay">
                  <div className="popup-content">
                    <RiCloseLargeFill className="noData" />
                    <h2>No Data Found</h2>
                    <p>Sorry, there is no data available to display.</p>
                    <button onClick={handleClosePopup}>Close</button>
                  </div>
                </div>
              )}
                <div className="latest-news-cards">              
                  {sectionNews?.map((item, index)=> <>
                  {sectionNews[index] &&
                    <LongPara
                      imageUrl={item?.image_url}
                      title={item?.title}
                      description={item?.description}
                      url={item?.url}
                      date={item?.date}
                    />}
                  </>)
                    }                          
                </div>                                    
              
            </div>) : 
            <MaterialPopup tableData={tableData} setIsMaterailCardHidden={setIsMaterailCardHidden}  year={year} setYear={setYear} 
            setMonthlyData={setMonthlyData}
            monthlyData={monthlyData}
            />
            }

          </div>

         { isMaterailCardHidden && <div className="pagination-controls">
            <div className="page-button">
              <button
                className={`${
                  currentPage === 1 ? "next-button-display" : "next-button"
                }`}
                onClick={handleFetchPrevPage}
              >
                <FaArrowLeftLong size={15} /> Prev Page
              </button>

              <button
                className={`${
                  currentPage === totalPage
                    ? "next-button-display"
                    : "next-button"
                }`}
                onClick={handleFetchNextPage}
              >
                Next Page <FaArrowRightLong size={15} />
              </button>
            </div>

            <div className="pagination-count">
              <span>Page</span>
              <input
                type="number"
                name=""
                id=""
                value={inputPage}
                onChange={handleInputChange}
              />
              <span>of {totalPage}</span>
            </div>
          </div>}

      
        </div>
      </section>
    </>
  );
};

export default ConstructionRawMaterial;





