import React, { useState, useEffect } from 'react';
import { FaHeart, FaComment, FaBookmark, FaShareAlt } from 'react-icons/fa';
import './Newscard.css';

const NewsCard = ({ article, isActive }) => {
    const [likes, setLikes] = useState(article.likes || 0);
    const [hasLiked, setHasLiked] = useState(() => {
        // Check local storage to see if the user has liked this post before
        const likedArticles = JSON.parse(localStorage.getItem("likedArticles")) || [];
        return likedArticles.includes(article.postId);
    });

    const [comments, setComments] = useState(article.comments || []);
    const [newComment, setNewComment] = useState('');
    const [isBookmarked, setIsBookmarked] = useState(() => {
        const savedArticles = JSON.parse(localStorage.getItem("bookmarkedArticles")) || [];
        return savedArticles.some(saved => saved.id === article.id);
    });

    useEffect(() => {
        // Update localStorage when the `hasLiked` state changes
        const likedArticles = JSON.parse(localStorage.getItem("likedArticles")) || [];
        if (hasLiked) {
            if (!likedArticles.includes(article.postId)) {
                likedArticles.push(article.postId);
                localStorage.setItem("likedArticles", JSON.stringify(likedArticles));
            }
        } else {
            const updatedLikedArticles = likedArticles.filter(id => id !== article.postId);
            localStorage.setItem("likedArticles", JSON.stringify(updatedLikedArticles));
        }
    }, [hasLiked, article.postId]);

    const handleLike = async () => {
        const userData = JSON.parse(localStorage.getItem('userData')); // Retrieve user data
    
        if (!userData || !userData.id) {
            console.error("User not logged in.");
            return;
        }
    
        try {
            // Determine if we are liking or unliking
            const action = hasLiked ? 'unlike' : 'like';
            const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${article.postId}/${action}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Specify JSON content type
                    'Authorization': `Bearer ${userData.token}`, // Add authorization token if needed
                },
                body: JSON.stringify({ userId: userData.id }), // Send userId in the request body
            });
    
            if (!response.ok) {
                const errorData = await response.json(); // Get error details from the response
                throw new Error(`Error: ${errorData.message || 'Failed to toggle like'}`);
            }
    
            const data = await response.json();
    
            // Update likes and like status based on server response
            setLikes(data.likes); // Update the likes count
            setHasLiked(data.hasLiked); // Update like status based on server response
        } catch (error) {
            console.error('Error liking/unliking article:', error);
        }
    };
    

    const handleBookmark = () => {
        const savedArticles = JSON.parse(localStorage.getItem("bookmarkedArticles")) || [];
        const articleExists = savedArticles.find(saved => saved.id === article.id);

        if (articleExists) {
            const updatedArticles = savedArticles.filter(saved => saved.id !== article.id);
            localStorage.setItem("bookmarkedArticles", JSON.stringify(updatedArticles));
            setIsBookmarked(false);
            alert("Article removed from your library!");
        } else {
            const updatedArticles = [...savedArticles, article];
            localStorage.setItem("bookmarkedArticles", JSON.stringify(updatedArticles));
            setIsBookmarked(true);
            alert("Article bookmarked!");
        }
    };

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: article.title,
                text: article.preview,
                url: window.location.href,
            }).then(() => console.log('Article shared successfully!'))
                .catch(error => console.log('Error sharing the article:', error));
        } else {
            alert('Sharing is not supported on this browser.');
        }
    };

    const formattedDate = new Date(article.createdAt).toLocaleDateString();

    return (
        <div className={`news-card ${isActive ? 'active' : ''}`}>
            <div className="news-info">
                <h3 className="news-title">{article.title}</h3>
                <p className="news-preview">{article.preview}</p>
                <div className='tag_container'>
                    <div className="news-meta">
                        <span className="date">{formattedDate}</span>
                        <span
                            onClick={handleLike}
                            className={`like-btn ${hasLiked ? 'liked' : ''}`}
                        >
                            <FaHeart /> {likes}
                        </span>

                        <span className="comment-btn">
                            <FaComment /> {comments.length}
                        </span>
                    </div>
                    <div className="news-actions">
                        <FaBookmark
                            className={`news-icon ${isBookmarked ? 'bookmarked' : ''}`}
                            onClick={handleBookmark}
                        />
                        <FaShareAlt className="news-icon" onClick={handleShare} />
                    </div>
                </div>
            </div>
            <div className={`news-image-placeholder ${isActive ? 'active' : ''}`}>
                <img src={article.pictureUrl || "https://via.placeholder.com/150"} alt="Article" />
            </div>
        </div>
    );
};

export default NewsCard;
