import React from "react";
import "./banner.scss";
import img from "../images/Objects.png";
import video_v from "../images/banner_v.mp4";
import RealState2 from "./RealState2";

const Banner = () => {
  return (
    <>
      <section id="Banner">
        <div className="bg">
          <div className="media-container">
            <video src={video_v} className="video" autoPlay loop muted />
            <img src={img} className="image" alt="Decorative" />
            <div className="bgTransparent"></div>
            <div className="content">
              <h1>A Quantitative Approach to Value and Risk in Real Estate</h1>
              <p>
              Inferred Theorem is a Real Estate technology  company that provides scientific and data-driven solutions for Institutions, Property Investors, Developers, and Real Estate Professionals.


              </p>
              <p>
              Inferred Theorem aims to become a pivotal tool in the real estate investment industry, offering a one-stop solution for professionals seeking appraisal solutions, performance indices, predictive insights and analytics, quantitative and qualitative data derived through our methodologies and market insights. 



              </p>
              <p>
              The adoption of this technology offers a comprehensive and advanced solution for real estate professionals, investors and decision-makers, leveraging cutting edge technologies to enhance precision, productivity, decision-making abilities and market transparency.

              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <RealState2 /> */}
    </>
  );
};

export default Banner;
