import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../component/Post_blog.css'; // External CSS
import { NavLink } from 'react-router-dom';
import { Button } from 'rsuite';

const PostsTable = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);  // Pagination state
  const postsPerPage = 6;  // Number of posts per page
  const [searchQuery, setSearchQuery] = useState('');  // State for search query

  const navigate = useNavigate();

  // Fetch posts from the API
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch('https://www.inferredtheorem.com:5000/api/auth/Posts');
        const data = await response.json();
        setPosts(data);
      } catch (error) {
        console.error('Failed to fetch posts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Handle edit post redirect
  const handleEdit = (post) => {
    console.log('Sending data for edit:', post); // Log the post data
    navigate('/posts/edit', { state: { post } });  // Pass the post object through state
  };

  const handleDelete = async (postId) => {
    try {
      const token = localStorage.getItem('token'); // Retrieve the token from local storage
  
      const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the headers
        },
      });
  
      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Failed to delete post');
      }
  
      // If successful, perform any necessary state updates
      console.log('Post deleted successfully');
    } catch (error) {
      console.error('Failed to delete post:', error);
      // Handle error (show notification, etc.)
    }
  };
  

  // Pagination logic: calculate total pages
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Get current posts based on the page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // Filter posts based on search query
  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Slice the filtered posts for current page
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Handle page navigation
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when search changes
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="posts-table-container">
      <div className="posts-header">
        <NavLink to="/Post">
          <button className="new-post-btn">+ New Post</button>
        </NavLink>
        <input
          type="text"
          placeholder="Search by title"
          className="search-bar"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>

      <table className="posts-table">
        <thead>
          <tr>
            <th>Picture</th>
            <th>Title</th>
            <th>Delete</th>
            <th>Update</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.map((post) => (
            <tr key={post._id}> {/* Ensure that each `tr` has a unique key */}
              <td>
                <img 
                  className="post-picture" 
                  src={post.pictureUrl} 
                  alt="Post" 
                />
              </td>
              <td>{post.title}</td>
              <td>
                <i className="fa fa-trash" onClick={() => handleDelete(post._id)} />
              </td>
              <td>
                <i className="fa fa-edit" onClick={() => handleEdit(post)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <div style={{marginLeft:'100px'}}>
        <button
          className="prev-btn"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className="next-btn"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        </div>
        <p style={{marginRight:'-100px'}}>
          Page {currentPage} of {totalPages}
        </p>
        
      </div>
    </div>
  );
};

export default PostsTable;
