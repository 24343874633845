import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow"; 


const TruncateString = (str, length) => {
  if (str?.length > length) {
    let temp = str.substring(0, length) + "...";
    return temp;
  }
  return str;
};

export default function RawMaterail2({ monthlyData }) {
  
  const defaultLabels = Array(12).fill("");

  const columns2 = [
    {
      id: "jan",
      label: monthlyData?.[0]?.[1] || defaultLabels[0],
      align: "center",
      minWidth: 60,
    },
    {
      id: "feb",
      label: monthlyData?.[1]?.[1] || defaultLabels[1],
      align: "center",
      minWidth: 60,
    },
    {
      id: "mar",
      label: monthlyData?.[2]?.[1] || defaultLabels[2],
      align: "center",
      format: (value) => value?.toLocaleString("en-US"),
      minWidth: 60,
    },
    {
      id: "apr",
      label: monthlyData?.[3]?.[1] || defaultLabels[3],
      align: "center",
      format: (value) => value?.toLocaleString("en-US"),
      minWidth: 60,
    },
    {
      id: "may",
      label: monthlyData?.[4]?.[1] || defaultLabels[4],
      align: "center",
      format: (value) => value?.toFixed(2),
      minWidth: 60,
    },
    {
      id: "jun",
      label: monthlyData?.[5]?.[1] || defaultLabels[5],
      align: "center",
      minWidth: 60,
    },
    {
      id: "jul",
      label: monthlyData?.[6]?.[1] || defaultLabels[6],
      align: "center",
      minWidth: 60,
    },
    {
      id: "aug",
      label: monthlyData?.[7]?.[1] || defaultLabels[7],
      align: "center",
      format: (value) => value?.toLocaleString("en-US"),
      minWidth: 60,
    },
    {
      id: "sep",
      label: monthlyData?.[8]?.[1] || defaultLabels[8],
      align: "center",
      format: (value) => value?.toLocaleString("en-US"),
      minWidth: 60,
    },
    {
      id: "oct",
      label: monthlyData?.[9]?.[1] || defaultLabels[9],
      align: "center",
      format: (value) => value?.toFixed(2),
      minWidth: 60,
    },
    {
      id: "nov",
      label: monthlyData?.[10]?.[1] || defaultLabels[10],
      align: "center",
      format: (value) => value?.toFixed(2),
      minWidth: 60,
    },
    {
      id: "dec",
      label: monthlyData?.[11]?.[1] || defaultLabels[11],
      align: "center",
      format: (value) => value?.toFixed(2),
      minWidth: 60,
    },
  ];

  function createData2(
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec
  ) {
    return { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec };
  }

  const rows2 = monthlyData?.length
    ? [
        createData2(
          monthlyData?.[0]?.[2] ?? "",
          monthlyData?.[1]?.[2] ?? "",
          monthlyData?.[2]?.[2] ?? "",
          monthlyData?.[3]?.[2] ?? "",
          monthlyData?.[4]?.[2] ?? "",
          monthlyData?.[5]?.[2] ?? "",
          monthlyData?.[6]?.[2] ?? "",
          monthlyData?.[7]?.[2] ?? "",
          monthlyData?.[8]?.[2] ?? "",
          monthlyData?.[9]?.[2] ?? "",
          monthlyData?.[10]?.[2] ?? "",
          monthlyData?.[11]?.[2] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[3] ?? "",
          monthlyData?.[1]?.[3] ?? "",
          monthlyData?.[2]?.[3] ?? "",
          monthlyData?.[3]?.[3] ?? "",
          monthlyData?.[4]?.[3] ?? "",
          monthlyData?.[5]?.[3] ?? "",
          monthlyData?.[6]?.[3] ?? "",
          monthlyData?.[7]?.[3] ?? "",
          monthlyData?.[8]?.[3] ?? "",
          monthlyData?.[9]?.[3] ?? "",
          monthlyData?.[10]?.[3] ?? "",
          monthlyData?.[11]?.[3] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[4] ?? "",
          monthlyData?.[1]?.[4] ?? "",
          monthlyData?.[2]?.[4] ?? "",
          monthlyData?.[3]?.[4] ?? "",
          monthlyData?.[4]?.[4] ?? "",
          monthlyData?.[5]?.[4] ?? "",
          monthlyData?.[6]?.[4] ?? "",
          monthlyData?.[7]?.[4] ?? "",
          monthlyData?.[8]?.[4] ?? "",
          monthlyData?.[9]?.[4] ?? "",
          monthlyData?.[10]?.[4] ?? "",
          monthlyData?.[11]?.[4] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[5] ?? "",
          monthlyData?.[1]?.[5] ?? "",
          monthlyData?.[2]?.[5] ?? "",
          monthlyData?.[3]?.[5] ?? "",
          monthlyData?.[4]?.[5] ?? "",
          monthlyData?.[5]?.[5] ?? "",
          monthlyData?.[6]?.[5] ?? "",
          monthlyData?.[7]?.[5] ?? "",
          monthlyData?.[8]?.[5] ?? "",
          monthlyData?.[9]?.[5] ?? "",
          monthlyData?.[10]?.[5] ?? "",
          monthlyData?.[11]?.[5] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[6] ?? "",
          monthlyData?.[1]?.[6] ?? "",
          monthlyData?.[2]?.[6] ?? "",
          monthlyData?.[3]?.[6] ?? "",
          monthlyData?.[4]?.[6] ?? "",
          monthlyData?.[5]?.[6] ?? "",
          monthlyData?.[6]?.[6] ?? "",
          monthlyData?.[7]?.[6] ?? "",
          monthlyData?.[8]?.[6] ?? "",
          monthlyData?.[9]?.[6] ?? "",
          monthlyData?.[10]?.[6] ?? "",
          monthlyData?.[11]?.[6] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[7] ?? "",
          monthlyData?.[1]?.[7] ?? "",
          monthlyData?.[2]?.[7] ?? "",
          monthlyData?.[3]?.[7] ?? "",
          monthlyData?.[4]?.[7] ?? "",
          monthlyData?.[5]?.[7] ?? "",
          monthlyData?.[6]?.[7] ?? "",
          monthlyData?.[7]?.[7] ?? "",
          monthlyData?.[8]?.[7] ?? "",
          monthlyData?.[9]?.[7] ?? "",
          monthlyData?.[10]?.[7] ?? "",
          monthlyData?.[11]?.[7] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[8] ?? "",
          monthlyData?.[1]?.[8] ?? "",
          monthlyData?.[2]?.[8] ?? "",
          monthlyData?.[3]?.[8] ?? "",
          monthlyData?.[4]?.[8] ?? "",
          monthlyData?.[5]?.[8] ?? "",
          monthlyData?.[6]?.[8] ?? "",
          monthlyData?.[7]?.[8] ?? "",
          monthlyData?.[8]?.[8] ?? "",
          monthlyData?.[9]?.[8] ?? "",
          monthlyData?.[10]?.[8] ?? "",
          monthlyData?.[11]?.[8] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[9] ?? "",
          monthlyData?.[1]?.[9] ?? "",
          monthlyData?.[2]?.[9] ?? "",
          monthlyData?.[3]?.[9] ?? "",
          monthlyData?.[4]?.[9] ?? "",
          monthlyData?.[5]?.[9] ?? "",
          monthlyData?.[6]?.[9] ?? "",
          monthlyData?.[7]?.[9] ?? "",
          monthlyData?.[8]?.[9] ?? "",
          monthlyData?.[9]?.[9] ?? "",
          monthlyData?.[10]?.[9] ?? "",
          monthlyData?.[11]?.[9] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[10] ?? "",
          monthlyData?.[1]?.[10] ?? "",
          monthlyData?.[2]?.[10] ?? "",
          monthlyData?.[3]?.[10] ?? "",
          monthlyData?.[4]?.[10] ?? "",
          monthlyData?.[5]?.[10] ?? "",
          monthlyData?.[6]?.[10] ?? "",
          monthlyData?.[7]?.[10] ?? "",
          monthlyData?.[8]?.[10] ?? "",
          monthlyData?.[9]?.[10] ?? "",
          monthlyData?.[10]?.[10] ?? "",
          monthlyData?.[11]?.[10] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[11] ?? "",
          monthlyData?.[1]?.[11] ?? "",
          monthlyData?.[2]?.[11] ?? "",
          monthlyData?.[3]?.[11] ?? "",
          monthlyData?.[4]?.[11] ?? "",
          monthlyData?.[5]?.[11] ?? "",
          monthlyData?.[6]?.[11] ?? "",
          monthlyData?.[7]?.[11] ?? "",
          monthlyData?.[8]?.[11] ?? "",
          monthlyData?.[9]?.[11] ?? "",
          monthlyData?.[10]?.[11] ?? "",
          monthlyData?.[11]?.[11] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[12] ?? "",
          monthlyData?.[1]?.[12] ?? "",
          monthlyData?.[2]?.[12] ?? "",
          monthlyData?.[3]?.[12] ?? "",
          monthlyData?.[4]?.[12] ?? "",
          monthlyData?.[5]?.[12] ?? "",
          monthlyData?.[6]?.[12] ?? "",
          monthlyData?.[7]?.[12] ?? "",
          monthlyData?.[8]?.[12] ?? "",
          monthlyData?.[9]?.[12] ?? "",
          monthlyData?.[10]?.[12] ?? "",
          monthlyData?.[11]?.[12] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[13] ?? "",
          monthlyData?.[1]?.[13] ?? "",
          monthlyData?.[2]?.[13] ?? "",
          monthlyData?.[3]?.[13] ?? "",
          monthlyData?.[4]?.[13] ?? "",
          monthlyData?.[5]?.[13] ?? "",
          monthlyData?.[6]?.[13] ?? "",
          monthlyData?.[7]?.[13] ?? "",
          monthlyData?.[8]?.[13] ?? "",
          monthlyData?.[9]?.[13] ?? "",
          monthlyData?.[10]?.[13] ?? "",
          monthlyData?.[11]?.[13] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[14] ?? "",
          monthlyData?.[1]?.[14] ?? "",
          monthlyData?.[2]?.[14] ?? "",
          monthlyData?.[3]?.[14] ?? "",
          monthlyData?.[4]?.[14] ?? "",
          monthlyData?.[5]?.[14] ?? "",
          monthlyData?.[6]?.[14] ?? "",
          monthlyData?.[7]?.[14] ?? "",
          monthlyData?.[8]?.[14] ?? "",
          monthlyData?.[9]?.[14] ?? "",
          monthlyData?.[10]?.[14] ?? "",
          monthlyData?.[11]?.[14] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[15] ?? "",
          monthlyData?.[1]?.[15] ?? "",
          monthlyData?.[2]?.[15] ?? "",
          monthlyData?.[3]?.[15] ?? "",
          monthlyData?.[4]?.[15] ?? "",
          monthlyData?.[5]?.[15] ?? "",
          monthlyData?.[6]?.[15] ?? "",
          monthlyData?.[7]?.[15] ?? "",
          monthlyData?.[8]?.[15] ?? "",
          monthlyData?.[9]?.[15] ?? "",
          monthlyData?.[10]?.[15] ?? "",
          monthlyData?.[11]?.[15] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[16] ?? "",
          monthlyData?.[1]?.[16] ?? "",
          monthlyData?.[2]?.[16] ?? "",
          monthlyData?.[3]?.[16] ?? "",
          monthlyData?.[4]?.[16] ?? "",
          monthlyData?.[5]?.[16] ?? "",
          monthlyData?.[6]?.[16] ?? "",
          monthlyData?.[7]?.[16] ?? "",
          monthlyData?.[8]?.[16] ?? "",
          monthlyData?.[9]?.[16] ?? "",
          monthlyData?.[10]?.[16] ?? "",
          monthlyData?.[11]?.[16] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[17] ?? "",
          monthlyData?.[1]?.[17] ?? "",
          monthlyData?.[2]?.[17] ?? "",
          monthlyData?.[3]?.[17] ?? "",
          monthlyData?.[4]?.[17] ?? "",
          monthlyData?.[5]?.[17] ?? "",
          monthlyData?.[6]?.[17] ?? "",
          monthlyData?.[7]?.[17] ?? "",
          monthlyData?.[8]?.[17] ?? "",
          monthlyData?.[9]?.[17] ?? "",
          monthlyData?.[10]?.[17] ?? "",
          monthlyData?.[11]?.[17] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[18] ?? "",
          monthlyData?.[1]?.[18] ?? "",
          monthlyData?.[2]?.[18] ?? "",
          monthlyData?.[3]?.[18] ?? "",
          monthlyData?.[4]?.[18] ?? "",
          monthlyData?.[5]?.[18] ?? "",
          monthlyData?.[6]?.[18] ?? "",
          monthlyData?.[7]?.[18] ?? "",
          monthlyData?.[8]?.[18] ?? "",
          monthlyData?.[9]?.[18] ?? "",
          monthlyData?.[10]?.[18] ?? "",
          monthlyData?.[11]?.[18] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[19] ?? "",
          monthlyData?.[1]?.[19] ?? "",
          monthlyData?.[2]?.[19] ?? "",
          monthlyData?.[3]?.[19] ?? "",
          monthlyData?.[4]?.[19] ?? "",
          monthlyData?.[5]?.[19] ?? "",
          monthlyData?.[6]?.[19] ?? "",
          monthlyData?.[7]?.[19] ?? "",
          monthlyData?.[8]?.[19] ?? "",
          monthlyData?.[9]?.[19] ?? "",
          monthlyData?.[10]?.[19] ?? "",
          monthlyData?.[11]?.[19] ?? ""
        ),
        createData2(
          monthlyData?.[0]?.[20] ?? "",
          monthlyData?.[1]?.[20] ?? "",
          monthlyData?.[2]?.[20] ?? "",
          monthlyData?.[3]?.[20] ?? "",
          monthlyData?.[4]?.[20] ?? "",
          monthlyData?.[5]?.[20] ?? "",
          monthlyData?.[6]?.[20] ?? "",
          monthlyData?.[7]?.[20] ?? "",
          monthlyData?.[8]?.[20] ?? "",
          monthlyData?.[9]?.[20] ?? "",
          monthlyData?.[10]?.[20] ?? "",
          monthlyData?.[11]?.[20] ?? ""
        ),
      ]
    : [];

  return (
    <Paper sx={{ width: "1250px" }}>
      <TableContainer sx={{ maxHeight: "2000px" }}>
        <Table
          sx={{ borderCollapse: "separate", borderSpacing: "0 10px" }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns2.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    width: "100px",
                    minWidth: column.minWidth,
                    borderBottom: "none",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows2.map((row, index) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.code}
                sx={{
                  height: "80px",
                  backgroundColor: index % 2 === 0 ? "#E9F2FA96" : "#F1F0F08F",
                  "&:not(:last-child)": { marginBottom: "10px" },
                }}
              >
                {columns2.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ width: "100px", minWidth: column.minWidth }}
                    >
                      {column.format && typeof value === "number"
                        ? column.format(value)
                        : TruncateString(value, 10)}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
