import React from "react";
import hero from "../images/hero.png";
import "./About.css";
import mission from "../images/mission.png";
import vision from "../images/vision_about.png";
import quantitative from '../images/quantitative.png'
import img from "../images/Objects.png";
import video_v from "../images/banner_v.mp4";
// import banner2 from '../images/Objects2.png';
import targetAudience from '../images/targetAudience.png'
import NewsletterBanner from "./NewsletterBanner";


const About = () => {
  return (
    <div className="about-page-container">

       <div className="Banner">
        <div className="bg">
          <div className="media-container">
            <video src={video_v} className="video" autoPlay loop muted />
            <img src={img} className="image" alt="Decorative" />
            <div className="bgTransparent"></div>
            <div className="about-page-banner">
              <div className="about-page-left-content"><h1 className="about-page-left-text" >About Us</h1></div>
              <div className="about-page-right-content">
                <p className="about-page-right-text" >Through our relentless pursuit of excellence and rigorous adherence to a scientific framework, we aspire to set new standards for accuracy, efficiency, and accountability in real estate investment. We seek to empower investors, lending institutions, and policymakers with the knowledge they need to make informed decisions and navigate the complexities of the market with confidence.</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>

      <div className="about-page-mission" >
        
        <div className="about-page-mission-content" >
          <div className="about-page-mission-left-content" >
          <p className="about-page-mission-left-content-headnig">Mission</p>
            <p className="about-page-mission-left-content-text">
            Our mission is to revolutionise the real estate industry by challenging traditional methodology and leveraging an objective scientific framework,  harnessing data and advanced analytics to deliver unparalleled insights, mitigate risk, and drive profitable growth for our clients.
            </p>
            <div className="about-page-mission-left-content-img">
              <img src={mission} alt="" />
            </div>
          </div>

          <div className="about-page-mission-right-content" >
            <div className="about-page-mission-right-content-img">
              <img src={vision} alt="" />
            </div>
          <p className="about-page-mission-right-content-headnig">Vision</p>
            <p className="about-page-mission-right-content-text">
            To empower the future of real estate and investment through technological innovation and a scientific framework, driving economic growth, and setting new standards for accuracy and efficiency.
            </p>
          </div>
        </div>
      </div>

      <div className="about-page-quantitative" >
         <h1 className="about-page-quantitative-heading" >
         A QUANTITATIVE APPROACH TO VALUE
         </h1>
         
         <p className="about-page-quantitative-text1" >
         To provide a cutting-edge real estate technology that provides data-driven solutions for property investors, developers, and real estate professionals.
         </p>

         <div className="about-page-quantitative-image" >
            <img src={quantitative} alt="" />
         </div>
        <p className="about-page-quantitative-text2" >
        Our <strong>software</strong> platform leverages advanced analytics, mathematical and statistical modeling,  artificial intelligence, and machine learning to deliver actionable insights, appraisal solutions, risk management solutions, streamline decision-making, and enhance the overall efficiency of real estate transactions and investments.
        </p>
 
      </div>

      <div className="about-page-target-audience" >
        <div className="about-page-target-audience-left" >
          <p className="about-page-target-text" >Target Audience</p>
          <p className="about-page-target-subtext" >Our platform serves a diverse range of clients, including <strong>
             Financial Institutions, Real Estate Investors, Speculative Developers and Property Managers </strong> </p>
        </div>
        <div className="about-page-target-audience-right">
          <img src={targetAudience} alt="" />
        </div>
      </div>
    
      <NewsletterBanner />

    </div>
  );
};

export default About;
