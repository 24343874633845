import React, { useEffect, useState } from "react";
import "./MaterialPopup.css";
import { FaAngleDown } from "react-icons/fa";
import RawMaterail2 from "./RawMaterail2";
import { IoClose } from "react-icons/io5";

const MaterialPopup = ({ tableData, setIsMaterailCardHidden, year, setYear, setMonthlyData, monthlyData }) => {
  // const [monthlyData, setMonthlyData] = useState("");
  const [showYear, setShowYear] = useState("");

  // useEffect(() => {
  //   getDataByYear();
  //   getShowYear();
  // }, []);

  console.log("monthly from popup", monthlyData);
  console.log("year in popup", year);
  // async function getDataByYear() {
  //   try {
  //     const response = await fetch(
  //       "http://20.197.20.165:8000/get_news_by_year_month/?year=2024"
  //     );
  //     const result = await response.json();
  //     setMonthlyData(convertTo2DArray(result.data));
  //   } catch (error) {
  //     console.log("error in fetching current year data", error);
  //   }
  // }

  // async function getShowYear() {
  //   try {
  //     const response = await fetch(
  //       "http://20.197.20.165:8000/get_year_priceindex/"
  //     );
  //     const result = await response.json();
  //     setShowYear(result["unique_years"]);
  //   } catch (error) {
  //     console.log("error in show year data", error);
  //   }
  // }

  // async function getYearFromDropDown(year) {
  //   try {
  //     const response = await fetch(
  //       `http://20.197.20.165:8000/get_news_by_year_month/?year=${year}`
  //     );
  //     const result = await response.json();
  //     setMonthlyData(convertTo2DArray(result.data));
  //   } catch (error) {
  //     console.log("Error in fetching year dropdown", error);
  //   }
  // }

  function convertTo2DArray(data) {
    return data?.map((item) => Object.values(item));
  }


  return (
    <div className="materialPopup-container">
      <div className="materialPopup-header">
        <div
          className="materialPopup-container-close"
          onClick={() => setIsMaterailCardHidden(true)}
        >
          <IoClose size={40} />
        </div>
        <p>Construction Raw Material Data <span>Base Year: 2015 = 100:00</span> </p>
        {/* <div class="dropdown">
          <button
            class="btn btn-dark dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            Year
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            {showYear &&
              showYear.slice(0, 5).map((item, index) => (
                <li key={index} style={{ cursor: "pointer" }}>
                  <a
                    class="dropdown-item"
                    onClick={() => getYearFromDropDown(item)}
                  >
                    {item}
                  </a>
                </li>
              ))}
          </ul>
        </div> */}
      </div>
      <div className="materialPopup-body">
        <div className="materialPopup-left-body">
          {tableData.map((item, index) => (
            <p
              key={index}
              style={{
                textAlign: 'center',
                backgroundColor: index % 2 !== 0 ? "#E9F2FA96" : "#F1F0F08F",
              }}
            >
              {item}
            </p>
          ))}
        </div>
        <div className="materialPopup-right-body">
          {monthlyData && <RawMaterail2 monthlyData={monthlyData} />}
        </div>
      </div>
    </div>
  );
};

export default MaterialPopup;
