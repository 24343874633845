
import React from "react";
import "./mission.scss";
import tech1 from '../images/tech_1.png';
import mission from '../images/goals.gif';
import vission from '../images/vision.gif';
import tech from '../images/tech_2.png';
import right_circle from '../images/rightcircle.gif';
import new_shape from '../images/right_circle2.gif';

const Mission = () => {
  return (
    <>
      <section id="Mission">
        <div className="container-fluid">
          <div className="row d-flex align-items-center ">
            <div className="col-sm-8">
              <div className="row mb-5">
                <div className="col-sm-3 d-flex justify-content-center">
                  <img
                  src={mission}
                    // src="https://s3-alpha-sig.figma.com/img/0bb9/3d43/f2576b50675cf5d70c8a569941b1ff53?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HgfbIEWottlGdNjwe6cSgT5MwP6Wq6FxRPwQKRX-4J9PQM~nUUtCjfNKtlYrEKJ-T3BBTItDabt785L17F-NIWvgeibosjKrCJQ11z5ufqL4rHsLsqfBuiEjPHKmm4UJfCBmPJBQCYEis8IBLk9GPD0t7hKaK~2FTrUun7wuT0ZaFUSwQFdK208wDeqVrHGrXN87mUoNOfm80ggCYzQGSkVxTjJICEpLsY~nYIrswcXURzahrs1p2egc-B76fwt00iUDyaIULcHBrtr8U~lgN4JTWr9NdQNlInlxGelqrVsLyYmFaP-ml5Pk8U1bvWUvL5FVQ1kW~l9gVgXG8L1vKA__"
                    alt="Mission Icon"
                    className="icon moving_left"
                  />
                </div>
                <div className="col-sm-9">
                  <h2>Mission</h2>
                  <p>
                    Our mission is to revolutionize the real estate industry by
                    harnessing data and advanced analytics to deliver unparalleled
                    insights, mitigate risk, and drive profitable growth for our
                    clients.
                  </p>
                </div>
              </div>
              <div className="row" >
                <div className="col-sm-3 d-flex justify-content-center">
                  <img
                  src={vission}
                    // src="https://s3-alpha-sig.figma.com/img/a778/779e/675e835adbfe5bbe2c47eb554ac6f04b?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O1~t5eT5DpqL0Oqatln0O~tDBeh9FrYXbumtObdYouRy3K0qCuW~1eXYSyorj~IcFW5oNhyaB-0Cn5d8jsxLFoZDjcznzMSKTvUNF-cwgrgvfi5gMTY3qxqRe2EPJB-FTNEV1FUGXf8kRZIQWBWbHIfXKo4ktH0hddpU8Z~dCHolCCqmIivpYf-PnZgZvubiUHU7Ncc02pA7CMIM~fZlHCdwke87bzLK7J6D3cMzh6Qe2GRiRnMuAs5iHx14whzdcV3Pty5CglN1pLzpkB2jYkIUAqYrsn3d7o7I6-RYY55NVswSrf3PiaPD6Dw3G--3OTH-mTXGMKu9pgMHndAn6Q__"
                    alt="Mission Icon"
                    className="icon moving_left"
                  />
                </div>
                <div className="col-sm-9">
                  <h2>Vision</h2>
                  <p>
                  To empower the future of real estate and investment through technological innovation and scientific analysis, driving economic growth, and setting new standards for accuracy and efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-end d-flex align-items-center d-flex justify-content-end">
    
              
            
                <div className="circle-container d-flex align-items-center d-flex justify-content-end">
                  <img className="circle_img"  
                  src={tech1}
                  // src={right_circle}
                  />
                  {/* <img className="circle_img"  src='https://s3-alpha-sig.figma.com/img/8e8a/5aa8/e3c4d7fc4018528f1a9c34cbe071ce88?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T4BOqauJa8fvTtszERy4cDiz5HkB0FfHMbgqnToOu6xcMxVRIWcpEgPFYF4Y~bsi~399QVksYYNG7B6JDvq3w891SaIgOLlAq-2vv~CIOVtDk8qwrGkKyb3Bm0R1PZa0~pIf2zvugNFwx0ehXc1VxhKxVfFU8wWmpBId4Q7I4KYATIwZf2A4jGyyMmOyiJ8quQBWgCG~5CMfgFdP7Q5UyyS~wccbUbDpDQ~zGtsSn9aOb1BM3~vIiMtnhLHSRNuqhKkv4u~cOWTI4~n0uzg0TiVsE3woCUig-VLIXhgVIRtZ-MNWKUcrEIlZ9-UgKV9xElHc8UiI~MO14IkEhcNcBg__'/> */}

                </div>
              </div>
              
        
            {/* <div className="col-sm-4 d-flex justify-content-end d-flex align-items-center d-flex justify-content-end">
    
              
    <div className="geometric-design-container d-flex align-items-center d-flex justify-content-end">
      <div className="circle-container d-flex align-items-center d-flex justify-content-end">
        <img className="circle_img"  
        src={tech1}
        // src={right_circle}
        />
        <img className="circle_img"  src='https://s3-alpha-sig.figma.com/img/8e8a/5aa8/e3c4d7fc4018528f1a9c34cbe071ce88?Expires=1724025600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=T4BOqauJa8fvTtszERy4cDiz5HkB0FfHMbgqnToOu6xcMxVRIWcpEgPFYF4Y~bsi~399QVksYYNG7B6JDvq3w891SaIgOLlAq-2vv~CIOVtDk8qwrGkKyb3Bm0R1PZa0~pIf2zvugNFwx0ehXc1VxhKxVfFU8wWmpBId4Q7I4KYATIwZf2A4jGyyMmOyiJ8quQBWgCG~5CMfgFdP7Q5UyyS~wccbUbDpDQ~zGtsSn9aOb1BM3~vIiMtnhLHSRNuqhKkv4u~cOWTI4~n0uzg0TiVsE3woCUig-VLIXhgVIRtZ-MNWKUcrEIlZ9-UgKV9xElHc8UiI~MO14IkEhcNcBg__'/> 

      </div>
    </div>
   
  </div>
   */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Mission;



