import React, { useState } from 'react';
import './Login.css'; 
import { IoLogoApple } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { NavLink, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Import jwtDecode

const Login = ({ authenticateUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const isFormValid = email.trim() !== '' && password.trim() !== '';

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setErrorMessage('');
    setIsLoading(true);
  
    try {
      const response = await fetch('https://www.inferredtheorem.com:5000/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(data.user));

        // Decode the token to get the user role
        const decoded = jwtDecode(data.token);
        const userRole = decoded.role;
  
        // Pass both authentication status and role to authenticateUser
        authenticateUser(true, data.token);
        
        console.log('Logged in successfully', data);
        navigate('/blog');
      } else {
        setErrorMessage(data.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setErrorMessage('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2 className='login-heading-text'>Login</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="form-group">
          <label>Email address</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email address"
            required 
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Password" 
            required 
          />
          <p className="forgot-password">Forget your password?</p>
        </div>
        <button 
          type="submit" 
          className={`login-btn ${isFormValid ? 'enabled' : 'disabled'}`} 
          disabled={!isFormValid || isLoading}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>

        <div className="signup-link">
          Don’t have an account? <NavLink to="/signup">Sign up</NavLink>
        </div>

        <div className="separator">
          <span>OR</span>
        </div>

        <button className="social-login google-login">
          <FcGoogle size={20} />
          Continue with Google
        </button>
        <button className="social-login apple-login">
          <IoLogoApple size={20} />
          Continue with Apple
        </button>
      </form>
    </div>
  );
};

export default Login;
