import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import '../component/Add_new_post.css'; 

const AddNewPost = () => {
  const location = useLocation();
  const navigate = useNavigate(); 
  const editingPost = location.state?.post; 

  const [title, setTitle] = useState('');
  const [pictureUrl, setPictureUrl] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (editingPost) {
      setTitle(editingPost.title);
      setPictureUrl(editingPost.pictureUrl);
      setContent(editingPost.content);
    }
  }, [editingPost]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const postData = { title, pictureUrl, content };
    const token = localStorage.getItem('token'); // Get the JWT token

    try {
      const response = await fetch(
        editingPost 
          ? `https://www.inferredtheorem.com:5000/api/auth/blog_post/${editingPost._id}` 
          : 'https://www.inferredtheorem.com:5000/api/auth/blog_post', 
        {
          method: editingPost ? 'PUT' : 'POST',  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Include token in headers
          },
          body: JSON.stringify(postData),
        }
      );

      if (!response.ok) {
        const errorDetails = await response.json(); 
        throw new Error(errorDetails.message || 'Failed to submit post');
      }

      const result = await response.json();
      console.log('Post submitted successfully:', result);
      alert('Post submitted successfully!'); // Notify the user

      navigate('/my-blogs');

      // Reset form fields
      setTitle('');
      setPictureUrl('');
      setContent('');
    } catch (err) {
      console.error(err);
      setError('Failed to submit the post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setTitle('');
    setPictureUrl('');
    setContent('');
  };

  return (
    <div className="add-new-post-container">
      <h2 className="form-title">{editingPost ? 'Edit Post' : 'Add New Post'}</h2>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Picture URL</label>
          <input
            type="text"
            value={pictureUrl}
            onChange={(e) => setPictureUrl(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Content</label>
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
        </div>
        
        <div className="form-buttons">
          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : editingPost ? 'Update' : 'Submit'}
          </button>
          <button type="button" onClick={handleClear}>
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewPost;
