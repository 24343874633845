import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './TickerSlider.css'

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-prev-arrow" onClick={onClick}>
        <svg
          className="arrow-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
      </div>
    );
  };
  
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-next-arrow" onClick={onClick}>
        <svg
          className="arrow-icon"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          ></path>
        </svg>
      </div>
    );
  };

const TickerSlider = () => {
    const [ticker, setTicker] = useState([]);

    useEffect(() => {
      getTicker();
  
      const intervalId = setInterval(() => {
        getTicker();
      }, 600000);
  
      return () => {
        clearInterval(intervalId);
        console.log("Interval cleared on component unmount");
      };
    }, []);
  
    async function getTicker() {
      try{
        const response = await fetch("https://backend.inferredtheorem.com/live_ticker/");
        const result = await response.json();
        setTicker(result);
      }catch(error){
        console.log("error in fetching ticker slider", error);
      }
    }
  
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 7, // Maximum of 5 cards
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };
  
    const cards = [
      { title: "Card 1", description: "Description 1" },
      { title: "Card 2", description: "Description 2" },
      { title: "Card 3", description: "Description 3" },
      { title: "Card 4", description: "Description 4" },
      { title: "Card 5", description: "Description 5" },
      { title: "Card 6", description: "Description 6" },
      { title: "Card 2", description: "Description 2" },
      { title: "Card 3", description: "Description 3" },
      { title: "Card 4", description: "Description 4" },
      { title: "Card 5", description: "Description 5" },
      { title: "Card 6", description: "Description 6" },
      { title: "Card 2", description: "Description 2" },
      { title: "Card 3", description: "Description 3" },
      { title: "Card 4", description: "Description 4" },
      { title: "Card 5", description: "Description 5" },
      { title: "Card 6", description: "Description 6" },
    ];
  
    const TruncateString = (str, length) => {
      if (!str) return str;
      str = Math.abs(str) + "";
      if (str?.length > length) {
        let temp = str.substring(0, length);
        return temp;
      }
      return str;
    };

    // console.log("tickers", ticker);
  
    return (
      <div className="Ticker-containers">
        { ticker?.length > 0 ?
        <Slider {...settings} className="Ticker">
          {ticker?.map((card, index) => (
            <div className="Ticker-card-wrapper" key={index} >
              <div className="Ticker-cards">
                <p className="Ticker-cards-heading" >{card?.symbol}</p>
                <div className="Ticker-cards-prices" >
                <p>{card?.price}</p>
                <p
                 style={{
                    color: `${card?.percent_change > 0 ? "#00AE31" : "#E70404"}`,
                  }}
                >{card?.percent_change}%</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        :  <p>Loading...</p>
    }
      </div>
  )
}

export default TickerSlider