import React from "react";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
 
const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${hours}:${formattedMinutes} ${ampm}`;
};

const chartColor = {
  'Top Gainers': '#00AE31',
  "Top Losers": "#d60a22"
}



const CryptoSmallChart = ({ baseData, gainerLoserText, TopNews}) => {
  
  const Colors = TopNews?.current_price < TopNews?.previous_close ? "#d60a22" : "" ;

     if(!baseData)  return;

    //  console.log("small baseData", baseData);
    // console.log('topNews', TopNews);

  return (
    <div style={{ width: "120px", height: "100px", marginTop:'20px' }}>
      {Array.isArray(baseData) && baseData.length > 0 ? (
  <Line
    data={{
      labels: baseData.map((coin) => {
        let date = coin.time;
        let temp = formatTime(date);
        return temp;
      }),
      datasets: [
        {
          data: baseData.map((coin) => coin.price.Close),
          label: `Price (Past 24 Hours) in INR`,
          borderColor: (chartColor[gainerLoserText] || Colors || "#00AE31"),
          fill: false,
        },
      ],
    }}
    options={{
      elements: {
        point: {
          radius: 1,
        },
      },
      scales: {
        x: {
          grid: {
            display: false, 
          },
          ticks: {
            display: false, 
          },
          title: {
            display: false, 
          },
        },
        y: {
          grid: {
            display: false, 
          },
          ticks: {
            display: false, 
          },
          title: {
            display: false, 
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const time = tooltipItem.label;
              const price = tooltipItem.raw;

              return [
                `Time: ${time}`, // First row
                `Price: ₹${price.toFixed(2)}`, // Second row
              ];
            },
          },
        },
        legend: {
          display: false, // Hides the dataset label
        },
      },
    }}
  />
) : (
  <div>No data available to display the chart.</div>
)}

     {/* { baseData && <Line
        data={{
          labels: baseData?.map((coin) => {
            let date = coin.time;
            let temp = formatTime(date);
            return temp;
          }),
          datasets: [
            {
              data: baseData?.map((coin) => coin.price),
              label: `Price (Past 24 Hours) in INR`,
              borderColor: "#00AE31",
              fill: false,
            },
          ],
        }}
        options={{
          elements: {
            point: {
              radius: 1,
            },
          },
          scales: {
            x: {
              grid: {
                display: false, // Removes vertical grid lines
              },
              ticks: {
                display: false, // Removes x-axis labels
              },
              title: {
                display: false, // Hides the x-axis title
              },
            },
            y: {
              grid: {
                display: false, // Removes horizontal grid lines
              },
              ticks: {
                display: false, // Removes y-axis labels
              },
              title: {
                display: false, // Hides the y-axis title
              },
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  const time = tooltipItem.label;
                  const price = tooltipItem.raw;

                  return [
                    `Time: ${time}`, // First row
                    `Price: ₹${price.toFixed(2)}`, // Second row
                  ];
                },
              },
            },
            legend: {
              display: false, // Hides the dataset label
            },
          },
        }}
      />} */}
    </div>
  );
};

export default CryptoSmallChart;
