import React, { useState, useRef } from 'react';
import './NewsletterBanner2.css'; // Assuming you're using SCSS for styling

const NewsletterBanner2 = () => {
  const [showPopup, setShowPopup] = useState(false);
  const emailRef = useRef(null); // Create a ref for the input field

  const handleSubscribe = (event) => {
    event.preventDefault();
    setShowPopup(true);

    // Clear the input field
    if (emailRef.current) {
      emailRef.current.value = '';
    }

    // Hide the popup after a few seconds (optional)
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  return (
    <div className="news-letter-banner2-container">
  

      <div className="banner-content">
   <p className='news-letter-banner2-text' >Subscribe and Interact with our Blog to receive updates about our future!</p>
        <h2>Get updates in your mailbox</h2>
        <form className="newsletter-form" onSubmit={handleSubscribe}>
          <input
            type="email"
            placeholder="Your email address"
            ref={emailRef} // Attach the ref to the input
            required
          />
          <button type="submit">Subscribe</button>
        </form>
        {showPopup && (
          <div className="thank-you-popup">
            Thank you for subscribing! You’ll now receive updates in your email.
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterBanner2;
