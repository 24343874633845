import React, { useState } from "react";
import "./offerings.scss";
import { MdArrowOutward } from "react-icons/md";
import video_v from "../images/Earth.mp4";
import RealState2 from "./RealState2";

const Offerings = () => {
  const [isRotated, setIsRotated] = useState(false);
  const [rotatedStates, setRotatedStates] = useState(new Array(6).fill(false));

  // State to handle the selected card's content
  const [selectedCard, setSelectedCard] = useState(null);

  // Data for each card
  const cardData = [
    { id: 1, title: "Advanced Economic Metrics & Charts", content: "Assess performance, productivity, and efficiency with our meticulously crafted economic metrics." },
    { id: 2, title: "Advanced Appraisal Techniques", content: "Harness the power of machine learning algorithms, including Random Forests, Gradient Boosting Machines, and Artificial Neural Networks, for superior valuation accuracy." },
    { id: 3, title: "Performance Indices", content: "Access comprehensive, standardized indices that provide consistent benchmarks across the industry." },
    { id: 4, title: "Predictive Models & Analytics", content: "Benefit from advanced predictive models that offer reliable forecasts and strategic insights, helping you stay ahead in a dynamic market." },
    { id: 5, title: "Data Modeling, Optimisation and Risk Profiles", content: "Utilise large language and optimisation models for sophisticated data modeling, research and extraction, ensuring robust and actionable insights"},
  ];

  // Toggle rotation and set selected card
  const toggleRotation = (index) => {
    setRotatedStates((prevStates) =>
      prevStates.map((state, i) => (i === index ? !state : state))
    );

    // Update the selected card based on the index
    if (selectedCard?.id === index + 1) {
      setSelectedCard(null);  // Hide the full-width card
    } else {
      setSelectedCard(cardData.find(card => card.id === index + 1));  // Show the full-width card
    }
  };


  return (

    <div className="container-fluid offering-page-container ">
      <div className="row">
        <div className="col-sm-12">
          <section className="offerings-container">
            <video className="background-video" autoPlay loop muted>
              <source src={video_v} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="content">
              <div id="Offerings_inner">
                <h1>Our Future Offerings</h1>
                <p>
                We aim to serve a diverse range of clientele, including Financial Institutions, Real Estate Investors, Speculative Developers and Property Managers. Our platform addresses the critical challenges facing the industry by providing:
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
      <RealState2/>
      <div className="content2">
      <div className="row mt-5">
        <div className="col-sm-1"></div>

        {cardData.map((card, index) => (
          <div className="col-sm-2" key={card.id}>
            <div className="card custum_card_offering">
              <div className="card-content">
                <h2>{card.title}</h2>
                <div
                  className={`icon-container ${rotatedStates[index] ? "rotated" : ""}`}
                  onClick={() => toggleRotation(index)}
                >
                  <span className="arrow-icon">
                    <MdArrowOutward className="icon" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="col-sm-1"></div>
      </div>

      {selectedCard && (
        <div className="full-width-card">
          <div className="card-content">
            <h2>{selectedCard.content}</h2>
            
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default Offerings;
