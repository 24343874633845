import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NewsCard.scss";
import img1 from "../images/cardImg1.png";
import img2 from "../images/cardImg2.png";
import img3 from "../images/cardImg3.png";
import { MdArrowOutward } from "react-icons/md";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";

const NewsCard = () => {
  const cardData = [
    {
      date: "05. May 2024",
      title: "UK house prices remain subdued for third successive month",
      source: "The Guardian",
      imgSrc: img1, // Use the correct path to your image
    },
    {
      date: "05. May 2024",
      title: "UK house prices remain subdued for third successive month",
      source: "The Guardian",
      imgSrc: img2, // Use the correct path to your image
    },
    {
      date: "05. May 2024",
      title: "UK house prices remain subdued for third successive month",
      source: "The Guardian",
      imgSrc: img3, // Use the correct path to your image
    },
  ];
  const [cardData1, setCardData1] = useState([]);

  useEffect(() => {
    getNewsCard();
  }, []);

  async function getNewsCard() {
    const resp = await fetch("https://backend.inferredtheorem.com/get_top4_news/");
    const result = await resp.json();
    // console.log("card data", result)
    setCardData1(result);
  }

  console.log("card1", cardData1);

  const TruncateString = (str, length) => {
    if (str?.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  return (
    <section className="home-page-NewsCard">
      <div className="news-container">
        <h2 className="header_news">Latest From News</h2>
        {cardData1?.length > 0 && (
          <Swiper
            slidesPerView={3} // Show 3 cards at a time
            spaceBetween={20} // Gap of 2rem (32px)
            pagination={{
              clickable: true,
            }}
            navigation
            modules={[Pagination, Navigation]}
            // modules={[Pagination]}
            className="mySwiper"
          >
            {cardData1.map((card, index) => (
              <SwiperSlide key={index}>
                <div className="card-custom">
                  <div className="news-card-date">
                    <p>{card.date.split(' ')[0]}.</p>
                    <p>{card.date.split(' ')[1]} {card.date.split(' ')[2]}</p>
                  </div>
                  <div
                    className="img-div"
                    onClick={() => window.open(card.url, "_blank")}
                  >
                    <img
                      src={card.image_url}
                      className="card-img"
                      alt="Card cap"
                    />
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      {TruncateString(card.title, 55)}
                    </p>
                    <div className="card-footer-arrow">
                      <p className="card-publisher">{card.publisher} </p>
                      <div className="card-arrow">
                        <MdArrowOutward size={30} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default NewsCard;
