import React, { useState } from "react";
import "./banner2.scss";
import Layout from "../component/Layout";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StockCard from "./StockCard";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import News from "./News";
import { Swiper, SwiperSlide } from "swiper/react";
import bg from "../images/bgimg.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import Header from "../component/Header";
import Footer from "../component/Footer";
import "./newsroom.scss";
import PlanningApplications from "./PlanningApplications";
import SliderComp from "./SliderComp";
import { useDispatch } from "react-redux";
import { filterNews } from "../redux/searchSlice";
import NewsletterBanner from "./NewsletterBanner";

import GovernmentLegislations from "./GovernmentLegislations";
import ConstructionRawMaterial from "./ConstructionRawMaterial";
import White_Paper2 from "./WhitePaper2";
import Internal_Publication from "./InternalPublication2";
import IPO from "./IPO";
import Live from "./Live";
import PublicTenders from "./PublicTenders";
import TickerSlider from "../component/TickerSlider";

const Newsroom = () => {
  const [header, setHeader] = useState("News");
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setHeader("News");
    } else if (newValue == 2) {
      setHeader("Public Tenders");
    } else if (newValue == 3) {
      setHeader("Planning Applications");
    } else if (newValue == 4) {
      setHeader("Live & Upcoming Projects");
    } else if (newValue == 5) {
      setHeader("Construction Raw Material Market Information");
    } else if (newValue == 6) {
      setHeader("White Papers");
    } else if (newValue == 7) {
      setHeader("Market Data");
    }  else if (newValue == 8) {
        setHeader("Government Legislations");
      } 
    // else if (newValue == 8) {
    //   setHeader("Internal Publications");
    // } 
  };

  const stockData = [
    { symbol: "zzz", value: "4,092.69", change: 2.91 },
    { symbol: "CNA", value: "6,851.12", change: 2.86 },
    { symbol: "BP", value: "74,998.87", change: -2.21 },
    { symbol: "BP", value: "74,998.87", change: -2.21 },
    { symbol: "BP", value: "74,998.87", change: -2.21 },
    // { symbol: "SSE", value: "20,043.25", change: 1.64 },
    // { symbol: "AAF", value: "4,092.69", change: 2.91 },
    // { symbol: "AAF", value: "4,092.69", change: 2.91 },
    // { symbol: "AAF", value: "4,092.69", change: 2.91 },
  ];

  const dispatch = useDispatch();

  console.log("newValue", value);
  const [searchValue, setsearchValue] = useState("");

  const handleSearch = (e) => {
    dispatch(filterNews(searchValue));
    setsearchValue("");
  };

  return (
    <>
      {/* <Header /> */}
      <div className="" style={{ position: "relative" }}>
        <section id="Newsroom">
          {/* <img src={bg} alt="Image 1" class="newsroom-image" /> */}

          <div className="inner_Newsroom_top">
            <div className="container-fluid">
              <div className="row banner-content">
                <div className="col-sm-6">
                  <h1>{header}</h1>
                </div>
                <div className="col-sm-6">
                  <div className="search-bar-container">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search query"
                        onChange={(e) => setsearchValue(e.target.value)}
                        value={searchValue}
                      />
                      <button
                        className="btn btn-dark"
                        style={{ backgroundColor: "rgba(18, 50, 67, 1)" }}
                        type="button"
                        onClick={handleSearch}
                      >
                        SEARCH
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-sm-12">
                  {/* <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        aria-label="visible arrows tabs example"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab label="News" />
        <Tab label="Item Two" />
        <Tab label="Item Three" />
        <Tab label="Item Four" />
        <Tab label="Item Five" />
        <Tab label="Item Six" />
        <Tab label="Item Seven" />
      </Tabs> */}
                  {/* ================================================= */}
                  <TabContext value={value}>
                    <Box
                      sx={{
                        margin: "0 auto",
                        backgroundColor: "#143445",
                      }}
                    >
                      <TabList
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            "&.Mui-disabled": { opacity: 0.3 },
                          },
                        }}
                        aria-label="lab API tabs example"
                      >
                        <Tab
                          label="News"
                          value="1"
                          sx={{
                            color: "white",
                          }}
                        />

                        <Tab
                          label="Public Tenders"
                          value="2"
                          sx={{
                            color: "white",
                          }}
                        />
                        <Tab
                          label="Planning Applications"
                          value="3"
                          sx={{
                            color: "white",
                          }}
                        />
                        <Tab
                          label="Live & Upcoming Projects"
                          value="4"
                          sx={{
                            color: "white",
                          }}
                        />

                        <Tab
                          label="Construction Raw Material Market Information"
                          value="5"
                          sx={{
                            color: "white",
                          }}
                        />
                        <Tab
                          label="White Papers"
                          value="6"
                          sx={{
                            color: "white",
                          }}
                        />

                        <Tab
                          label="Market Data"
                          value="7"
                          sx={{
                            color: "white",
                          }}
                        />
                        {/* <Tab
                          label="Internal Publications"
                          value="8"
                          sx={{
                            color: "white",
                          }}
                        /> */}
                        <Tab
                          label="Government Legislations"
                          value="8"
                          sx={{
                            color: "white",
                          }}
                        />
                      </TabList>
                    </Box>

                    {/* ========================================================= */}
                  </TabContext>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Box
                    sx={{
                      width: "1440px",
                      margin: "0 auto",
                    }}
                  >
                    {/* <SliderComp /> */}
                    <TickerSlider />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="dynamicContent">
          <img src={bg} alt="Image 1" class="newsroom-image" />
          <div className="conainer-fluid">
            <div className="dynamicContent"> 
              <div className="row">
                <div className="col-sm-12">
                  {value === "1" ? <News /> : null}
                  {/* {value === "1" ? <IPO /> : null} */}
                  
                  {value === "2" ? <PublicTenders /> : null}

                  {value === "3" ? <PlanningApplications /> : null}

                  {value === "4" ? <Live /> : null}

                  {value === "5" ? <ConstructionRawMaterial /> : null}
                  {value === "6" ? <White_Paper2 /> : null}
                  {value === "7" ? <IPO /> : null}
                  {/* {value === "8" ? <Internal_Publication /> : null} */}

                  {value === "8" ? <GovernmentLegislations /> : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="main-footer">
          <div className="footer2">
            <NewsletterBanner />
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Newsroom;
