import React from 'react'
import './footer.scss'
import logo from '../images/inferred_theorem-logo.png'
import { NavLink } from 'react-router-dom'
const Footer = () => {
  return (
    <>
      
      <footer className="footer  text-light text-center ">
      <div className="container">
        <ul className="nav justify-content-center mb-3">
          <li className="nav-item">
            <NavLink to={'/'} className="nav-link" style={{color:'#D0DAF5'}}>Home</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'./newsroom'} className="nav-link" style={{color:'#D0DAF5'}}>Newsroom</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={'/blog'} className="nav-link" style={{color:'#D0DAF5'}}>Blog</NavLink>
          </li>
          {/* <li className="nav-item">
            <a href="#" className="nav-link" style={{color:'#D0DAF5'}}>Live Tickers & Indices</a>
          </li>
          <li className="nav-item me-0">
            <a href="#" className="nav-link" style={{color:'#D0DAF5'}}>Simulations</a>
          </li>
          <li className="nav-item me-0">
            <a href="#" className="nav-link" style={{color:'#D0DAF5'}}>Appraisals</a>
          </li>
          <li className="nav-item me-0">
            <a href="#" className="nav-link" style={{color:'#D0DAF5'}}>API</a>
          </li> */}
        </ul>
        <div className="social-icons">
             <img src={logo} alt="logo" />
          {/* <a href="#" className=""><i className="fab fa-facebook-f"></i></a>
          <a href="#" className=""><i className="fab fa-twitter"></i></a>
          <a href="#" className=""><i className="fab fa-youtube"></i></a> */}
        </div>
        <p className='footer-contact-info' >
          <a href="mailto:josef@inferredtheorem.com " style={{color: 'inherit'}} >
          Contact: josef@inferredtheorem.com 
          </a>
          </p>
        <p className='footer-copyright-text' >&copy; Copyright 2024 - INFERRED THEOREM</p>
      </div>
    </footer>


    </>
  )
}

export default Footer


/*  
<a
             href="mailto:nk1@bluparrot.in"
             >nk1@bluparrot.in</a>
*/