import {configureStore} from '@reduxjs/toolkit'
import searchreducer from './searchSlice'






const store = configureStore({
    reducer : {
        searchreducer
      
    }
})

export default store