import React, { useEffect, useState } from "react";
import "./SingleIPO.css";
import CryptoChart from "../component/CryptoChart";
import { FaCircleArrowLeft } from "react-icons/fa6";
import CardSlider from "../component/Slider";
import { IoIosArrowDown } from "react-icons/io";
import { PiChartLine } from "react-icons/pi";
import { LiaMountainSolid } from "react-icons/lia";
import { MdOutlineCandlestickChart } from "react-icons/md";

const SingleIPO = React.memo(
  ({
    gainerLoserText,
    setIsCardVisible,
    tickerId,
    topIPOChart,
    topGainLosers,
    top1GainLoser,
    top2GainLoser,
    top3GainLoser,
    top4GainLoser,
    top5GainLoser,
    top6GainLoser,
    top7GainLoser,
    top8GainLoser,
    top9GainLoser,
    top10GainLoser,
  }) => {
    // const [baseData, setBaseData] = useState([]);
    const [days, setDays] = useState("one");
    const [lineAreaData, setLineAreaData] = useState("");
    const [indicatorData, setIndicatorData] = useState("");
    const [candlestickData, setCandlestickData] = useState("");
    const [isChartListVisible, setIsChartListVisible] = useState(false);
    const [currentChartType, setCurrentChartType] = useState("line");
    const [isIndicatorChartVisibale, setIsIndicatorChartVisible] =
      useState(false);
    const [currentIndicatorChartType, setCurrentIndicatorChartType] =
      useState("24-hour Volume");

    useEffect(() => {
      solve();
    }, [days]);

    function handleDayChange(d) {
      setDays(d);
      setCurrentIndicatorChartType("24-hour Volume");
      setCurrentChartType('line');
    }

    function formatDate(dateString) {
      if (!dateString) return dateString;

      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      return `${day} ${month}`;
    }

    async function solve() {
      let URL;
      if (days === "one") {
        URL = `https://backend.inferredtheorem.com/chart_ipo/${topIPOChart.ticker}/`;
      } else {
        URL = `https://backend.inferredtheorem.com/chart_ipo_5days/${topIPOChart.ticker}/`;
      }

      try {
        const response = await fetch(URL);
        const result = await response.json();
        const temp =
          result.length > 0 &&
          result.map((item) => ({
            time: Math.floor(new Date(item.time).getTime() / 1000),
            value: item.price.Close || 0,
            open: item.price.Open || 0,
            low: item.price.Low || 0,
            high: item.price.High || 0,
            close: item.price.Close || 0,
            volume: item.price.Volume || 0,
            "24-hour Volume": item.price["24-hour Volume"] || 0,
            "Accumulation/Distribution":
              item.price["Accumulation/Distribution"] || 0,
            "Advance Decline Line": item.price["Advance Decline Line"] || 0,
            "Advance Decline Ratio": item.price["Advance Decline Ratio"] || 0,
            ALMA: item.price["ALMA"] || 0,
            "Aroon Up": item.price["Aroon Up"] || 0,
            "Aroon Down": item.price["Aroon Down"] || 0,
            "Average Day Range": item.price["Average Day Range"] || 0,
            "Fib 23.6%": item.price["Fib 23.6%"] || 0,
            "Fib 38.2%": item.price["Fib 38.2%"] || 0,
            "Fib 50%": item.price["Fib 50%"] || 0,
            "Fib 61.8%": item.price["Fib 61.8%"] || 0,
            "Fib 100%": item.price["Fib 100%"] || 0,
            "Ext 161.8%": item.price["Ext 161.8%"] || 0,
            "Ext 261.8%": item.price["Ext 261.8%"] || 0,
            "Ext 423.6%": item.price["Ext 423.6%"] || 0,
          }));

        const temp2 = result.map((item) => ({
          time: Math.floor(new Date(item.time).getTime() / 1000),
          value: item.price["24-hour Volume"],
        }));

        // setBaseData(temp);
        setLineAreaData(temp);
        setIndicatorData(temp2);
        setCandlestickData(temp);
      } catch (error) {
        console.log("error in fetching chart", error);
      }
    }

    const currencyMap = {
      SEK: "kr",
      EUR: "Є",
      USD: "$",
      BNPQY: "$",
      GBp: "£",
    };
    let ChartColor = "#00AE31";

    if (topIPOChart?.current_price < topIPOChart?.previous_close) {
      ChartColor = "#d60a22";
    }

    console.log("current chart type", currentChartType);
    console.log("current indicator", currentIndicatorChartType);
    console.log("indicator data", indicatorData);

    const handleToggleChart = (value) => {};

    // this number will convert string to number and add commas
    function formatCurrencyStringWithCommas(currencyString) {
      const number = parseFloat(currencyString);

      if (isNaN(number)) {
        return "N/A";
      }

      return number.toLocaleString();
    }

    return (
      <div className="SingleIPO_container">
        <div
          className="IPOPopup-container-back"
          onClick={() => setIsCardVisible(false)}
        >
          <FaCircleArrowLeft size={30} />
          <p>Back</p>
        </div>
        <div className="SingleIPO_header">
          <div className="SingleIPO_header-heading">
            <p>{topIPOChart?.long_name}</p>
            <p>
              {topIPOChart?.exchange}- Delayed Quote. {topIPOChart?.currency}
            </p>
          </div>
          <div className="SingleIPO_header-prices">
            <div className="SingleIPO_header-price">
              <p>{topIPOChart?.current_price}</p>

              {topIPOChart?.current_price >= topIPOChart?.previous_close ? (
                <p style={{ color: "#00AE31" }}>
                  +{topIPOChart?.absolute_change} ({topIPOChart?.percent_change}
                  %)
                </p>
              ) : (
                <p style={{ color: "#d60a22" }}>
                  -{topIPOChart?.absolute_change} ({topIPOChart?.percent_change}
                  %)
                </p>
              )}
            </div>
            <div className="SingleIPO_header-date">
              At Close: {formatDate(topIPOChart?.date)} at 15.00 UTC
            </div>
          </div>
        </div>
        <div className="SingleIPO_chart">
          <div className="SingleIPO_chart-buttons">
            <div className="SingleIPO_chart-day-buttons">
              <button
                className={`${days === "one" && "currentButton"}`}
                onClick={() => handleDayChange("one")}
              >
                1D
              </button>
              <button
                className={`${days === "five" && "currentButton"}`}
                onClick={() => handleDayChange("five")}
              >
                5D
              </button>
            </div>
            <div className="SingleIPO_chart-dropdown-buttons">
              <div
                className="SingleIPO_chart-dropdown1"
                onClick={() => setIsIndicatorChartVisible((prev) => !prev)}
              >
                <button>Indicators</button>
                <IoIosArrowDown color="black" />
              </div>

              <div
                className="SingleIPO_chart-dropdown"
                onClick={() => setIsChartListVisible((prev) => !prev)}
              >
                <button>Charts </button>
                <IoIosArrowDown color="black" />
              </div>
            </div>
          </div>
          <div className="SingleIPO_big_chart">
            <CryptoChart
              baseData={lineAreaData}
              currency={topIPOChart?.currency}
              ChartColor={ChartColor}
              days={days}
              lineAreaData={lineAreaData}
              indicatorData={indicatorData}
              setIndicatorData={setIndicatorData}
              candlestickData={candlestickData}
              currentChartType={currentChartType}
              setCurrentChartType={setCurrentChartType}
              isChartListVisible={isChartListVisible}
              setCurrentIndicatorChartType={setCurrentIndicatorChartType}
              currentIndicatorChartType={currentIndicatorChartType}
              isIndicatorChartVisibale={isIndicatorChartVisibale}
              setIsIndicatorChartVisible={setIsIndicatorChartVisible}
              setIsChartListVisible={setIsChartListVisible}
            />
          </div>
        </div>
        <div className="SingleIPO_info">
          <div className="SingleIPO_info-columns">
            <div className="SingleIPO_info-column">
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                {currencyMap[topIPOChart?.currency]}
                  {formatCurrencyStringWithCommas(topIPOChart?.current_price)}
                </p>
                <p>Current Price</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]}{" "}
                  {formatCurrencyStringWithCommas(topIPOChart?.previous_close)}
                </p>
                <p>Previous Close</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>{topIPOChart?.current_ratio}</p>
                <p>Current Ratio</p>
              </div>
            </div>
            <div className="SingleIPO_info-column">
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>{topIPOChart?.percent_change}</p>
                <p>Percent Change</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]}{" "}
                  {formatCurrencyStringWithCommas(topIPOChart?.market_cap)}
                </p>
                <p>Market Cap</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]} {formatCurrencyStringWithCommas(topIPOChart?.net_income)}
                </p>
                <p>Net Income</p>
              </div>
            </div>
            <div className="SingleIPO_info-column">
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p> {topIPOChart?.ev_revenue}</p>
                <p>Enterprise Value-to-Revenue Ratio</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]} {formatCurrencyStringWithCommas(topIPOChart?.revenue)}
                </p>
                <p>Total Revenue</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>{topIPOChart?.operating_margin}</p>
                <p>Operating Margin</p>
              </div>
            </div>
            <div className="SingleIPO_info-column">
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]}{" "}
                  {formatCurrencyStringWithCommas(topIPOChart?.week_52_low)}
                </p>
                <p>52-Week Low</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>{topIPOChart?.roe}</p>
                <p>Return on Equity</p>
              </div>
              <div className="SingleIPO_info-column-price SingleIPO_info-column-price-border">
                <p>{topIPOChart?.debt_ratio}</p>
                <p>Debt to Equity</p>
              </div>
            </div>
            <div className="SingleIPO_info-column">
              <div className="SingleIPO_info-column-price">
                <p>
                  {" "}
                  {currencyMap[topIPOChart?.currency]}{" "}
                  {formatCurrencyStringWithCommas(topIPOChart?.week_52_high)}
                </p>
                <p>52-Week High</p>
              </div>
              <div className="SingleIPO_info-column-price">
                <p>{topIPOChart?.beta}</p>
                <p>Beta</p>
              </div>
            </div>
          </div>
        </div>
        <div className="SingleIPO_slider">
          <p className="SingleIPO_slider-heading">Trending Stocks</p>
          <CardSlider
            gainerLoserText={gainerLoserText}
            baseData={lineAreaData}
            topGainLosers={topGainLosers}
            top1GainLoser={top1GainLoser}
            top2GainLoser={top2GainLoser}
            top3GainLoser={top3GainLoser}
            top4GainLoser={top4GainLoser}
            top5GainLoser={top5GainLoser}
            top6GainLoser={top6GainLoser}
            top7GainLoser={top7GainLoser}
            top8GainLoser={top8GainLoser}
            top9GainLoser={top9GainLoser}
            top10GainLoser={top10GainLoser}
          />
        </div>
      </div>
    );
  }
);

export default SingleIPO;
