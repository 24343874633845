import React, { useState, useEffect, useRef } from "react";
import "./header.scss";
import navbarLogo from "../images/navbarLogo.png";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FaUser, FaPen, FaFileAlt, FaSignOutAlt } from 'react-icons/fa';

const Header = ({ isAuthenticated, authenticateUser, isAdmin }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    authenticateUser(false); // Update authentication state immediately
    navigate("/login");
    setIsDropdownOpen(false); // Close the dropdown on logout
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState); // Toggle dropdown state
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // Close dropdown when authentication state changes
    if (!isAuthenticated) {
      setIsDropdownOpen(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Re-render when admin status changes
    setIsDropdownOpen(false); // Close dropdown when admin changes
  }, [isAdmin]);

  return (
    <div className="section">
      <header id="header">
        <div className="header-container">
          <Link to="/" className="logo d-flex align-items-center header-logo">
            <img src={navbarLogo} alt="Logo" />
            <span className="brand">INFERRED THEOREM</span>
          </Link>
          <nav id="navmenu" className="navmenu header-menuList">
            <ul>
              <li>
                <NavLink to="/about" activeClassName="active-link" className="nav-link">About</NavLink>
              </li>
              <li>
                <NavLink to="/newsroom" activeClassName="active-link" className="nav-link">Newsroom</NavLink>
              </li>
              <li>
                <NavLink to="/blog" activeClassName="active-link" className="nav-link">Blog</NavLink>
              </li>
            </ul>
            <i className="mobile-nav-toggle d-xl-none bi bi-list" />
          </nav>

          <div className="auth-buttons header-navButtons">
            {isAuthenticated ? (
              <div className="profile-section" ref={dropdownRef}>
                <FaUserCircle style={{ color: 'white' }} size={30} className="profile-icon" onClick={toggleDropdown} />
                {isDropdownOpen && (
                  <div className="profile-dropdown">
                    <ul>
                      <li>
                        <NavLink to="/profile">
                          <FaUser /> Profile
                        </NavLink>
                      </li>
                      {isAdmin && (
                        <>
                          <li>
                            <NavLink to="/post">
                              <FaPen /> Publish
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/my-blogs">
                              <FaFileAlt /> Posts
                            </NavLink>
                          </li>
                        </>
                      )}
                      <hr />
                      <li onClick={handleLogout}>
                        <NavLink to="#">
                          <FaSignOutAlt /> Sign out
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ) : (
              <>
                <button className="login-btn">
                  <NavLink to="/login" className="aaa">LOG IN</NavLink>
                </button>
                <button className="try-btn">
                  <NavLink to="/signup">Sign Up</NavLink>
                </button>
              </>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
