import { HiOutlineArrowsExpand } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import CRMCheckBox from "./CRMCheckBox";
import { IoClose } from "react-icons/io5";

let data = [
  {
    name: "Jan",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const materials = [
  {
    id: 1,
    label: "Gravel, Sand, Clays & Kaolin - excluding Aggregate Levy",
    color: "#1E90FF",
  },
  {
    id: 2,
    label: "Gravel, Sand, Clays & Kaolin - including Aggregate Levy",
    color: "#FF8C00",
  },
  {
    id: 3,
    label: "Bituminous Mixtures based on Natural & Artificial Stone",
    color: "#FFD700",
  },
  { id: 4, label: "Cement", color: "#32CD32" },
  { id: 5, label: "Ready-mixed concrete", color: "#00FA9A" },
  { id: 6, label: "Pre-cast concrete products", color: "#FF4500" },
  { id: 7, label: "Concrete reinforcing bars (steel)", color: "#FF69B4" },
  { id: 8, label: "Imported sawn or planed wood", color: "#8B4513" },
  { id: 9, label: "Imported Plywood", color: "#483D8B" },
  { id: 10, label: "Fabricated structural steel", color: "#8B0000" },
  { id: 11, label: "Central Heating Boilers", color: "#A52A2A" },
  { id: 12, label: "Taps and valves for sanitaryware", color: "#FFB6C1" },
  { id: 13, label: "Pipes & Fittings (rigid)", color: "#FFC0CB" },
  { id: 14, label: "Pipes & fittings (flexible)", color: "#FFDAB9" },
  { id: 15, label: "Sanitaryware", color: "#C0C0C0" },
  {
    id: 16,
    label: "Insulating materials (thermal or acoustic)",
    color: "#FFFF00",
  },
  { id: 17, label: "Paint (aqueous)", color: "#ADFF2F" },
  { id: 18, label: "Paint (non-aqueous)", color: "#7CFC00" },
  { id: 19, label: "Kitchen furniture", color: "#87CEEB" },
];

const CRMChart = ({ setIsMaterailCardHidden, year, setYear }) => {
  const [temp, setTemp] = useState(data);
  const [selectedItems, setSelectedItems] = useState([]);
  const [importedData, setImportedData] = useState();
  const [monthlyData, setMonthlyData] = useState("");
  const [showYear, setShowYear] = useState("");

  console.log("year in crm chart", year);

  useEffect(() => {
    getDataByYear();
    getShowYear();
  }, []);

  async function getDataByYear(year = 2024) {
    try {
      const response = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_year_month/?year=2024`
      );
      const result = await response.json();
      setMonthlyData(convertTo2DArray(result.data));
    } catch (error) {
      console.log("error in fetching current year data", error);
    }
  }

  console.log("monthlyData", monthlyData);
  // console.log("importedData", importedData);

  async function getShowYear() {
    try {
      const response = await fetch(
        "https://backend.inferredtheorem.com/get_year_priceindex/"
      );
      const result = await response.json();
      setShowYear(result["unique_years"]);
    } catch (error) {
      console.log("error in show year data", error);
    }
  }

  function convertTo2DArray(data) {
    return data?.map((item) => Object.values(item));
  }

  return (
    <>
      <div className="top-crm-lineChart-section">
        <div className="top-crm-lineChart-buttons">
          {/* <button>Monthly Data <IoClose /> </button> */}
          <div class="dropdown">
            <button
              class="btn btn-dark dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              Year ({year})
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              {showYear &&
                showYear.slice(0, 5).map((item, index) => (
                  <li key={index} style={{ cursor: "pointer" }}>
                    <p class="dropdown-item" onClick={() => setYear(item)}>
                      {item}
                    </p>
                  </li>
                ))}
            </ul>
          </div>
          <button onClick={() => setIsMaterailCardHidden((prev) => !prev)}>
            Data Table <HiOutlineArrowsExpand />
          </button>
        </div>

        <LineChart width={900} height={400} data={importedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}

          <Line type="monotone" strokeWidth={2} dataKey="Cement" stroke="#2CA02C" />
          <Line type="monotone" strokeWidth={2} dataKey="Kitchen_furniture" stroke="#74F2FF" />
          <Line type="monotone" strokeWidth={2} dataKey="Imported_plywood" stroke="#C5B0D5" />
          <Line type="monotone" strokeWidth={2} dataKey="Paint_non_aqueous" stroke="#17BECF" />
          <Line type="monotone" strokeWidth={2} dataKey="Paint_aqueous" stroke="#DBDB8D" />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Insulating_materials_thermal_or_acoustic"
            stroke="#BCBD22"
          />
          <Line type="monotone" strokeWidth={2} dataKey="Sanitaryware" stroke="#C7C7C7" />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Pipes_and_fittings_flexible"
            stroke="#7F7F7F"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Pipes_and_fittings_rigid"
            stroke="#F7B6D2"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Taps_and_valves_for_sanitaryware"
            stroke="#E377C2"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Gravel_Sand_Clays_Kaolin_incl_Aggregate_Levy"
            stroke="#124A74"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Central_heating_boilers"
            stroke="#C49C94"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Fabricated_structural_steel"
            stroke="#8C564B"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Imported_sawn_or_planed_wood"
            stroke="#9467BD"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Concrete_reinforcing_bars_steel"
            stroke="#FF9896"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Pre_cast_concrete_products"
            stroke="#D62728"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Ready_mixed_concrete"
            stroke="#98DF8A"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Bituminous_Mixtures_based_on_Natural_Artificial_Stone" 
            stroke="#FFBB78"
          />
          <Line
            type="monotone" strokeWidth={2}
            dataKey="Gravel_Sand_Clays_Kaolin_exc_Aggregate_Levy"
            stroke="#00ffbf"
          />
        </LineChart>
      </div>

      <CRMCheckBox
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setTemp={setTemp}
        temp={temp}
        setImportedData={setImportedData}
        importedData={importedData}
        year={year}
        setYear={setYear}
      />

      {/* <div>
      {materials.map((material) => (
        <div key={material.id} style={{ marginBottom: "10px" }}>
          <input
            type="checkbox"
            value={material.id}
            onChange={() => handleCheckboxChange(material.id)}
            checked={selectedMaterials.includes(material.id)}
          />
          <label style={{ marginLeft: "8px", color: material.color }}>
            {material.label}
          </label>
        </div>
      ))}
      <button onClick={handleSubmit}>Submit</button>
    </div> */}
    </>
  );
};

export default CRMChart;
