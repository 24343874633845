import React, { useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import "./MinMaxRange.css"; // Custom CSS for styling

const MinMaxRange = ({ awardedData, MinMaxValue, setMinMaxValue }) => {
  const [range, setRange] = useState({
    min: MinMaxValue.min_awarded_value,
    max: MinMaxValue.max_awarded_value,
  });

    console.log("nested MINMAX", MinMaxValue);

  const handleRangeChange = (min, max) => {
    setRange({ min, max });
    setMinMaxValue({ min, max });
  };


  return (
    <div className="MinMax-Range-container">
      <MultiRangeSlider
        min={awardedData.min_awarded_value}
        max={awardedData.max_awarded_value}
        step={1}
        onChange={(e) => handleRangeChange(e.minValue, e.maxValue)}
        minValue={range.min}
        maxValue={range.max}
        ruler={false} // Hides the ruler for cleaner design
        tooltip={false} // Hides tooltips for cleaner design
        barInnerColor="green"
        label={false}
        style={{
          border: "none",
          boxShadow: "none",
          padding: "10px 0",
        }}
      />

      <div className="slider-container-MinMax-text">
        <p>£{range.min}</p>
        <p>£{range.max}</p>
      </div>
    </div>
  );
};

export default React.memo(MinMaxRange);
