import React, { useEffect, useState } from "react";
import "./PlanningApplications.scss";
import { FaArrowRight } from "react-icons/fa";
import { DateRangePicker, Footer } from "rsuite";
import axios from "axios";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowDown, IoMdCloseCircleOutline } from "react-icons/io";
import { RiCloseLargeFill } from "react-icons/ri";



const PlanningApplications = () => {
  const [topNews, setTopNews] = useState("");
  const [latestNews, setlatestNews] = useState("");
  const [subscribeNews, setSubscribeNews] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDrpoDownVisible, setIsDropDownVisible] = useState(false);

  useEffect(() => {
    get_top4_News();
    getlatestNews();
    getSubscribeNews();
  }, []);

  const get_top4_News = () => {
    axios
      .get("https://backend.inferredtheorem.com/get_top4/")
      .then((response) => {
        // console.log(response);
        setTopNews(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getlatestNews = () => {
    axios
      .get(`https://backend.inferredtheorem.com/section_2/`)
      .then((response) => {
        setlatestNews(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubscribeNews = () => {
    axios
      .get(`https://backend.inferredtheorem.com/get_news_by_area/`)
      .then((response) => {
        // console.log(response);
        setSubscribeNews(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // catches 11th, 12th, 13th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

    return `${dayWithSuffix} ${month} ${year}`;
  };

  // console.log("suscriberNews", subscribeNews?.[0]);

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  useEffect(() => {
    if (endDate) {
      filterLatestNewsBYdate();
    } else {
      getlatestNews();
      setPopupVisible(false);
    }
  }, [endDate]);

  const formatDate3 = (dateStr) => {
    const date = new Date(dateStr);
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [dataNotFound, setDataNotFound] = useState(false);

  const filterLatestNewsBYdate = () => {
    const start_date = formatDate3(startDate);
    const end_date = formatDate3(endDate);

    axios
      .get(
        `https://backend.inferredtheorem.com/from_to_date/?from=${start_date}&to=${end_date}`
      )
      .then((response) => {
        setlatestNews(response.data.data);
        setPopupVisible(false);
        setlatestNewsPage(1);

        if (response.data.count == 0) {
          setDataNotFound(true);
          handleShowPopup(true);
          setlatestNewsPage(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filtertrendingNewsBYarea = (area) => {

    setIsDropDownVisible(false);
    // console.log("area", area);
    axios
      .get(`https://backend.inferredtheorem.com/get_news_by_area/?area=${area}`)
      .then((response) => {

        if (response.data.count == 0) {

        } else {
          setSubscribeNews(response.data.data);
          setIsDropDownVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
        getSubscribeNews();
      });
  };

  const reduxSearch = useSelector((state) => state.searchreducer.searchNews);

  useEffect(() => {
    handleSearch();
  }, [reduxSearch]);

  const handleSearch = () => {
    axios
      .get(`https://backend.inferredtheorem.com/search_pa/?search=${reduxSearch}`)
      .then((response) => {
        if (response.data.count == 0) {
          getlatestNews();
        } else {
          setlatestNews(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [latestNewspage, setlatestNewsPage] = React.useState(1);
  const latestNewsPerPage = 9; // Number of items to display per page

  const latestNewspageChange = (value) => {
    if (value < 0) return;
    setlatestNewsPage(value);
  };
  

  const latestNewsToDisplay =
    latestNews &&
    latestNews.slice(
      (latestNewspage - 1) * latestNewsPerPage,
      latestNewspage * latestNewsPerPage
    );

  const [subscribeNewspage, setlsubscribeNewsPage] = React.useState(1);
  const subscribeNewsPerPage = 8; // Number of items to display per page

  const subscribeNewspageChange = (event, value) => {
    setlatestNewsPage(value);
  };

  const subscribeNewsToDisplay =
    subscribeNews &&
    subscribeNews.slice(
      (subscribeNewspage - 1) * subscribeNewsPerPage,
      subscribeNewspage * subscribeNewsPerPage
    );

  const prevpageChange = () => {
    setlatestNewsPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1;
      } else {
        return prevPage;
      }
    });
  };

  const nextpageChange = () => {
    if(latestNewspage >= Math.ceil(latestNews?.length / latestNewsPerPage) ) return ;
    
    setlatestNewsPage((prevPage) => prevPage + 1);
  };

  const sendToAnotherpage = (url) => {
    window.open(url, "_blank");
  };

  function truncateParagraph(paragraph, maxLength) {
    if (paragraph?.length > maxLength) {
      return paragraph.substring(0, maxLength) + "...";
    } else {
      return paragraph;
    }
  }

  function removePercentageSymbolAndConvert(str) {
    if(!str) return ;
    return parseFloat(str.replace("%", ""));
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);

  const [sectionNews, setSectionNews] = useState("");

  useEffect(() => {
    handleInputChange();
  }, [inputPage]);

  function handleInputChange(value) {

    if (value >= 1 && value <= totalPage) {
      setCurrentPage(value);
      const data =
        latestNews &&
        latestNews.data.slice((value - 1) * 10 - 1, value * 10 - 1);
      setSectionNews(data);
    } else {
      setInputPage(value);
    }
  }

  // console.log("section news", subscribeNews?.data);

  const [areaDropdown, setareaDropdown] = useState("");
  useEffect(() => {
    axios
      .get(`https://backend.inferredtheorem.com/area_dropdown/`)
      .then((response) => {
        setareaDropdown(response.data.areas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getlatestNews();
  };

  const TruncateString = (str, length) => {
    if (str?.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  return (
    <>
      <section
        className="PlanningApplications"
        style={{         
          color: "black",        
          marginTop: "20px",
        }}
      >
        <div className="planning">
          <div className="container-fluid p-0">
            <div className="top4">
              <div className="row">
                {latestNews?.length >= 0 ||
                  topNews >= 0 ||
                  (subscribeNews >= 0 && (
                    <div class="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                  ))}

                <div className="col-lg-7">
                  <div
                    className="top_card mb-3"
                    onClick={() =>
                      sendToAnotherpage(topNews[0] && topNews[0].url)
                    }
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[0] && topNews[0].area_name}
                      </h5>
                      <p className="top_card_description">
                        {TruncateString(topNews[0]?.description, 70)}
                      </p>

                      <hr />
                      <div className="top_cardfooter">
                        <div className="top_cardfooter-left-container">
                          <span className="footer_left">
                            {topNews[0]?.status}
                          </span>
                          <span>
                            <strong>|</strong>
                          </span>
                          <span className="footer_left">
                            Processing Time&nbsp;: &nbsp;
                            <strong>
                              {topNews[0]?.processing_time_days}
                              Days
                            </strong>
                          </span>
                        </div>
                        <span className="footer_right">
                          {formatDate(topNews[0] && topNews[0].start_date)}
                          {/* 23 JULY 2024 */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="top_card mb-3"
                    style={{ backgroundColor: "rgba(226, 237, 246, 1)" }}
                    onClick={() => sendToAnotherpage(topNews[2]?.url)}
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[2]?.area_name}
                      </h5>
                      <p className="top_card_description">
                      {TruncateString(topNews[2]?.description, 70)}
                      </p>
                      <hr />
                      <div className="top_cardfooter">
                        <div className="top_cardfooter-left-container">
                          <span className="footer_left">
                            {topNews[2]?.status}
                          </span>
                          <span>
                            <strong>|</strong>
                          </span>
                          <span className="footer_left">
                            Processing Time&nbsp;: &nbsp;
                            <strong>
                              {topNews[2]?.processing_time_days}
                              Days
                            </strong>
                          </span>
                        </div>
                        <span className="footer_right">
                          {formatDate(topNews[2]?.start_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Side Articles */}
                <div className="col-lg-5 col-md-12">
                  <div
                    className="top_card mb-4"
                    style={{ backgroundColor: "rgba(226, 237, 246, 1)" }}
                    onClick={() => sendToAnotherpage(topNews[1]?.url)}
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[1]?.area_name}
                      </h5>
                      <p className="top_card_description">
                      {TruncateString(topNews[1]?.description, 70)}
                      </p>
                      <hr />
                      <div className="top_cardfooter">
                        <div className="top_cardfooter-left-container">
                          <span className="footer_left">
                            {topNews[1]?.status}
                          </span>
                          <span>
                            <strong>|</strong>
                          </span>
                          <span className="footer_left">
                            Processing Time&nbsp;: &nbsp;
                            <strong>
                              {topNews[1]?.processing_time_days}
                              Days
                            </strong>
                          </span>
                        </div>
                        <span className="footer_right">
                          {formatDate(topNews[1]?.start_date)}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="top_card mb-3"
                    onClick={() => sendToAnotherpage(topNews[3]?.url)}
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[3]?.area_name}
                      </h5>
                      <p className="top_card_description">
                      {TruncateString(topNews[3]?.description, 70)}
                      </p>
                      <hr />
                      {/* <hr className="top-line" /> */}
                      <div className="top_cardfooter">
                        <div className="top_cardfooter-left-container">
                          <span className="footer_left">
                            {topNews[3]?.status}
                          </span>
                          <span>
                            <strong>|</strong>
                          </span>
                          <span className="footer_left">
                            Processing Time&nbsp;: &nbsp;
                            <strong>
                              {topNews[3]?.processing_time_days}
                              Days
                            </strong>
                          </span>
                        </div>
                        <span className="footer_right">
                          {formatDate(topNews[3]?.start_date)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="latest">
              <div
                className="row"
                style={
                  {
                    //   backgroundColor: "rgba(0, 35, 53, 0.09)",
                    //   padding: "15px 10px",
                  }
                }
              >
                <div className="col-sm-7 latest-section-heading ">
                  <div className="latest-filter">
                    <span className="latest-text">Latest</span>
                    <hr className="latest-line" />

                    <DateRangePicker
                      format="MM/dd/yyyy"
                      character=" – "
                      placeholder="Select a Date range"
                      onChange={handleDateChange}
                    />
                  </div>
                  
                  {isPopupVisible && (
                    <div className="popup-overlay">
                      <div className="popup-content">
                        <RiCloseLargeFill className="noData" />
                        <h2>No Data Found</h2>
                        <p>Sorry, there is no data available to display.</p>
                        <button onClick={handleClosePopup}>Close</button>
                      </div>
                    </div>
                  )}

                  {latestNewsToDisplay &&
                    latestNewsToDisplay.map((news) => {
                      return (
                        <div
                          className="top_card mt-3 bg-white shadow-sm"
                          onClick={() => sendToAnotherpage(news.url)}
                        >
                          <div className="top_card_body">
                            <h5 className="top_card_title">{news.area_name}</h5>
                            <p className="top_card_description">
                              {truncateParagraph(news.description, 100)}
                            </p>
                            <hr />
                            <div className="top_cardfooter">
                              <div className="top_cardfooter-left-container">
                                <span className="footer_left">
                                  {news?.status}
                                </span>
                                <span>
                                  <strong>|</strong>
                                </span>
                                <span className="footer_left">
                                  Processing Time&nbsp;: &nbsp;
                                  <strong>
                                    {news?.processing_time_days}
                                    Days
                                  </strong>
                                </span>
                              </div>
                              <span className="footer_right">
                                {formatDate(news.start_date)}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* ================================================================================= */}

                <div className="col-sm-5">
                  <div className="trendingNews-top-container">
                    <div className="trendingNews-top-container-heading">
                      <h1>{subscribeNews?.[0]?.area_name}</h1>
                      <div className="col-sm-6 d-flex justify-content-end">
                        {/* <div class="dropdown">
                          <button
                            class="btn btn-secondary btn-dark dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              justifyContent: 'center',
                            }} 
                          >
                            Area Name
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {areaDropdown &&
                              areaDropdown.map((item) => {
                                return (
                                  <li style={{ cursor: "pointer" }}>
                                    <a
                                      class="dropdown-item"
                                      onClick={(e) =>
                                        filtertrendingNewsBYarea(item)
                                      }
                                    >
                                      {item}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                        </div> */}
                          <div
                        className="trending-news-dropdown"
                        onClick={() => setIsDropDownVisible((prev) => !prev)}
                      >
                        <button>Area Name</button>
                        <IoIosArrowDown color="white" />
                      </div>
                     { isDrpoDownVisible && <div className="dropDown-list">
                      {areaDropdown && areaDropdown.map((item, index) => (
                             <p key={index}
                             style={{cursor:'pointer'}}
                             onClick={(e) =>
                              filtertrendingNewsBYarea(item)
                            }
                              >      
                              {TruncateString(item, 12)}
                              {/* Hello */}
                              </p>
                            ))}
                     </div> }
                      </div>
                    </div>
                    <div className="trendingNews-buttons">
                      <div className="trendingNews-button">
                        <p>Approval Rate</p>
                        <p>{subscribeNews?.[0]?.approval_rate}</p>
                      </div>
                      <div className="trendingNews-button">
                        <p>Rejection Rate</p>
                        <p>{subscribeNews?.[0]?.rejection_rate}</p>
                      </div>
                      <div className="trendingNews-button">
                        <p>Pending Rate</p>
                        <p> { 100 - (removePercentageSymbolAndConvert(subscribeNews?.[0]?.approval_rate) + removePercentageSymbolAndConvert(subscribeNews?.[0]?.rejection_rate))}%</p>
                      </div>
                    </div>
                  </div>
                  <div className="trendingNews">
                    {subscribeNewsToDisplay &&
                      subscribeNewsToDisplay.map((news) => {
                        return (
                          <div
                            className="top_card2  mb-3 mt-4 bg-white shadow-sm"
                            onClick={() => sendToAnotherpage(news.url)}
                          >
                            <div className="top_card_body">
                              <h5 className="top_card_title">
                                {news.area_name}
                              </h5>
                              <p className="top_card_description">
                                {TruncateString(news.description, 80)}
                              </p>
                              <hr />
                              <div className="top_cardfooter">
                                <div className="top_cardfooter-left-container">
                                  <span className="footer_left">
                                    {TruncateString(news?.status, 20)}
                                  </span>
                                  <span>
                                    <strong>|</strong>
                                  </span>
                                  <span className="footer_left">
                                    Processing Time&nbsp;: &nbsp;
                                    <strong>
                                      {news?.processing_time_days}
                                      Days
                                    </strong>
                                  </span>
                                </div>
                                <span className="footer_right">
                                  {formatDate(news.start_date)}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pagination-controls">
        <div className="page-button">
          <button
            className={`${
              latestNewspage > 1
                ? "next-page-button"
                : "next-page-button disabled_button"
            }`}
            onClick={prevpageChange}
          >
            <FaArrowLeft className="arrow-icon-left" /> Prev Page
          </button>

          <button className={`${
              latestNewspage < Math.ceil(latestNews?.length / latestNewsPerPage)
                ? "next-page-button"
                : "next-page-button disabled_button"
            }`} onClick={nextpageChange}>
            Next Page <FaArrowRight className="arrow-icon-right" />
          </button>
        </div>

        <div className="pagination-count">
          <span>Page</span>
          <input
            type="number"
            name=""
            id=""       
           value={latestNewspage}
            onChange={(e) => latestNewspageChange(Number(e.target.value))}
          />
          <span>of {Math.ceil(latestNews?.length / latestNewsPerPage) || 1}</span>
        </div>
      </div>
      </section>

      
    </>
  );
};

export default PlanningApplications;
