import React, { useEffect, useState } from "react";
import { FaHeart, FaComment, FaBookmark, FaShareAlt, FaSearch } from 'react-icons/fa'; // Icons
import Header from "../component/Header";
import Footer from "../component/Footer";
import NewsCard from "../component/NewsCard"; // Import the NewsCard component
import NewsletterBanner from "./NewsletterBanner";
import { Link } from "react-router-dom";
import "./Library.css";

const Library = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Load articles from localStorage when the component mounts
  useEffect(() => {
    const savedArticles = JSON.parse(localStorage.getItem("bookmarkedArticles")) || [];
    setArticles(savedArticles);
  }, []);

  // Remove an article from the library
  const removeArticle = (id) => {
    const updatedArticles = articles.filter(article => article.id !== id);
    setArticles(updatedArticles);
    localStorage.setItem("bookmarkedArticles", JSON.stringify(updatedArticles));
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter articles based on the search term
  const filteredArticles = articles.filter(article =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="Library_container" style={{ overflowX: 'hidden' }}>
      <div className="custom-header-container">
        {/* Main Blog Title */}
        <div className="custom-title-container">
          <h1 className="custom-blog-title">THE BLOG</h1>
        </div>

        {/* Search Bar and My Library Section */}
        <div className="custom-search-library-container">
          <div className="custom-search-container">
            <input
              type="text"
              placeholder="Search"
              className="custom-search-input"
              value={searchTerm} // Bind the search term to the input
              onChange={handleSearchChange} // Use the change handler
            />
            <FaSearch className="search-icon" /> {/* Add the search icon */}
          </div>
          <div className="custom-library-link">
            <Link to="/blog/library" className="custom-library-button">
              <FaBookmark />
              My Library
            </Link>
          </div>
        </div>
      </div>
      
      <div className="Library">
        <div className="Library_text">
          <h1>Your Library</h1>
        </div>
        <div style={{ borderBottom: '1px solid black' }}>
          {filteredArticles.length > 0 ? (
            filteredArticles.map((article) => (
              <NewsCard key={article.id} article={article} onRemove={() => removeArticle(article.id)} />
            ))
          ) : (
            <p>{searchTerm ? "No results found." : "Your library is empty."}</p> // Improved feedback
          )}
        </div>
      </div>
      <NewsletterBanner />
    </div>
  );
};

export default Library;
