import React, { useEffect, useState } from "react";
import "./GovernmentLegislations.scss";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { FaArrowRight } from "react-icons/fa";
import { DateRangePicker, Footer } from "rsuite";
import axios from "axios";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { IoIosArrowDown, IoMdCloseCircleOutline } from "react-icons/io";
import { RiCloseLargeFill } from "react-icons/ri";

const GovernmentLegislations = () => {
  const [topNews, setTopNews] = useState("");
  const [page, setPage] = React.useState(1);
  const [latestNews, setlatestNews] = React.useState("");
  const [subscribeNews, setSubscribeNews] = React.useState("");
  const [isDrpoDownVisible, setIsDropDownVisible] = useState(false);

  useEffect(() => {
    get_top4_News();
    getlatestNews();
    getSubscribeNews();
  }, []);

  const get_top4_News = () => {
    axios
      .get("https://backend.inferredtheorem.com/get_top4_legis/")
      .then((response) => {
        console.log("response of top news", response);
        setTopNews(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getlatestNews = () => {
    axios
      .get(`https://backend.inferredtheorem.com/section_2_legis/`)
      .then((response) => {
        console.log("response latest legis", response);
        setlatestNews(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubscribeNews = () => {
    axios
      .get(`https://backend.inferredtheorem.com/get_news_by_type/`)
      .then((response) => {
        // console.log(response);
        setSubscribeNews(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);

  //   const day = date.getDate();
  //   const month = date.toLocaleString("default", { month: "long" });
  //   const year = date.getFullYear();

  //   const getOrdinalSuffix = (day) => {
  //     if (day > 3 && day < 21) return "th"; // catches 11th, 12th, 13th
  //     switch (day % 10) {
  //       case 1:
  //         return "st";
  //       case 2:
  //         return "nd";
  //       case 3:
  //         return "rd";
  //       default:
  //         return "th";
  //     }
  //   };

  //   const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

  //   return `${dayWithSuffix} ${month} ${year}`;
  // };

  // ====================================================================

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [startDate, setStartDate] = useState("2001-01-01");
  // const [endDate, setEndDate] = useState("2025-12-20");

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  useEffect(() => {
    console.log("endDate", endDate);
    if (endDate) {
      filterLatestNewsBYdate();
    } else {
      getlatestNews();
      setPopupVisible(false);
    }
  }, [endDate]);

  const formatDate3 = (dateStr) => {
    const date = new Date(dateStr);
    return date.getFullYear();
  };

  const [dataNotFound, setDataNotFound] = useState(false);

  const filterLatestNewsBYdate = () => {
    const start_date = formatDate3(startDate);
    const end_date = formatDate3(endDate);

    console.log("response filter by date", startDate, end_date);
    axios
      .get(
        `https://backend.inferredtheorem.com/from_to_date_legis/?from=${start_date}&to=${end_date}`
      )
      .then((response) => {
        setlatestNews(response.data.data);
        setPopupVisible(false);
        setlatestNewsPage(1);

        if (response.data.count == 0) {
          setDataNotFound(true);
          setPopupVisible(true);
          setlatestNewsPage(1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filtertrendingNewsBYarea = (area) => {
    // console.log("area", area);
    setIsDropDownVisible(false);
    axios
      .get(`https://backend.inferredtheorem.com/get_news_by_type/?l_type=${area}`)
      .then((response) => {
        console.log("response filtertrendingNewsBYarea", response);
        if (response.data.count == 0) {
          // alert("no news found in this area");
          // getSubscribeNews();
        } else {
          console.log("setSubscribeNews", response.data.data);
          setSubscribeNews(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        getSubscribeNews();
      });
  };

  const reduxSearch = useSelector((state) => state.searchreducer.searchNews);
  console.log("reduxSearch", reduxSearch);

  useEffect(() => {
    if (reduxSearch) {
      handleSearch();
    }
  }, [reduxSearch]);

  const handleSearch = () => {
    axios
      .get(`https://backend.inferredtheorem.com/search_pa/?search=${reduxSearch}`)
      .then((response) => {
        console.log("response handleSearch", response);
        if (response.data.count == 0) {
          // setPopupVisible(true);
        } else {
          // alert("no news found");

          setlatestNews(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [latestNewspage, setlatestNewsPage] = React.useState(1);
  const latestNewsPerPage = 8; // Number of items to display per page

  const latestNewspageChange = (value) => {
    if (value < 0) return;
    // console.log("value: ", value);
    setlatestNewsPage(value);
  };

  const latestNewsToDisplay =
    latestNews &&
    latestNews.slice(
      (latestNewspage - 1) * latestNewsPerPage,
      latestNewspage * latestNewsPerPage
    );

  const [subscribeNewspage, setlsubscribeNewsPage] = React.useState(1);
  const subscribeNewsPerPage = 8; // Number of items to display per page

  const subscribeNewspageChange = (event, value) => {
    setlatestNewsPage(value);
  };

  const subscribeNewsToDisplay =
    subscribeNews &&
    subscribeNews.slice(
      (subscribeNewspage - 1) * subscribeNewsPerPage,
      subscribeNewspage * subscribeNewsPerPage
    );

  const prevpageChange = () => {
    setlatestNewsPage((prevPage) => {
      if (prevPage > 1) {
        return prevPage - 1;
      } else {
        return prevPage;
      }
    });
  };

  const nextpageChange = () => {
    if(latestNewspage >= Math.ceil(latestNews?.length / latestNewsPerPage) ) return ;

    setlatestNewsPage((prevPage) => prevPage + 1);
  };

  const sendToAnotherpage = (url) => {
    // window.location.href = url;
    window.open(url, "_blank");
  };

  function truncateParagraph(paragraph, maxLength) {
    // Check if the paragraph length exceeds maxLength
    if (paragraph && paragraph.length > maxLength) {
      // Truncate the paragraph and add '...' at the end
      return paragraph.substring(0, maxLength) + "...";
    } else {
      // If not, return the paragraph as is
      return paragraph;
    }
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [sectionNews, setSectionNews] = useState("");

  useEffect(() => {
    handleInputChange();
  }, [inputPage]);

  const TruncateString = (str, length) => {
    if (str?.length > length) {
      let temp = str.substring(0, length) + "...";
      return temp;
    }
    return str;
  };

  function handleInputChange(value) {
    if (value >= 1 && value <= totalPage) {
      setCurrentPage(value);
      const data =
        latestNews &&
        latestNews.data.slice((value - 1) * 10 - 1, value * 10 - 1);
      setSectionNews(data);
    } else {
      setInputPage(value);
    }
  }

  console.log("section news", sectionNews);

  const [areaDropdown, setareaDropdown] = useState("");
  useEffect(() => {
    axios
      .get(`https://backend.inferredtheorem.com/legis_type_dropdown/`)
      .then((response) => {
        console.log(
          "response areaDropdown",
          response.data["Legislation Type"].slice(0, 5)
        );
        setareaDropdown(response.data["Legislation Type"].slice(0, 5));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // console.log("areaDropdown", areaDropdown);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getlatestNews();
  };

  return (
    <>
      <section
        className="GovernmentLegislations"
        style={{
          //   backgroundColor: "gray",
          //   backgroundColor: "white",
          color: "black",
          //   boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.07)",
          marginTop: "20px",
        }}
      >
        <div className="planning">
          <div className="container-fluid p-0">
            <div className="top4">
              <div className="row">
                {latestNews.length >= 0 ||
                  topNews >= 0 ||
                  (subscribeNews >= 0 && (
                    <div class="lds-ripple">
                      <div></div>
                      <div></div>
                    </div>
                  ))}

                <div className="col-lg-7">
                  <div
                    className="top_card mb-3"
                    onClick={() =>
                      sendToAnotherpage(topNews[0] && topNews[0].url)
                    }
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[0] && topNews[0].type}
                      </h5>
                      <p className="top_card_description">
                        {TruncateString(topNews[0]?.title, 90)}
                      </p>

                      <hr />
                      <div className="top_cardfooter">
                        <span className="footer_left">
                          {/* {topNews[0] && topNews[0].status} */}
                          {/* Research Gate */}
                        </span>
                        <span className="footer_right">
                          {topNews[0] && topNews[0].date}
                          {/* 23 JULY 2024 */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="top_card mb-3"
                    style={{ backgroundColor: "rgba(226, 237, 246, 1)" }}
                    onClick={() =>
                      sendToAnotherpage(topNews[0] && topNews[0].url)
                    }
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[2] && topNews[2].type}
                      </h5>
                      <p className="top_card_description">
                        {TruncateString(topNews[2]?.title, 90)}
                      </p>
                      <hr />
                      <div className="top_cardfooter">
                        <span className="footer_left">
                          {/* {topNews[2] && topNews[2].status} */}
                        </span>
                        <span className="footer_right">
                          {topNews[2] && topNews[2].date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Side Articles */}
                <div className="col-lg-5 col-md-12">
                  <div
                    className="top_card mb-4"
                    style={{ backgroundColor: "rgba(226, 237, 246, 1)" }}
                    onClick={() =>
                      sendToAnotherpage(topNews[0] && topNews[0].url)
                    }
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[1] && topNews[1].type}
                      </h5>
                      <p className="top_card_description">
                        {TruncateString(topNews[1]?.title, 65)}
                      </p>
                      <hr />
                      <div className="top_cardfooter">
                        <span className="footer_left">
                          {/* {topNews[1] && topNews[1].status} */}
                        </span>
                        <span className="footer_right">
                          {topNews[1] && topNews[1].date}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="top_card mb-3"
                    onClick={() =>
                      sendToAnotherpage(topNews[0] && topNews[0].url)
                    }
                  >
                    <div className="top_card_body">
                      <h5 className="top_card_title">
                        {topNews[3] && topNews[3].type}
                      </h5>
                      <p className="top_card_description">
                        {TruncateString(topNews[3]?.title, 65)}
                      </p>
                      <hr />
                      {/* <hr className="top-line" /> */}
                      <div className="top_cardfooter">
                        <span className="footer_left"> </span>
                        <span className="footer_right">
                          {topNews[3] && topNews[3].date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="latest">
              <div
                className="row"
                style={
                  {
                    //   backgroundColor: "rgba(0, 35, 53, 0.09)",
                    //   padding: "15px 10px",
                  }
                }
              >
                {/* =========================================================================== */}

                <div className="col-sm-7">
                  <div className="latest-filter">
                    <span className="latest-text">Latest</span>
                    <hr className="latest-line" />
                    
                    <DateRangePicker
                      format="MM/dd/yyyy"
                      character=" – "
                      placeholder="Select a Date range"
                      onChange={handleDateChange}
                      // ranges={[]}
                      // renderValue={(value) =>
                      //   value
                      //     ? `${new Date(value[0]).getFullYear()} – ${new Date(
                      //         value[1]
                      //       ).getFullYear()}`
                      //     : ""
                      // }
                    />
                  </div>
                  {console.log("dataNotFound", dataNotFound)}
                  {isPopupVisible ? (
                    <div className="popup-overlay">
                      <div className="popup-content">
                        <RiCloseLargeFill className="noData" />
                        <h2>No Data Found</h2>
                        <p>Sorry, there is no data available to display.</p>
                        <button onClick={handleClosePopup}>Close</button>
                      </div>
                    </div>
                  ) : null}

                  {latestNewsToDisplay &&
                    latestNewsToDisplay.map((news) => {
                      return (
                        <div
                          className="top_card mt-3 bg-white shadow-sm"
                          onClick={() => sendToAnotherpage(news.url)}
                        >
                          <div className="top_card_body">
                            <h5 className="top_card_title">{news.type}</h5>
                            <p className="top_card_description">
                              {truncateParagraph(news.title, 200)}
                            </p>
                            <hr />
                            <div className="top_cardfooter">
                              {/* <span className="footer_left">{news.status}</span> */}
                              <span className="footer_right">
                                {news?.date}{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {/* {
  dataNotFound &&  <h4 className="text-center"> Not Found</h4>
} */}
                  {/* <h4 className="text-center">{dataNotFound? 'Not Found':''}</h4> */}
                </div>
                {/* ================================================================================= */}

                <div className="col-sm-5">
                  <div className="trendingNews">
                    <div className="row d-flex align-items-center Trending-header-Container ">
                      <div className="col-sm-6">
                        {" "}
                        <h4 className="TrendingSearches_header">
                          Top Trending Searches
                        </h4>
                      </div>
                      <div className="col-sm-6 d-flex justify-content-end">
                        {" "}
                        {/* <div class="dropdown">
                          <button
                            class="btn btn-secondary btn-dark dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              display: "flex",
                              gap: "12px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            Sort By Area
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            {areaDropdown &&
                              areaDropdown.map((item) => {
                                return (
                                  <li style={{ cursor: "pointer" }}>
                                    <a
                                      class="dropdown-item"
                                      onClick={(e) =>
                                        filtertrendingNewsBYarea(item)
                                      }
                                    >
                                      {item}
                                    </a>
                                  </li>
                                );
                              })}

                            
                          </ul>
                        </div> */}
                        <div
                          className="trending-news-dropdown"
                          onClick={() => setIsDropDownVisible((prev) => !prev)}
                        >
                          <button> Sort By Area</button>
                          <IoIosArrowDown color="white" />
                        </div>
                        {isDrpoDownVisible && (
                          <div className="dropDown-list">
                            {areaDropdown &&
                              areaDropdown.map((item, index) => (
                                <p
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    filtertrendingNewsBYarea(item)
                                  }
                                >
                                  {TruncateString(item, 12)}
                                  {/* Hello */}
                                </p>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* ============================================================ */}

                    {subscribeNewsToDisplay &&
                      subscribeNewsToDisplay.map((news) => {
                        return (
                          <div
                            className="top_card2  mb-3 mt-4 bg-white shadow-sm"
                            onClick={() => sendToAnotherpage(news.url)}
                          >
                            <div className="top_card_body">
                              <h5 className="top_card_title">{news.type}</h5>
                              <p className="top_card_description">
                                {news.title}
                              </p>
                              <hr />
                              <div className="top_cardfooter">
                                <span className="footer_left">
                                  {/* {news.status} */}
                                </span>
                                <span className="footer_right">
                                  {news.date}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    {/* ============================================================ */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pagination-controls">
        <div className="page-button">
          <button
            className={`${
              latestNewspage > 1
                ? "next-page-button"
                : "next-page-button disabled_button"
            }`}
            onClick={prevpageChange}
          >
            <FaArrowLeft className="arrow-icon-left" /> Prev Page
          </button>

          <button className={`${
              latestNewspage < Math.ceil(latestNews?.length / latestNewsPerPage)
                ? "next-page-button"
                : "next-page-button disabled_button"
            }`} onClick={nextpageChange}>
            Next Page <FaArrowRight className="arrow-icon-right" />
          </button>
        </div>

        <div className="pagination-count">
          <span>Page</span>
          <input
            type="number"
            name=""
            id=""
            value={latestNewspage}
            onChange={(e) => latestNewspageChange(Number(e.target.value))}
          />
          <span>
            of {Math.ceil(latestNews?.length / latestNewsPerPage) || 1}
          </span>
        </div>
      </div>
      </section>

      
    </>
  );
};

export default GovernmentLegislations;
