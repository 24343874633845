import React, { useEffect, useState } from 'react';
import NewsCard from '../component/NewsCard'; // Import the NewsCard component
import './blog.css'; // Import the CSS
import { Link } from 'react-router-dom';
import { FaHeart, FaComment, FaBookmark, FaShareAlt, FaSearch,FaThumbsUp, FaUserCircle, FaCircle } from 'react-icons/fa'; // Icons
import Footer from '../component/Footer';
import Header from '../component/Header';
import NewsletterBanner from './NewsletterBanner';
import axios from 'axios';

const BlogPage = () => {
  const [Postid, setPostid] = useState([]);
  const [replies, setReplies] = useState([]); // To store replies
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [hasLiked, setHasLiked] = useState(false);
  const [newReply, setNewReply] = useState({});
  const [replyingTo, setReplyingTo] = useState(null); // Track which comment is being replied to
  const [showAllReplies, setShowAllReplies] = useState(false); // Track whether to show all replies
  const [loading, setLoading] = useState(true); // To handle loading state
  const [commentId, setCommentId] = useState(null); // State to store commentId
  const [openReplies, setOpenReplies] = useState({});
  const [loadingReplies, setLoadingReplies] = useState({});
  const [showReplies, setShowReplies] = useState({});
  const [likedComments, setLikedComments] = useState([]);




  const [currentPage, setCurrentPage] = useState(1);
  const [comments, setComments] = useState({});
  const [likes, setLikes] = useState({});
  const [articlesData, setArticlesData] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [newComment, setNewComment] = useState('');

  const articlesPerPage = 5;

  // Fetch articles from the API
  const fetchArticles = async () => {
    try {
      const response = await fetch('https://www.inferredtheorem.com:5000/api/auth/Posts');
      const data = await response.json();
      setArticlesData(data);

      const initialLikes = {};
      const initialComments = {};
      data.forEach(article => {
        initialLikes[article.id] = article.likes || 0;
        initialComments[article.id] = article.comments || [];
      });
      setLikes(initialLikes);
      setComments(initialComments);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    fetchArticles();
    console.log(userData)
  }, []);

  const totalPages = Math.ceil(articlesData.length / articlesPerPage);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    fetchComments(article.postId);

    // Check if the article is already bookmarked
    const savedArticles = JSON.parse(localStorage.getItem("bookmarkedArticles")) || [];
    const isBookmarked = savedArticles.some(saved => saved.id === article.id);
    setIsBookmarked(isBookmarked);
  };

  const handleBackToList = () => {
    setSelectedArticle(null);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handlePostComment = async (e) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userName = `${userData.first_name} ${userData.last_name}`;
    const postId = selectedArticle?.postId;
    const trimmedComment = newComment.trim();
  
    if (trimmedComment && postId) {
      // If it's Enter key or a click on the button
      if (e.key === 'Enter' || e.type === 'click') {
        e.preventDefault(); // Prevent form submission on Enter key
        try {
          const userId = userData.id;
          const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/comment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, userName, text: trimmedComment }),
          });
  
          const result = await response.json();
  
          if (response.ok) {
            setComments(prev => ({
              ...prev,
              [postId]: [...(prev[postId] || []), { userId, text: trimmedComment, _id: result._id }],
            }));
            setNewComment(''); // Clear comment input
            console.log('Comment added successfully:', result);
          } else {
            console.error('Error adding comment:', result.message);
          }
        } catch (error) {
          console.error('Error adding comment:', error);
        }
      }
    }
  };
  
  const fetchReplies = async (commentId, postId) => {
    setLoadingReplies((prev) => ({ ...prev, [commentId]: true })); // Start loading for this comment
    try {
      const response = await axios.get(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/comments/${commentId}/replies`);
      setReplies((prevReplies) => ({
        ...prevReplies,
        [commentId]: response.data.replies, // Store replies for this comment
      }));
    } catch (error) {
      console.error('Error fetching replies:', error);
    } finally {
      setLoadingReplies((prev) => ({ ...prev, [commentId]: false })); // Stop loading
    }
  };

  // Toggle replies visibility and fetch replies if not yet loaded
  const handleShowReplies = (commentId, postId) => {
    setShowReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId], // Toggle show state for this comment
    }));

    // Fetch replies if not already loaded
    if (!replies[commentId]) {
      fetchReplies(commentId, postId);
    }
  };
  useEffect(() => {
    const storedLikedComments = JSON.parse(localStorage.getItem('likedComments')) || [];
    setLikedComments(storedLikedComments);
  }, []);
  //comment like
  // useEffect(() => {
  //   if (commentId && Postid) { // Only fetch if both are set
  //     const fetchReplies = async () => {
  //       setLoadingReplies((prev) => ({ ...prev, [commentId]: true }));

  //       try {
  //         const response = await axios.get(`https://www.inferredtheorem.com:5000/api/auth/posts/${Postid}/comments/${commentId}/replies`);
  //         setReplies((prevReplies) => ({
  //           ...prevReplies,
  //           [commentId]: response.data.replies,
  //         }));
  //       } catch (error) {
  //         console.error('Error fetching replies:', error);
  //       } finally {
  //         setLoadingReplies((prev) => ({ ...prev, [commentId]: false }));
  //       }
  //     };

  //     fetchReplies();
  //   }
  // }, [commentId, Postid]);

  // // Handle comment click
  // const handleCommentClick = (id, postId) => {
  //   setCommentId(id);
  //   setPostid(postId);
  //   setLoadingReplies((prev) => ({ ...prev, [id]: true }));
  // };

  // // Toggle replies display and fetch if not already done
  // const toggleReplies = (commentId, postId) => {
  //   setOpenReplies((prev) => ({
  //     ...prev,
  //     [commentId]: !prev[commentId],
  //   }));

  //   if (!replies[commentId]) {
  //     handleCommentClick(commentId, postId);
  //   }
  // };

  const handleLikeComment = async (commentId, postId) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userName = `${userData.first_name} ${userData.last_name}`;
    const userId = userData.id; // Assuming userId is stored in userData

    try {
      const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/comments/${commentId}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, userName }),
      });

      if (response.ok) {
        console.log("Liked successfully");

        // Update the likedComments state and localStorage
        let updatedLikedComments = JSON.parse(localStorage.getItem('likedComments')) || [];

        // Toggle like/unlike the comment
        if (updatedLikedComments.includes(commentId)) {
          updatedLikedComments = updatedLikedComments.filter(id => id !== commentId);
        } else {
          updatedLikedComments.push(commentId);
        }

        // Save to localStorage
        localStorage.setItem('likedComments', JSON.stringify(updatedLikedComments));

        // Update the likedComments state
        setLikedComments(updatedLikedComments);
      } else {
        console.error('Failed to like the comment:', await response.text());
      }
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };


  const handleReply = async (commentId, postId) => {
    const replyText = newReply[commentId];
    const userData = JSON.parse(localStorage.getItem('userData'));
    const userName = `${userData.first_name} ${userData.last_name}`;


    if (!replyText.trim()) return;

    try {

      const userId = userData.id;  // replace with actual userId
      const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/comments/${commentId}/reply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, userName, text: replyText }),
      });

      if (response.ok) {
        // Reset reply state and refetch comments
        setNewReply(prev => ({ ...prev, [commentId]: '' }));
        fetchComments(postId);
      }
    } catch (error) {
      console.error('Error replying to comment:', error);
    }
  };
  const formatTimeAgo = (date) => {
    const secondsAgo = Math.floor((new Date() - new Date(date)) / 1000);
    const intervals = [
      { label: 'year', seconds: 31536000 },
      { label: 'month', seconds: 2592000 },
      { label: 'day', seconds: 86400 },
      { label: 'hour', seconds: 3600 },
      { label: 'minute', seconds: 60 },
      { label: 'second', seconds: 1 },
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsAgo / interval.seconds);
      if (count > 0) {
        return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
      }
    }
    return 'just now';
  };

  const handleLike = async () => {
    const postId = selectedArticle.postId;
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (!userData || !userData.id) {
      console.error("User not logged in.");
      return;
    }

    try {
      const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userData.token}`, // assuming token is stored in userData
        },
        body: JSON.stringify({ userId: userData.id }),
      });

      if (!response.ok) {
        throw new Error('Failed to toggle like');
      }

      const data = await response.json();

      // Update likes and like status based on server response
      setLikes((prev) => ({
        ...prev,
        [postId]: data.likes,
      }));
      setHasLiked(data.hasLiked); // Update like status based on server response
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  const fetchComments = async (postId) => {
    try {
      const response = await fetch(`https://www.inferredtheorem.com:5000/api/auth/posts/${postId}/comments`);
      const data = await response.json();
      if (response.ok) {
        setComments(prev => ({
          ...prev,
          [postId]: data.comments || [],
        }));
      } else {
        console.error('Error fetching comments:', data.message);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = (Array.isArray(articlesData) ? articlesData : []).slice(indexOfFirstArticle, indexOfLastArticle);

  const handleBookmark = () => {
    const articleId = selectedArticle.id;
    const savedArticles = JSON.parse(localStorage.getItem("bookmarkedArticles")) || [];
    const articleExists = savedArticles.find(saved => saved.id === articleId);

    if (articleExists) {
      const updatedArticles = savedArticles.filter(saved => saved.id !== articleId);
      localStorage.setItem("bookmarkedArticles", JSON.stringify(updatedArticles));
      setIsBookmarked(false);
      alert("Article removed from your library!");
    } else {
      const updatedArticles = [...savedArticles, selectedArticle];
      localStorage.setItem("bookmarkedArticles", JSON.stringify(updatedArticles));
      setIsBookmarked(true);
      alert("Article bookmarked!");
    }
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: selectedArticle.title,
        text: selectedArticle.preview,
        url: window.location.href,
      }).then(() => console.log('Article shared successfully!'))
        .catch(error => console.log('Error sharing the article:', error));
    } else {
      alert('Sharing is not supported on this browser.');
    }
  };

  // Format the createdAt date
  const formattedDate = selectedArticle ? new Date(selectedArticle.createdAt).toLocaleDateString() : '';

  return (
    <div style={{ overflowX: 'hidden' }} className='blog-full-page' >
      <div className="custom-header-container">
        <div className="custom-title-container">
          <h1 className="custom-blog-title">THE BLOG</h1>
        </div>

        <div className="custom-search-library-container">
          <div className="custom-search-container">
            <input
              type="text"
              placeholder="Search"
              className="custom-search-input"
            />
            <FaSearch className="search-icon" />
          </div>
          <div className="custom-library-link">
            <Link to="/blog/library" className="custom-library-button">
              <FaBookmark />
              My Library
            </Link>
          </div>
        </div>
      </div>

      <div className="blog-page-container">
        <div className={`article-list ${selectedArticle ? 'shifted' : ''}`}>
          {currentArticles.map(article => (
            <div
              key={article.id}
              className={`article-preview ${selectedArticle ? 'active' : ''}`}
              onClick={() => handleArticleClick(article)}
            >
              <NewsCard article={article} isActive={selectedArticle !== null} />
            </div>
          ))}
        </div>

        {selectedArticle && (
          <div className="article-detail">
            <h2>{selectedArticle.title}</h2>
            <div className='date' style={{ marginBottom: '10px', paddingBottom: '10px', borderBottom: '1px solid #7C7A7A' }}>
              {formattedDate} {/* Display the formatted date */}
            </div>
            <div className='news-detail'>
              <span
                className={`like-btn ${hasLiked ? 'liked' : ''}`} // Apply "liked" class when the user has liked the post
                onClick={handleLike}
              >
                <FaHeart /> {likes[selectedArticle.postId] || selectedArticle.likes}
              </span>

              <span className='comment-btn'>
                <FaComment /> {comments[selectedArticle.postId]?.length || selectedArticle.comments?.length || 0}
              </span>
              <div className="news-actions">
                <FaBookmark
                  className={`news-icon ${isBookmarked ? 'bookmarked' : ''}`}
                  onClick={handleBookmark}
                />
                <FaShareAlt className="news-icon" onClick={handleShare} />
              </div>
            </div>
            <p>{selectedArticle.content}</p>
            <button onClick={handleBackToList} className="back-button">
              ← Back to articles
            </button>
            <div className="comments-section" style={{ overflowY: 'scroll', maxHeight: "700px" }}>
              <div style={{ display: 'flex' }}>
                <textarea className='Text_area'
                  placeholder="Write a comment..."
                  value={newComment}
                  onChange={handleCommentChange}
                  onKeyDown={handlePostComment}
                />
                <button className='Button-post' onClick={handlePostComment}>Post</button>
              </div>
              <h3>Comments:</h3>
              <div className="comments-list">
                {comments[selectedArticle.postId] && Array.isArray(comments[selectedArticle.postId]) && comments[selectedArticle.postId].length > 0 ? (
                  comments[selectedArticle.postId].map((comment) => (
                    <div key={comment._id} style={{ borderBottom: '2px solid black', marginTop: '20px', padding: '10px' }}>
                      <p><strong>{comment.userName}</strong> <small>{formatTimeAgo(comment.createdAt)}</small></p>
                      <p style={{ marginLeft: '40px' }}>{comment.text}</p>
                      <div style={{ marginLeft: '40px', display: 'flex', gap: '10px' }}>
                        <button style={{ backgroundColor: '#00ffff00' }} onClick={() => handleLikeComment(comment._id, selectedArticle.postId)}>{likedComments.includes(comment._id) ? (
                          <FaThumbsUp />  // Display thumbs-up icon if liked
                        ) : (
                          <p>Like</p>
                            // Display regular thumbs-up icon if not liked
                        )}</button>
                        <button
                          style={{ backgroundColor: '#00ffff00' }}
                          onClick={() => setReplyingTo(replyingTo === comment._id ? null : comment._id)}
                        >
                          Reply
                        </button>
                        <button style={{ backgroundColor: '#00ffff00' }} onClick={() => handleShowReplies(comment._id, selectedArticle.postId)}>
                          {showReplies[comment._id] ? "Hide Replies" : "Show Replies"}
                        </button>
                      </div>
                      {replyingTo === comment._id && (
                        <div className="reply-section">
                          <textarea
                            placeholder="Write a reply..."
                            value={newReply[comment._id] || ''}
                            onChange={(e) => setNewReply({ ...newReply, [comment._id]: e.target.value })}
                          />
                          <button
                            onClick={() => {
                              handleReply(comment._id, selectedArticle.postId);
                              setReplyingTo(null); // Close reply box after submitting
                            }}
                          >
                            Submit
                          </button>
                        </div>
                      )}

                      {/* Replies Section */}
                      {showReplies[comment._id] && (
                        <div className="replies">
                          {loadingReplies[comment._id] ? (
                            <p>Loading replies...</p> // Loading indicator for this comment's replies
                          ) : replies[comment._id] && replies[comment._id].length > 0 ? (
                            replies[comment._id].map((reply) => (
                              <div key={reply._id} style={{ marginLeft: '60px', padding: '5px' }}>
                                <p><strong>{reply.userId}:</strong> {reply.text}</p>
                                <small>{formatTimeAgo(reply.createdAt)}</small>
                              </div>
                            ))
                          ) : (
                            <p style={{ marginLeft: '60px', fontStyle: 'italic' }}>No replies yet.</p>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <p>No comments yet.</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="pagination-controls">
        <div className='Button-container'>
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
      </div>
      <NewsletterBanner />
    </div>
  );
};

export default BlogPage;
