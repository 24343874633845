import { createSlice } from '@reduxjs/toolkit'


const SearchSlice = createSlice({
    name: 'SearchSlice',
    initialState: {
        searchNews:{}
    },
    reducers: {
        filterNews: (state, action) => {
           
            // state.candidates.unshift(action.payload)
            state.searchNews = action.payload
        },
       
    }
})
export const {filterNews} = SearchSlice.actions
export default SearchSlice.reducer