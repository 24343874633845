import React, { useEffect, useState } from "react";
import "./PublicTenders.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import MinMaxRange from "../component/MinMaxRange";
import { DateRangePicker } from "rsuite";
import { RiCloseLargeFill } from "react-icons/ri";
import axios from "axios";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const TruncateString = (str, length) => {
  if (str?.length > length) {
    let temp = str.substring(0, length) + "...";
    return temp;
  }
  return str;
};

function FormatDate(dateString) {
  const date = new Date(dateString);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getUTCDate(); // Get day
  const month = monthNames[date.getUTCMonth()]; // Get month name
  const year = date.getUTCFullYear(); // Get year

  return `${day} ${month} ${year}`;
}

function formatCurrencyStringWithCommas(currencyString) {
  const number = parseFloat(currencyString);

  if (isNaN(number)) {
    return "N/A";
  }

  return number.toLocaleString();
}

function FormatToSimpleDate(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

const TenderSectionCard = ({
  title,
  description,
  publishedDate,
  awardedDate,
  awardedValue,
  awardedSupplier,
  noticeStatus,
  organisationName,
  region,
  start,
  end,
}) => {
  const [showFullText, setShowFullText] = useState(false);

  // console.log(showFullText);

  return (
    <div className="tender-section-card">
      <div className="tender-section-left-card">
        {showFullText ? (
          <>
          <h1>{title}</h1>
          <p>{description}</p>
          </>
        ) : (
          <>
          <h1>{TruncateString(title, 95)}</h1>
          <p>{TruncateString(description, 400)}</p>
          </>
        )}

        <div className="tender-section-left-card-buttons">
          <div className="tender-section-left-card-button">
            <div>Publishhed Date</div>
            <div className="tender-section-left-card-date">
              {FormatToSimpleDate(publishedDate)}
            </div>
          </div>

          <div className="tender-section-left-card-button">
            <div>Awarded Date</div>
            <div className="tender-section-left-card-date">
              {FormatToSimpleDate(awardedDate)}
            </div>
          </div>

          <div className="tender-section-left-card-button">
            <div>Awarded Value</div>
            <div className="tender-section-left-card-date">£{formatCurrencyStringWithCommas(awardedValue)}</div>
          </div>

          <div className="tender-section-left-card-button">
            <div>Awarded Suppiler</div>
            <div className="tender-section-left-card-date">
              {TruncateString(awardedSupplier, 26)}
            </div>
          </div>

          <div className="tender-section-left-card-button">
            <div>Notice Status</div>
            <div className="tender-section-left-card-date">{noticeStatus}</div>
          </div>
        </div>
      </div>
      <div className="tender-section-right-card">
        <div className="tender-section-right-card-description">
          <p>Org. Name : {TruncateString(organisationName, 25)}</p>
          <p>Region : {TruncateString(region, 25)}</p>
          <p>Start : {FormatDate(start)}</p>
          <p>End : {FormatDate(end)}</p>
        </div>
        <div
          className="tender-section-right-card-button"
          // onClick={()=> console.log(FormatDate(end))}
          onClick={() => setShowFullText((prev) => !prev)}
        >
          <div className="tender-section-right-card-icon">
            {showFullText ? (
              <>
              <IoIosArrowUp size={25} />
              <p>Show Less</p>             
              </>
            ) : (
              <>
                {<IoIosArrowDown size={25} />}
                {<p>Read More</p>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


const PublicTenders = () => {
  const [topNews, setTopNews] = useState("");
  const [sectionNews, setSectionNews] = useState("");
  const [allNews, setAllNews] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [showRangeCard, setShowRangeCard] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [awardedData, setAwardedData] = useState({});
  const [MinMaxValue, setMinMaxValue] = useState({});

  useEffect(() => {
    getTopNews();
    getSectionNews();
    getAwarderData();
  }, []);

  useEffect(() => {
    filterLatestNewsBYdate();
  }, [endDate, MinMaxValue]);

  async function getTopNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/get_top4_public/");
      const resp2 = await resp1.json();
      setTopNews(resp2);
    } catch (error) {
      console.log("error in fetching top news in publicTender page", error);
    }
  }

  async function getSectionNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/section_2_public/");
      const resp2 = await resp1.json();
      setAllNews(resp2);
      const page = Math.ceil(resp2.count / 5);
      setTotalPage(page);
      setSectionNews(resp2.data.slice(0, 5));
    } catch (error) {
      console.log("error in fetching section news in white-paper page", error);
    }
  }

  async function getAwarderData() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com//awarded_dropdown/");
      const resp2 = await resp1.json();
      setAwardedData(resp2);
      setMinMaxValue(resp2);
    } catch (error) {
      console.log("error in fetching section news in white-paper page", error);
    }
  }

  const formatDate2 = (date) => {
    return date instanceof Date && !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "Not selected";
  };

  const filterLatestNewsBYdate = () => {
    let start_date = '';
    let end_date = '';
    if(startDate && endDate){
       start_date = formatDate2(startDate);
       end_date = formatDate2(endDate);
    }

    console.log('startDateeeee', startDate);

    axios
      // `filter_public/?min_value=${MinMaxValue.min}&max_value=${MinMaxValue.max}&from_date=${start_date}&end_date=${end_date}`
      .get(
        `https://backend.inferredtheorem.com/filter_public/?min_value=${MinMaxValue.min}&max_value=${MinMaxValue.max}&from_date=${start_date}&end_date=${end_date}`
      )
      .then((response) => {
        if (response.data.data.length) {
          setAllNews(response.data);
          setSectionNews(response.data.data.slice(0, 5));
          const page = Math.ceil(response.data.count / 5);
          setTotalPage(page);
          setPopupVisible(false);
          setCurrentPage(1);
          setInputPage(1);
        } else {
          handleShowPopup();
          setSectionNews(null);
          setTotalPage(1);
          setCurrentPage(1);
          setInputPage(1);
        }
      })
      .catch((error) => {
        console.log("error in filter news by date", error);
        getSectionNews();
      });
  };

  function handleFetchNextPage() {
    const newpage = Number(currentPage) + 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function FetchPage(pageNumber) {
    let data;
    if (pageNumber > 1) {
      data = allNews.data.slice((pageNumber - 1) * 5, pageNumber * 5);
    } else {
      data = allNews.data.slice(0, 5);
    }
    setSectionNews(data);
    console.log(pageNumber);
  }

  function handleFetchPrevPage() {
    const newpage = currentPage - 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function handleInputChange(e) {
    const value = e.target.value;
    if (value < 0) {
      return;
    }
    setInputPage(value);
    setCurrentPage(value);
    const data = allNews.data.slice((value - 1) * 5, value * 5);
    setSectionNews(data);
  }

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setShowRangeCard(false);
    setStartDate("");
    setEndDate("");
    getAwarderData();
    getSectionNews();
  };

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <section
      className="PlanningApplications"
      style={{
        color: "black",
        marginTop: "20px",
      }}
    >
      <div className="planning">
        <div className="publicTender-wrapper">
          <div className="top-news-section">
            <div className="row">
              <div className="col-sm-7">
                <div className="top-news-left-section">
                  {topNews[0] && (
                    <div className="top-1st-news">
                      <h3>{TruncateString(topNews[0]?.title, 130)}</h3>
                      <p>{TruncateString(topNews[0]?.description, 400)}</p>

                      <div className="top-news-links">
                        <span>
                          {TruncateString(topNews[0]?.organisationName)}
                        </span>
                        <span>{FormatDate(topNews[0]?.start)}</span>
                      </div>
                    </div>
                  )}

                  {topNews[1] && (
                    <div className="top-2nd-news">
                      <h3>{TruncateString(topNews[1].title, 130)}</h3>
                      <p>{TruncateString(topNews[1].description, 280)}</p>

                      <div className="top-news-links">
                        <span>
                          {TruncateString(topNews[1]?.organisationName)}
                        </span>
                        <span>{FormatDate(topNews[1]?.start)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-5">
                <div className="top-news-right-section">
                  {topNews[2] && (
                    <div className="top-2nd-news">
                      <h3>{TruncateString(topNews[2].title, 97)}</h3>
                      <p>{TruncateString(topNews[2].description, 200)}</p>

                      <div className="top-news-links">
                        <span>{topNews[2].organisationName}</span>
                        <span>{FormatDate(topNews[2]?.start)}</span>
                      </div>
                    </div>
                  )}

                  {topNews[3] && (
                    <div className="top-1st-news">
                      <h3>{TruncateString(topNews[3]?.title, 97)}</h3>
                      <p>{TruncateString(topNews[3]?.description, 300)}</p>

                      <div className="top-news-links">
                        <span>
                          {TruncateString(topNews[3]?.organisationName, 40)}
                        </span>
                        <span>{FormatDate(topNews[3]?.start)}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="latest-section">
            <h1 className="latest-name">Latest</h1>
            <div className="latest-section-border"></div>
            <div className="latest-section-button">
              {showRangeCard && (
                <div className="latest-section-range">
                  <MinMaxRange
                    awardedData={awardedData}
                    MinMaxValue={MinMaxValue}
                    setMinMaxValue={setMinMaxValue}
                  />
                </div>
              )}
              <DateRangePicker
                format="MM/dd/yyyy"
                character=" – "
                placeholder="Select a Date range"
                onChange={handleDateChange}
              />
              <button onClick={() => setShowRangeCard((prev) => !prev)}>
                Awarded Value
                <div className="awarded-button-downArrow">
                  <IoIosArrowDown color="black" />
                </div>
              </button>
            </div>
          </div>
          {isPopupVisible && (
            <div className="popup-overlay">
              <div className="popup-content">
                <RiCloseLargeFill className="noData" />
                <h2>No Data Found</h2>
                <p>Sorry, there is no data available to display.</p>
                <button onClick={handleClosePopup}>Close</button>
              </div>
            </div>
          )}
          <div className="tender-section">
            {sectionNews &&
              sectionNews.map((item, index) => (
                <TenderSectionCard
                  title={item?.title}
                  description={item?.description}
                  publishedDate={item?.publishedDate}
                  awardedDate={item?.awardedDate}
                  awardedValue={item?.awardedValue}
                  awardedSupplier={item?.awardedSupplier}
                  noticeStatus={item?.noticeStatus}
                  organisationName={item?.organisationName}
                  region={item?.region}
                  start={item?.start}
                  end={item?.end}
                />
              ))}
          </div>

          {/* pagination */}
          <div className="pagination-controls">
            <div className="page-button">
              <button
                className={`${
                  currentPage === 1 ? "next-button-display" : "next-button"
                }`}
                onClick={handleFetchPrevPage}
              >
                <FaArrowLeftLong size={15} /> Prev Page
              </button>

              <button
                className={`${
                  currentPage === totalPage
                    ? "next-button-display"
                    : "next-button"
                }`}
                onClick={handleFetchNextPage}
              >
                Next Page <FaArrowRightLong size={15} />
              </button>
            </div>

            <div className="pagination-count">
              <span>Page</span>
              <input
                type="number"
                name=""
                id=""
                value={inputPage}
                onChange={handleInputChange}
                // onKeyDown={handlePageKeyPress}
              />
              <span>of {totalPage}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PublicTenders;
