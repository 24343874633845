// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/Objects2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RealState2 {
  height: 370px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/realState2.scss"],"names":[],"mappings":"AAAA;EAWI,aAAA;EACA,mDAAA;EACA,2BAAA;EACA,4BAAA;EACA,UAAA;EAMA,WAAA;EACA,kBAAA;EACA,WAAA;AAdJ","sourcesContent":[".RealState2{\n    // height: 300px;\n    // background: url('../images/Objects2.png');\n    // background-position: center;\n    // /* background-repeat: no-repeat; */\n    // z-index: 2;\n    // position: relative;\n    // top: -261px;\n    // width: 100%;\n\n\n    height: 370px;\n    background: url('../images/Objects2.png');\n    background-position: center;\n    background-repeat: no-repeat;\n    z-index: 0;\n    // position: relative;\n    // top: 50px;\n\n\n\n    z-index: -1;\n    position: absolute;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
