import React, { useState, useEffect } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import Layout from './component/Layout';
import Home from './pages/Home';
import Newsroom from './pages/Newsroom';
import About from './pages/About';
import Header from './component/Header';
import Footer from './component/Footer';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import BlogPage from './pages/blog';
import Library from './pages/Library';
import AddNewPost from './component/Add_new_post';
import PostsTable from './component/Post_Blog';
import EditProfile from './component/Profile';
import { jwtDecode } from 'jwt-decode';
import ScrollToTop from './component/ScrollToTop';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check for token on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setIsAuthenticated(true);
        setUserRole(decoded.role);
      } catch (error) {
        console.error('Invalid token:', error);
        setIsAuthenticated(false);
        setUserRole(null);
      }
    }
    setLoading(false);
  }, []);

  // Modified authenticateUser function
  const authenticateUser = (status, token = null) => {
    setIsAuthenticated(status);

    if (status && token) {
      try {
        const decoded = jwtDecode(token);
        setUserRole(decoded.role);
        localStorage.setItem('token', token); // Store token in local storage
      } catch (error) {
        console.error('Error decoding token:', error);
        setUserRole(null);
      }
    } else {
      setUserRole(null);
      localStorage.removeItem('token'); // Remove token on logout
    }
  };

  // ProtectedRoute for role-based access control
  const ProtectedRoute = ({ element, roles = [] }) => {
    if (!isAuthenticated) return <Navigate to="/login" />;
    if (roles.length && !roles.includes(userRole)) return <Navigate to="/" />;
    return element;
  };

  // Display loading screen while checking for token
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header
        isAuthenticated={isAuthenticated}
        authenticateUser={authenticateUser}
        isAdmin={userRole === 'admin'}
      />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/newsroom" element={<Newsroom />} />
        <Route path="/about" element={<About />} />
        <Route path="/signup" element={<SignUp authenticateUser={authenticateUser} />} />
        <Route path="/login" element={<Login authenticateUser={authenticateUser} />} />

        {/* Protected Routes */}
        <Route path="/profile" element={<ProtectedRoute element={<EditProfile />} />} />
        <Route path="/blog" element={<ProtectedRoute element={<BlogPage />} />} />
        <Route path="/blog/library" element={<ProtectedRoute element={<Library />} />} />

        {/* Admin-only routes */}
        <Route path="/my-blogs" element={<ProtectedRoute element={<PostsTable />} roles={['admin']} />} />
        <Route path="/posts/edit" element={<ProtectedRoute element={<AddNewPost />} roles={['admin']} />} />
        <Route path="/post" element={<ProtectedRoute element={<AddNewPost />} roles={['admin']} />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
