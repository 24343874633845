import React, { useState, useEffect } from 'react';
import axios from 'axios'; // For API requests
import "../component/Profile.css"; // Make sure the CSS file is in the correct path

const EditProfile = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  });

  useEffect(() => {
    // Load user data from local storage
    const storedData = JSON.parse(localStorage.getItem('userData'));
    
    if (storedData) {
      setFormData({
        firstName: storedData.firstName || '',  // Ensure default values
        lastName: storedData.lastName || '',
        phoneNumber: storedData.phoneNumber || '',
        email: storedData.email || ''
      });
    } else {
      alert("User data not found. Please log in again.");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Retrieve user ID from local storage
    const userId = JSON.parse(localStorage.getItem('userData'))?.id; // Adjust to fetch userId from userData

    if (!userId) {
      alert("User ID not found. Unable to update profile.");
      return;
    }

    try {
      const response = await axios.put(`https://www.inferredtheorem.com:5000/api/auth/update-profile/${userId}`, formData);
      
      if (response.data.success) {
        // Update local storage with new data
        localStorage.setItem('userData', JSON.stringify({ ...formData, id: userId }));
        alert('Profile updated successfully!');
      } else {
        alert('Failed to update profile. Please try again.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="edit-profile-container">
      <h2>Edit Profile</h2>
      <div className="profile-picture-section">
        <img src="https://via.placeholder.com/100" alt="Profile" className="profile-img" />
        <button className="edit-picture-btn">
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </button>
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              required
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
          </div>
          <div className="form-group">
            <label>Email ID</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email ID"
              required
            />
          </div>
        </div>

        <button type="submit" className="save-changes-btn">Save Changes</button>
      </form>
    </div>
  );
};

export default EditProfile;
