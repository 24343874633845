// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #002848;
  margin-top: 1px;
  padding: 56px 0px 30px 0;
}
.footer .nav li {
  margin-right: 25px;
}
.footer .footer .nav-link {
  color: #D0DAF5 !important;
  font-family: Libre Franklin;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.97px;
  text-align: center;
}
.footer .footer .nav-link a {
  color: #D0DAF5;
  text-decoration: none;
}
.footer .social-icons {
  margin-bottom: 20px;
  margin-top: 20px;
  cursor: pointer;
}
.footer .social-icons a {
  font-size: 20px;
  margin: 5px 15px;
  color: #D0DAF5;
}
.footer p {
  font-family: Libre Franklin;
  font-size: 12px;
  font-weight: 400;
  opacity: 60%;
  line-height: 15.62px;
  text-align: center;
  color: #D0DAF5;
}
.footer .footer-contact-info {
  color: #FFFFFF;
}
.footer .footer-copyright-text {
  margin-top: 4.5rem;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/component/footer.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,eAAA;EACA,wBAAA;AAAF;AAGI;EACI,kBAAA;AADR;AAKE;EACE,yBAAA;EACA,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;AAHJ;AAII;EAEI,cAAA;EACA,qBAAA;AAHR;AAMA;EACI,mBAAA;EACA,gBAAA;EACA,eAAA;AAJJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,cAAA;AAJJ;AAOE;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,cAAA;AALJ;AAQE;EACE,cAAA;AANJ;AAQE;EACE,kBAAA;EACA,0BAAA;AANJ","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap\");\n\n.footer {\n  background-color: #002848;\n  margin-top: 1px;\n  padding: 56px 0px 30px 0;\n\n.nav{\n    li{\n        margin-right: 25px;\n      }\n}\n \n  .footer .nav-link {\n    color: #D0DAF5 !important;\n    font-family: Libre Franklin;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 16.97px;\n    text-align: center;\n    a{\n\n        color: #D0DAF5;\n        text-decoration: none;\n    }\n  }\n.social-icons{\n    margin-bottom: 20px;\n    margin-top: 20px;\n    cursor: pointer;\n}\n  .social-icons a {\n    font-size: 20px;\n    margin: 5px 15px;\n    color: #D0DAF5;\n  }\n\n  p {\n    font-family: Libre Franklin;\n    font-size: 12px;\n    font-weight: 400;\n    opacity: 60%;\n    line-height: 15.62px;\n    text-align: center;\n    color: #D0DAF5;\n  }\n  \n  .footer-contact-info{\n    color: #FFFFFF;  \n  }\n  .footer-copyright-text{\n    margin-top: 4.5rem;\n    text-decoration: underline;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
