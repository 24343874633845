// ================================
import React, { useEffect, useState } from "react";
import "./whitepaper2.scss";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";
import { RiCloseLargeFill } from "react-icons/ri";
import { IoIosArrowDown } from "react-icons/io";

const TruncateString = (str, length) => {
  if (str?.length > length) {
    let temp = str.substring(0, length) + "...";
    return temp;
  }
  return str;
};

const FromatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "numeric", month: "long", year: "numeric" };
  return date.toLocaleDateString("en-GB", options);
};

const LongPara = ({
  sectionNews,
  url,
  title,
  description,
  author,
  publisherName,
  publisherDate,
}) => {
  return (
    <>
      {sectionNews && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="latest-news-card latest-news-long-card">
            <h3>{TruncateString(title, 60)}</h3>
            <p>{TruncateString(description, 400)}</p>
            <div className="trending-news-author-container">
              {author
                .split(",")
                .slice(0, 2)
                .map((auth, index) => (
                  <div key={index} className="trending-news-author">
                    <div className="circle"></div>
                    <span>{auth}</span>
                  </div>
                ))}
            </div>
            <div className="latest-news-link">
              <span>{TruncateString(publisherName, 12)}</span>
              <span>{FromatDate(publisherDate)}</span>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

const ShortPara = ({
  sectionNews,
  url,
  title,
  description,
  author,
  publisherName,
  publisherDate,
}) => {
  return (
    <>
      {sectionNews && (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="latest-news-card latest-news-short-card">
            <h3>{TruncateString(title, 35)}</h3>
            <p>{TruncateString(description, 150)}</p>
            <div className="trending-news-author-container">
              {author
                .split(",")
                .slice(0, 2)
                .map((auth, index) => (
                  <div key={index} className="trending-news-author">
                    <div className="circle"></div>
                    <span>{auth}</span>
                  </div>
                ))}
            </div>

            <div className="latest-news-link">
              <span>{TruncateString(publisherName, 12)}</span>
              <span>{FromatDate(publisherDate)}</span>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

const White_Paper2 = () => {
  const [topNews, setTopNews] = useState("");
  const [sectionNews, setSectionNews] = useState("");
  const [trendingNews, setTrendingNews] = useState("");
  const [allNews, setAllNews] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isDrpoDownVisible, setIsDropDownVisible] = useState(false);
  const [publisherList, setPublisherList] = useState("");

  useEffect(() => {
    getTopNews();
    getSectionNews(0, 9);
    getTrendingNews();
    getPublisherList();
  }, []);

  useEffect(() => {
    if(endDate){
      filterLatestNewsBYdate();  
    }else{
      setPopupVisible(false);
      getSectionNews();
    }
  }, [endDate]);

  async function getTopNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/get_top4_wp/");
      const resp2 = await resp1.json();
      setTopNews(resp2);
    } catch (error) {
      console.log("error in fetching top news in white-paper page", error);
    }
  }

  async function getSectionNews() {
    try {
      const resp1 = await fetch("https://backend.inferredtheorem.com/section_2_wp/");
      const resp2 = await resp1.json();
      setAllNews(resp2);
      const page = Math.ceil(resp2.count / 9);
      setTotalPage(page);
      setSectionNews(resp2.data.slice(0, 9));
    } catch (error) {
      console.log("error in fetching section news in white-paper page", error);
    }
  }

  async function getTrendingNews() {
    try {
      const resp1 = await fetch(
        "https://backend.inferredtheorem.com/get_news_by_publisher/"
      );
      const resp2 = await resp1.json();
      setTrendingNews(resp2.data);
    } catch (error) {
      console.log("error in fetching top news in white-paper page", error);
    }
  }

  async function getPublisherList() {
    try {
      const resp = await fetch("https://backend.inferredtheorem.com/publisher_dropdown/");
      const result = await resp.json();
      setPublisherList(result.publishers);
    } catch (error) {
      console.log("error in fetching top news in white-paper page", error);
    }
  }

  function handleFetchNextPage() {
    const newpage = Number(currentPage) + 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  function FetchPage(pageNumber) {
    let data;
    if (pageNumber > 1) {
      data = allNews.data.slice((pageNumber - 1) * 9, pageNumber * 9);
    } else {
      data = allNews.data.slice(0, 9);
    }
    setSectionNews(data);
    // console.log(pageNumber);
  }

  function handleFetchPrevPage() {
    const newpage = currentPage - 1;
    setCurrentPage(newpage);
    setInputPage(newpage);
    FetchPage(newpage);
  }

  const handleDateChange = (range) => {
    if (range) {
      const sDate = new Date(range[0]);
      const eDate = new Date(range[1]);
      setStartDate(sDate);
      setEndDate(eDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
    setStartDate("");
    setEndDate("");
    getSectionNews();
  };

  const formatDate2 = (date) => {
    return date instanceof Date && !isNaN(date)
      ? date.toISOString().split("T")[0]
      : "Not selected";
  };

  const filterLatestNewsBYdate = () => {
    const start_date = formatDate2(startDate);
    const end_date = formatDate2(endDate);

    axios
      .get(
        `https://backend.inferredtheorem.com/from_to_date_wp/?from=${start_date}&to=${end_date}`
      )
      .then((response) => {
        if (response.data.data.length) {
          setAllNews(response.data);
          setSectionNews(response.data.data.slice(0, 9));
          const page = Math.ceil(response.data.count / 9);
        setTotalPage(page);
        setPopupVisible(false);
          setCurrentPage(1);
          setInputPage(1);         
        } else {
          handleShowPopup();
          setSectionNews(null);
          setTotalPage(1);
        setCurrentPage(1);
        setInputPage(1);
        }
      })
      .catch((error) => {
        console.log("error in filter news by date", error);
        getSectionNews();
      });
  };

  const filtertrendingNewsByPublishing = async (publishing) => {
    try {
      const resp = await fetch(
        `https://backend.inferredtheorem.com/get_news_by_publisher/?publisher=${publishing}`
      );
      const result = await resp.json();
      // console.log("trending news by publishing", result);
      setTrendingNews(result.data);
      setIsDropDownVisible(false);
    } catch (error) {
      console.log("error in filter trending News", error);
    }
  };

  const reduxSearch = useSelector((state) => state.searchreducer.searchNews);

  useEffect(() => {
    handleSearch();
  }, [reduxSearch]);

  const handleSearch = () => {
    axios
      .get(`https://backend.inferredtheorem.com/search_wp/?search=${reduxSearch}`)
      .then((response) => {
        // console.log("response handleSearch", response);
        if (response.data.count == 0) {
          // getSectionNews(0, 9);
        } else {
          // alert("no news found");

          setSectionNews(response.data.data.slice(0, 9));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleInputChange(e) {
    const value = e.target.value;
    if (value < 0) {
      return;
    }
    setInputPage(value);
    setCurrentPage(value);
    const data = allNews.data.slice((value - 1) * 9, value * 9);
    setSectionNews(data);
  }
  

  return (
    <section
      className="PlanningApplications"
      style={{
        color: "black",
        marginTop: "20px",
      }}
    >
      <div className="planning">
        <div className="white-paper-wrapper">
          <div className="top-news-section">
            <div className="row">
              <div className="col-sm-7">
                <div className="top-news-left-section">
                  {topNews[0] && (
                    <a
                      href={topNews[0].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="top-1st-news">
                        <h3>{TruncateString(topNews[0].title, 130)}</h3>
                        <p>{TruncateString(topNews[0].description, 350)}</p>
                        <div className="top-news-author-container">
                          {topNews[0].authors
                            .split(",")
                            .slice(0, 4)
                            .map((auth, index) => (
                              <div key={index} className="top-news-author">
                                <div className="circle"></div>
                                <span>{TruncateString(auth, 12)}</span>
                              </div>
                            ))}
                        </div>
                        <div className="top-news-links">
                          <span>
                            {TruncateString(topNews[0]?.publisher_name)}
                          </span>
                          <span>{FromatDate(topNews[0].publisher_date)}</span>
                        </div>
                      </div>
                    </a>
                  )}

                  {topNews[1] && (
                    <a
                      href={topNews[1].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="top-2nd-news">
                        <h3>{TruncateString(topNews[1].title, 130)}</h3>
                        <p>{TruncateString(topNews[1].description, 350)}</p>
                        <div className="top-news-author-container">
                          {topNews[1].authors
                            .split(",")
                            .slice(0, 4)
                            .map((auth, index) => (
                              <div key={index} className="top-news-author">
                                <div className="circle"></div>
                                <span>{TruncateString(auth, 12)}</span>
                              </div>
                            ))}
                        </div>
                        <div className="top-news-links">
                          <span>
                            {TruncateString(topNews[1]?.publisher_name)}
                          </span>
                          <span>{FromatDate(topNews[1].publisher_date)}</span>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              </div>
              <div className="col-sm-5">
                <div className="top-news-right-section">
                  {topNews[2] && (
                    <a
                      href={topNews[2].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="top-2nd-news">
                        <h3>{TruncateString(topNews[2].title, 97)}</h3>
                        <p>{TruncateString(topNews[2].description, 100)}</p>
                        <div className="top-news-author-container">
                          {topNews[2].authors
                            .split(",")
                            .slice(0, 4)
                            .map((auth, index) => (
                              <div key={index} className="top-news-author">
                                <div className="circle"></div>
                                <span>{TruncateString(auth, 12)}</span>
                              </div>
                            ))}
                        </div>
                        <div className="top-news-links">
                          <span>
                            {TruncateString(topNews[2]?.publisher_name, 40)}
                          </span>
                          <span>{FromatDate(topNews[2].publisher_date)}</span>
                        </div>
                      </div>
                    </a>
                  )}

                  {topNews[3] && (
                    <a
                      href={topNews[3].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="top-1st-news">
                        <h3>{TruncateString(topNews[3].title, 97)}</h3>
                        <p>{TruncateString(topNews[3].description, 300)}</p>
                        <div className="top-news-author-container">
                          {topNews[3].authors
                            .split(",")
                            .slice(0, 4)
                            .map((auth, index) => (
                              <div key={index} className="top-news-author">
                                <div className="circle"></div>
                                <span>{TruncateString(auth, 12)}</span>
                              </div>
                            ))}
                        </div>
                        <div className="top-news-links">
                          <span>
                            {TruncateString(topNews[3]?.publisher_name, 40)}
                          </span>
                          <span>{FromatDate(topNews[3].publisher_date)}</span>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="main-content-section">
            <div className="all-news-section">
              <div className="row">
                <div className="col-sm-7">
                  <div className="latest-news-heading">
                    <h3>Latest</h3>
                    <div className="bottom-border"></div>
                    <DateRangePicker
                      format="MM/dd/yyyy"
                      character=" – "
                      placeholder="Select a Date range"
                      onChange={handleDateChange}
                    />
                    {/* {console.log("dataNotFound", dataNotFound)} */}
                  </div>
                  {isPopupVisible && (
                    <div className="popup-overlay">
                      <div className="popup-content">
                        <RiCloseLargeFill className="noData" />
                        <h2>No Data Found</h2>
                        <p>Sorry, there is no data available to display.</p>
                        <button onClick={handleClosePopup}>Close</button>
                      </div>
                    </div>
                  )}
                  {sectionNews && (
                    <div className="latest-news-cards-wrapper">
                      {sectionNews[0] && (
                        <a
                          href={sectionNews[0].url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <div className="latest-news-1-container">
                            <h3>
                              {TruncateString(sectionNews[0]?.title, 110)}
                            </h3>
                            <p>
                              {TruncateString(sectionNews[0]?.description, 250)}
                            </p>
                            <div className="bottom-border"></div>
                            <div className="latest-news-link">
                              <span>{sectionNews[0]?.publisher_name}</span>
                              <span>
                                {FromatDate(sectionNews[0]?.publisher_date)}
                              </span>
                            </div>
                          </div>
                        </a>
                      )}
                      <div className="latest-news-cards">
                        <div className="latest-news-card-left-container">
                          {sectionNews[1] && (
                            <LongPara
                              url={sectionNews[1].url}
                              sectionNews={sectionNews[1]}
                              title={sectionNews[1].title}
                              description={sectionNews[1].description}
                              author={sectionNews[1].authors}
                              publisherName={sectionNews[1].publisher_name}
                              publisherDate={sectionNews[1].publisher_date}
                            />
                          )}
                          {sectionNews[2] && (
                            <ShortPara
                              sectionNews={sectionNews[2]}
                              url={sectionNews[2].url}
                              title={sectionNews[2].title}
                              description={sectionNews[2].description}
                              author={sectionNews[2].authors}
                              publisherName={sectionNews[2].publisher_name}
                              publisherDate={sectionNews[2].publisher_date}
                            />
                          )}
                        </div>
                        <div className="latest-news-card-right-container">
                          {sectionNews[3] && (
                            <ShortPara
                              sectionNews={sectionNews[3]}
                              url={sectionNews[3].url}
                              title={sectionNews[3].title}
                              description={sectionNews[3].description}
                              author={sectionNews[3].authors}
                              publisherName={sectionNews[3].publisher_name}
                              publisherDate={sectionNews[3].publisher_date}
                            />
                          )}
                          {sectionNews[4] && (
                            <LongPara
                              sectionNews={sectionNews[4]}
                              url={sectionNews[4].url}
                              title={sectionNews[4].title}
                              description={sectionNews[4].description}
                              author={sectionNews[4].authors}
                              publisherName={sectionNews[4].publisher_name}
                              publisherDate={sectionNews[4].publisher_date}
                            />
                          )}
                        </div>
                      </div>
                      <div className="latest-news-cards">
                        <div className="latest-news-card-left-container">
                          {sectionNews[5] && (
                            <LongPara
                              sectionNews={sectionNews[5]}
                              url={sectionNews[5].url}
                              title={sectionNews[5].title}
                              description={sectionNews[5].description}
                              author={sectionNews[5].authors}
                              publisherName={sectionNews[5].publisher_name}
                              publisherDate={sectionNews[5].publisher_date}
                            />
                          )}
                          {sectionNews[6] && (
                            <ShortPara
                              sectionNews={sectionNews[6]}
                              url={sectionNews[6].url}
                              title={sectionNews[6].title}
                              description={sectionNews[6].description}
                              author={sectionNews[6].authors}
                              publisherName={sectionNews[6].publisher_name}
                              publisherDate={sectionNews[6].publisher_date}
                            />
                          )}
                        </div>
                        <div className="latest-news-card-right-container">
                          {sectionNews[7] && (
                            <ShortPara
                              sectionNews={sectionNews[7]}
                              url={sectionNews[7].url}
                              title={sectionNews[7].title}
                              description={sectionNews[7].description}
                              author={sectionNews[7].authors}
                              publisherName={sectionNews[7].publisher_name}
                              publisherDate={sectionNews[7].publisher_date}
                            />
                          )}
                          {sectionNews[8] && (
                            <LongPara
                              sectionNews={sectionNews[8]}
                              url={sectionNews[8].url}
                              title={sectionNews[8].title}
                              description={sectionNews[8].description}
                              author={sectionNews[8].authors}
                              publisherName={sectionNews[8].publisher_name}
                              publisherDate={sectionNews[8].publisher_date}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-sm-5">
                  <div className="trending-news-section">
                    <div className="trending-news-heading">
                      <h3>Top Trending Searches</h3>
                      {/* <button>Drop Down</button> */}
                      
                      <div
                        className="trending-news-dropdown"
                        onClick={() => setIsDropDownVisible((prev) => !prev)}
                      >
                        <button>Publisher</button>
                        <IoIosArrowDown color="white" />
                      </div>
                     { isDrpoDownVisible && <div className="dropDown-list">
                      {publisherList && publisherList.map((item, index) => (
                             <p key={index}
                             style={{cursor:'pointer'}}
                             onClick={(e) =>
                              filtertrendingNewsByPublishing(item)
                            }
                              >      
                              {TruncateString(item, 12)}
                              {/* Hello */}
                              </p>
                            ))}
                      </div>}
                    </div>
                    <div className="trending-news-cards">
                      {trendingNews &&
                        trendingNews.slice(0, 7).map((item, index) => (
                          <a
                            key={index}
                            href={item.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <div className="trending-news-card">
                              <h3> {TruncateString(item.title, 100)} </h3>
                              <p>{TruncateString(item.description, 100)}</p>

                              <div className="trending-news-author-container">
                                {item?.authors
                                  ?.split(",")
                                  .slice(0, 2)
                                  .map((auth, index) => (
                                    <div
                                      key={index}
                                      className="trending-news-author"
                                    >
                                      <div className="circle"></div>
                                      <span>{auth}</span>
                                    </div>
                                  ))}
                              </div>
                              <div className="trending-news-link">
                                <span>
                                  {TruncateString(item.publisher_name, 40)}
                                </span>
                                <span>{FromatDate(item.publisher_date)}</span>
                              </div>
                            </div>
                          </a>
                        ))}
                        {/* <a
                            
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            <div className="trending-news-card">
                              <h3> Developing Tools to Enable the UK Construction Industry to Adopt the Active Building Concept for Net... </h3>
                              <p>The research project discussed in this paper is driven by the United Kingdom’s (UK’s) need to reduce...</p>

                              <div className="trending-news-author-container">
                                
                                    <div
                                      
                                      className="trending-news-author"
                                    >
                                      <div className="circle"></div>
                                      <span>hello</span>
                                    </div>
                                  
                              </div>
                              <div className="trending-news-link">
                                <span>
                                  sdfsadfad
                                </span>
                                <span>DSfasdfa</span>
                              </div>
                            </div>
                          </a> */}
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination-controls">
            <div className="page-button">
              <button
                className={`${
                  currentPage === 1 ? "next-button-display" : "next-button"
                }`}
                onClick={handleFetchPrevPage}
              >
                <FaArrowLeftLong size={15} /> Prev Page
              </button>

              <button
                className={`${
                  currentPage === totalPage
                    ? "next-button-display"
                    : "next-button"
                }`}
                onClick={handleFetchNextPage}
              >
                Next Page <FaArrowRightLong size={15} />
              </button>
            </div>

            <div className="pagination-count">
              <span>Page</span>
              <input
                type="number"
                name=""
                id=""
                value={inputPage}
                onChange={handleInputChange}
                // onKeyDown={handlePageKeyPress}
              />
              <span>of {totalPage}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default White_Paper2;
