// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-post-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

.form-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

textarea {
  min-height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button[type='submit'] {
  background-color: #002335;
  color: #fff;
}

button[type='submit']:disabled {
  background-color: #a0a0a0;
}

button[type='button'] {
  background-color: #a0a0a0;
  color: #fff;
}

button:hover {
  opacity: 0.9;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

}`, "",{"version":3,"sources":["webpack://./src/component/Add_new_post.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;;AAEzC;EACE,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;;EAGE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA","sourcesContent":[".add-new-post-container {\n  max-width: 600px;\n  margin: 50px auto;\n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n\n.form-title {\n  text-align: center;\n  margin-bottom: 20px;\n  font-size: 24px;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-group label {\n  display: block;\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n.form-group input,\n.form-group textarea,\n.form-group select {\n  width: 100%;\n  padding: 10px;\n  border-radius: 4px;\n  border: 1px solid #ccc;\n  font-size: 16px;\n}\n\ntextarea {\n  min-height: 100px;\n}\n\n.form-buttons {\n  display: flex;\n  justify-content: space-between;\n}\n\nbutton {\n  padding: 10px 20px;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  font-size: 16px;\n  transition: background-color 0.3s ease;\n}\n\nbutton[type='submit'] {\n  background-color: #002335;\n  color: #fff;\n}\n\nbutton[type='submit']:disabled {\n  background-color: #a0a0a0;\n}\n\nbutton[type='button'] {\n  background-color: #a0a0a0;\n  color: #fff;\n}\n\nbutton:hover {\n  opacity: 0.9;\n}\n\n.error-message {\n  color: red;\n  margin-bottom: 10px;\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
